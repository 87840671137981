/*============================================================================
  Shopify Timber v2.0.0 | github.com/shopify/timber
  Copyright 2014 Shopify Inc.
  Author Carson Shold @cshold
  Built with Sass - http://sass-lang.com/

  Some things to know about this file:
    - Sass is compiled on Shopify's server so you don't need to convert it to CSS yourself
    - The output CSS is compressed and comments are removed
    - This file merges your stylesheets into one master at assets/timber.scss.liquid
==============================================================================*/
/*================ Variables, theme settings, and Sass mixins ================*/
/*================ Global | Sass Mixins ================*/
/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
/*============================================================================
  Accent text
==============================================================================*/
/*================ The following are dependencies of csswizardry grid ================*/
/*================ Color variables ================*/
.rev_slider_wrapper {
  font-family: Arvo; }

.flexslider {
  font-family: Arvo; }

/*================ Global | Normalize ================*/
*, input, :before, :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html, body {
  padding: 0;
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

input[type="search"], input[type="text"], input[type="button"], input[type="email"], button, input[type="submit"] {
  outline: none;
  -moz-appearance: none; }

/*================ Site-wide styles ================*/
/*================ Partials | Layout Styles ================*/
body {
  background-color: #181616;
  overflow-x: hidden; }

footer .wrapper, .footer-bottom .wrapper {
  padding: 0 4%; }
  @media screen and (max-width: 1199px) {
    footer .wrapper, .footer-bottom .wrapper {
      padding: 0 2%; } }
  @media screen and (max-width: 967px) {
    footer .wrapper, .footer-bottom .wrapper {
      padding: 0 1%; } }

.wrapper {
  *zoom: 1;
  max-width: 100%;
  margin: 0 auto;
  clear: both; }
  .wrapper:after {
    content: "";
    display: !important;
    clear: both; }
  @media screen and (max-width: 1199px) {
    .wrapper {
      padding: 0 0px; } }
  @media screen and (max-width: 767px) {
    .wrapper {
      padding: 0 0px; } }

.container-bg {
  float: left;
  width: 100%;
  padding: 0 4%; }
  @media screen and (max-width: 967px) {
    .container-bg {
      padding: 0 1%; } }
  @media screen and (max-width: 1199px) {
    .container-bg {
      padding: 0 2%; } }

hr {
  clear: both;
  border-top: solid #36342f;
  border-width: 1px 0 0;
  margin: 30px 0;
  height: 0; }
  hr.hr--small {
    margin: 15px 0; }
  hr.hr--clear {
    border-top-color: transparent; }
  .template-index .main-content .grid-uniform + hr.hr--clear {
    display: none; }

.container {
  max-width: 1230px;
  margin: auto; }
  @media screen and (max-width: 1599px) {
    .container {
      width: 95%; } }

/*================ Partials | Typography styles ================*/
body,
input,
textarea,
button,
select {
  letter-spacing: 0em;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.7;
  font-family: Maven Pro;
  color: gainsboro;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }
  @media screen and (max-width: 967px) {
    body,
    input,
    textarea,
    button,
    select {
      font-size: 15px; } }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  letter-spacing: 0em;
  margin: 0 0 15px;
  line-height: 1.2;
  color: #b10a0a;
  font-family: Libre Baskerville;
  font-weight: 300; }
  h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
    text-decoration: none;
    font-weight: inherit; }

/*================ Use em() Sass function to declare font-size ================*/
h1, .h1 {
  font-size: 50px; }
  @media screen and (max-width: 1599px) {
    h1, .h1 {
      font-size: 40px; } }
  @media screen and (max-width: 767px) {
    h1, .h1 {
      font-size: 40px; } }

h2, .h2 {
  font-size: 40px; }
  @media screen and (max-width: 1599px) {
    h2, .h2 {
      font-size: 31px; } }
  @media screen and (max-width: 767px) {
    h2, .h2 {
      font-size: 31px; } }

h3, .h3 {
  font-size: 30px; }
  @media screen and (max-width: 1599px) {
    h3, .h3 {
      font-size: 25px; } }
  @media screen and (max-width: 767px) {
    h3, .h3 {
      font-size: 25px; } }

h4, .h4 {
  font-size: 20px; }
  @media screen and (max-width: 1599px) {
    h4, .h4 {
      font-size: 16px; } }
  @media screen and (max-width: 767px) {
    h4, .h4 {
      font-size: 16px; } }

h5, .h5 {
  font-size: 18px; }
  @media screen and (max-width: 967px) {
    h5, .h5 {
      font-size: 16px; } }
  @media screen and (max-width: 767px) {
    h5, .h5 {
      font-size: 16px; } }

h6, .h6 {
  font-size: 16px; }
  @media screen and (max-width: 967px) {
    h6, .h6 {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    h6, .h6 {
      font-size: 14px; } }

p {
  margin: 0 0 15px 0; }
  p img {
    margin: 0; }

em {
  font-style: italic; }

b, strong {
  font-weight: bold; }

small {
  font-size: 0.9em; }

sup, sub {
  position: relative;
  font-size: 60%;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.5em; }

blockquote {
  margin: 0 0 10px;
  padding: 15px 30px; }
  blockquote p {
    margin-bottom: 0; }
    blockquote p + cite {
      margin-top: 15px; }
  blockquote cite {
    display: block;
    font-size: 0.75em; }
    blockquote cite:before {
      content: "\2014 \0020"; }

code, pre {
  font-size: 1em;
  border: 0 none;
  padding: 0 2px; }

pre {
  overflow: auto;
  padding: 15px;
  margin: 0 0 30px; }

/*================ Partials | Lists ================*/
ul, ol {
  margin: 0;
  padding: 0 0 0 15px; }

.inline-list li {
  float: left;
  margin: 0 7px 0 0;
  list-style: none; }
  @media screen and (max-width: 767px) {
    .inline-list li {
      float: none;
      display: inline-block; } }

.inline-list li:last-child {
  margin-right: 0; }

/*================ Partials | Tables ================*/
table {
  width: 100%;
  margin-bottom: 1em;
  border-collapse: collapse;
  border-spacing: 0; }

.table-wrap {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

th {
  font-weight: bold; }

th, td {
  text-align: left;
  padding: 15px;
  border: 1px solid #36342f; }

/*============================================================================
  #Grid Setup
    - Based on csswizardry grid, but with floated columns, a fixed gutter size, and BEM classes
    - Breakpoints defined above, under #Breakpoint and Grid Variables
    - Note the inclusion of .grid-uniform to take care of clearfixes on evenly sized grid items
==============================================================================*/
/* Force clearfix on grids */
.grid, .grid--rev, .grid--full,
.grid-uniform {
  *zoom: 1; }
  .grid:after, .grid--rev:after, .grid--full:after,
  .grid-uniform:after {
    content: "";
    display: !important;
    clear: both; }

/* Manual grid__item clearfix */
.grid__item.clear {
  clear: both; }

/*============================================================================
  Drop relative positioning into silent classes which can't take advantage of
  the `[class*="push--"]` and `[class*="pull--"]` selectors.
==============================================================================*/
/*============================================================================
  Grid Setup
    1. Allow the grid system to be used on lists.
    2. Remove any margins and paddings that might affect the grid system.
    3. Apply a negative `margin-left` to negate the columns' gutters.
==============================================================================*/
.grid, .grid--rev, .grid--full,
.grid-uniform {
  list-style: none;
  margin: 0;
  padding: 0; }

.grid__item {
  float: left;
  min-height: 1px;
  vertical-align: top;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*============================================================================
  Reversed grids allow you to structure your source in the opposite
  order to how your rendered layout will appear.
==============================================================================*/
.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__item {
    direction: ltr;
    text-align: left;
    float: right; }

/* Gutterless grids have all the properties of regular grids, minus any spacing. */
.grid--full {
  margin-left: 0; }
  .grid--full > .grid__item {
    padding-left: 0; }

/*============================================================================
  WIDTHS
    - Create width classes, prefixed by the specified namespace.
==============================================================================*/
/*================ Clearfix helper on uniform grids ================*/
/*================ Helper show/hide classes around our breakpoints ================*/
/*================ Our regular, non-responsive width and helper classes ================*/
/** Whole */
.one-whole {
  width: 100%; }

/* Halves */
.one-half {
  width: 50%; }

/* Thirds */
.one-third {
  width: 33.333%; }

.two-thirds {
  width: 66.656%; }

/* Quarters */
.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

/* Fifths */
.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

/* Sixths */
.one-sixth {
  width: 16.666%; }

.two-sixths {
  width: 33.333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.666%; }

.five-sixths {
  width: 83.333%; }

/* Eighths */
.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

/* Tenths */
.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

/*================ Our responsive classes, if we have enabled them ================*/
@media only screen and (max-width: 567px) {
  /** Whole */
  .small--one-whole {
    width: 100%; }

  /* Halves */
  .small--one-half {
    width: 50%; }

  /* Thirds */
  .small--one-third {
    width: 33.333%; }

  .small--two-thirds {
    width: 66.656%; }

  /* Quarters */
  .small--one-quarter {
    width: 25%; }

  .small--two-quarters {
    width: 50%; }

  .small--three-quarters {
    width: 75%; }

  /* Fifths */
  .small--one-fifth {
    width: 20%; }

  .small--two-fifths {
    width: 40%; }

  .small--three-fifths {
    width: 60%; }

  .small--four-fifths {
    width: 80%; }

  /* Sixths */
  .small--one-sixth {
    width: 16.666%; }

  .small--two-sixths {
    width: 33.333%; }

  .small--three-sixths {
    width: 50%; }

  .small--four-sixths {
    width: 66.666%; }

  .small--five-sixths {
    width: 83.333%; }

  /* Eighths */
  .small--one-eighth {
    width: 12.5%; }

  .small--two-eighths {
    width: 25%; }

  .small--three-eighths {
    width: 37.5%; }

  .small--four-eighths {
    width: 50%; }

  .small--five-eighths {
    width: 62.5%; }

  .small--six-eighths {
    width: 75%; }

  .small--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .small--one-tenth {
    width: 10%; }

  .small--two-tenths {
    width: 20%; }

  .small--three-tenths {
    width: 30%; }

  .small--four-tenths {
    width: 40%; }

  .small--five-tenths {
    width: 50%; }

  .small--six-tenths {
    width: 60%; }

  .small--seven-tenths {
    width: 70%; }

  .small--eight-tenths {
    width: 80%; }

  .small--nine-tenths {
    width: 90%; }

  .small--show {
    display: block !important; }

  .small--hide {
    display: none !important; }

  .small--text-left {
    text-align: left !important; }

  .small--text-right {
    text-align: right !important; }

  .small--text-center {
    text-align: center !important; }

  .small--left {
    float: left !important; }

  .small--right {
    float: right !important; }

  .grid-uniform .small--one-half:nth-child(2n+1),
  .grid-uniform .small--one-third:nth-child(3n+1),
  .grid-uniform .small--one-quarter:nth-child(4n+1),
  .grid-uniform .small--one-fifth:nth-child(5n+1),
  .grid-uniform .small--one-sixth:nth-child(6n+1),
  .grid-uniform .small--two-sixths:nth-child(3n+1),
  .grid-uniform .small--three-sixths:nth-child(2n+1),
  .grid-uniform .small--two-eighths:nth-child(4n+1),
  .grid-uniform .small--four-eighths:nth-child(2n+1),
  .grid-uniform .small--five-tenths:nth-child(2n+1),
  .grid-uniform .small--one-twelfth:nth-child(12n+1),
  .grid-uniform .small--two-twelfths:nth-child(6n+1),
  .grid-uniform .small--three-twelfths:nth-child(4n+1),
  .grid-uniform .small--four-twelfths:nth-child(3n+1),
  .grid-uniform .small--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (min-width: 568px) and (max-width: 767px) {
  /** Whole */
  .medium--one-whole {
    width: 100%; }

  /* Halves */
  .medium--one-half {
    width: 50%; }

  /* Thirds */
  .medium--one-third {
    width: 33.333%; }

  .medium--two-thirds {
    width: 66.656%; }

  /* Quarters */
  .medium--one-quarter {
    width: 25%; }

  .medium--two-quarters {
    width: 50%; }

  .medium--three-quarters {
    width: 75%; }

  /* Fifths */
  .medium--one-fifth {
    width: 20%; }

  .medium--two-fifths {
    width: 40%; }

  .medium--three-fifths {
    width: 60%; }

  .medium--four-fifths {
    width: 80%; }

  /* Sixths */
  .medium--one-sixth {
    width: 16.666%; }

  .medium--two-sixths {
    width: 33.333%; }

  .medium--three-sixths {
    width: 50%; }

  .medium--four-sixths {
    width: 66.666%; }

  .medium--five-sixths {
    width: 83.333%; }

  /* Eighths */
  .medium--one-eighth {
    width: 12.5%; }

  .medium--two-eighths {
    width: 25%; }

  .medium--three-eighths {
    width: 37.5%; }

  .medium--four-eighths {
    width: 50%; }

  .medium--five-eighths {
    width: 62.5%; }

  .medium--six-eighths {
    width: 75%; }

  .medium--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .medium--one-tenth {
    width: 10%; }

  .medium--two-tenths {
    width: 20%; }

  .medium--three-tenths {
    width: 30%; }

  .medium--four-tenths {
    width: 40%; }

  .medium--five-tenths {
    width: 50%; }

  .medium--six-tenths {
    width: 60%; }

  .medium--seven-tenths {
    width: 70%; }

  .medium--eight-tenths {
    width: 80%; }

  .medium--nine-tenths {
    width: 90%; }

  .medium--show {
    display: block !important; }

  .medium--hide {
    display: none !important; }

  .medium--text-left {
    text-align: left !important; }

  .medium--text-right {
    text-align: right !important; }

  .medium--text-center {
    text-align: center !important; }

  .medium--left {
    float: left !important; }

  .medium--right {
    float: right !important; }

  .grid-uniform .medium--one-half:nth-child(2n+1),
  .grid-uniform .medium--one-third:nth-child(3n+1),
  .grid-uniform .medium--one-quarter:nth-child(4n+1),
  .grid-uniform .medium--one-fifth:nth-child(5n+1),
  .grid-uniform .medium--one-sixth:nth-child(6n+1),
  .grid-uniform .medium--two-sixths:nth-child(3n+1),
  .grid-uniform .medium--three-sixths:nth-child(2n+1),
  .grid-uniform .medium--two-eighths:nth-child(4n+1),
  .grid-uniform .medium--four-eighths:nth-child(2n+1),
  .grid-uniform .medium--five-tenths:nth-child(2n+1),
  .grid-uniform .medium--one-twelfth:nth-child(12n+1),
  .grid-uniform .medium--two-twelfths:nth-child(6n+1),
  .grid-uniform .medium--three-twelfths:nth-child(4n+1),
  .grid-uniform .medium--four-twelfths:nth-child(3n+1),
  .grid-uniform .medium--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (max-width: 767px) {
  /** Whole */
  .medium-down--one-whole {
    width: 100%; }

  /* Halves */
  .medium-down--one-half {
    width: 50%; }

  /* Thirds */
  .medium-down--one-third {
    width: 33.333%; }

  .medium-down--two-thirds {
    width: 66.656%; }

  /* Quarters */
  .medium-down--one-quarter {
    width: 25%; }

  .medium-down--two-quarters {
    width: 50%; }

  .medium-down--three-quarters {
    width: 75%; }

  /* Fifths */
  .medium-down--one-fifth {
    width: 20%; }

  .medium-down--two-fifths {
    width: 40%; }

  .medium-down--three-fifths {
    width: 60%; }

  .medium-down--four-fifths {
    width: 80%; }

  /* Sixths */
  .medium-down--one-sixth {
    width: 16.666%; }

  .medium-down--two-sixths {
    width: 33.333%; }

  .medium-down--three-sixths {
    width: 50%; }

  .medium-down--four-sixths {
    width: 66.666%; }

  .medium-down--five-sixths {
    width: 83.333%; }

  /* Eighths */
  .medium-down--one-eighth {
    width: 12.5%; }

  .medium-down--two-eighths {
    width: 25%; }

  .medium-down--three-eighths {
    width: 37.5%; }

  .medium-down--four-eighths {
    width: 50%; }

  .medium-down--five-eighths {
    width: 62.5%; }

  .medium-down--six-eighths {
    width: 75%; }

  .medium-down--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .medium-down--one-tenth {
    width: 10%; }

  .medium-down--two-tenths {
    width: 20%; }

  .medium-down--three-tenths {
    width: 30%; }

  .medium-down--four-tenths {
    width: 40%; }

  .medium-down--five-tenths {
    width: 50%; }

  .medium-down--six-tenths {
    width: 60%; }

  .medium-down--seven-tenths {
    width: 70%; }

  .medium-down--eight-tenths {
    width: 80%; }

  .medium-down--nine-tenths {
    width: 90%; }

  .medium-down--show {
    display: block !important; }

  .medium-down--hide {
    display: none !important; }

  .medium-down--text-left {
    text-align: left !important; }

  .medium-down--text-right {
    text-align: right !important; }

  .medium-down--text-center {
    text-align: center !important; }

  .medium-down--left {
    float: left !important; }

  .medium-down--right {
    float: right !important; }

  .grid-uniform .medium-down--one-half:nth-child(2n+1),
  .grid-uniform .medium-down--one-third:nth-child(3n+1),
  .grid-uniform .medium-down--one-quarter:nth-child(4n+1),
  .grid-uniform .medium-down--one-fifth:nth-child(5n+1),
  .grid-uniform .medium-down--one-sixth:nth-child(6n+1),
  .grid-uniform .medium-down--two-sixths:nth-child(3n+1),
  .grid-uniform .medium-down--three-sixths:nth-child(2n+1),
  .grid-uniform .medium-down--two-eighths:nth-child(4n+1),
  .grid-uniform .medium-down--four-eighths:nth-child(2n+1),
  .grid-uniform .medium-down--five-tenths:nth-child(2n+1),
  .grid-uniform .medium-down--one-twelfth:nth-child(12n+1),
  .grid-uniform .medium-down--two-twelfths:nth-child(6n+1),
  .grid-uniform .medium-down--three-twelfths:nth-child(4n+1),
  .grid-uniform .medium-down--four-twelfths:nth-child(3n+1),
  .grid-uniform .medium-down--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (min-width: 768px) and (max-width: 967px) {
  /** Whole */
  .large--one-whole {
    width: 100%; }

  /* Halves */
  .large--one-half {
    width: 50%; }

  /* Thirds */
  .large--one-third {
    width: 33.333%; }

  .large--two-thirds {
    width: 66.656%; }

  /* Quarters */
  .large--one-quarter {
    width: 25%; }

  .large--two-quarters {
    width: 50%; }

  .large--three-quarters {
    width: 75%; }

  /* Fifths */
  .large--one-fifth {
    width: 20%; }

  .large--two-fifths {
    width: 40%; }

  .large--three-fifths {
    width: 60%; }

  .large--four-fifths {
    width: 80%; }

  /* Sixths */
  .large--one-sixth {
    width: 16.666%; }

  .large--two-sixths {
    width: 33.333%; }

  .large--three-sixths {
    width: 50%; }

  .large--four-sixths {
    width: 66.666%; }

  .large--five-sixths {
    width: 83.333%; }

  /* Eighths */
  .large--one-eighth {
    width: 12.5%; }

  .large--two-eighths {
    width: 25%; }

  .large--three-eighths {
    width: 37.5%; }

  .large--four-eighths {
    width: 50%; }

  .large--five-eighths {
    width: 62.5%; }

  .large--six-eighths {
    width: 75%; }

  .large--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .large--one-tenth {
    width: 10%; }

  .large--two-tenths {
    width: 20%; }

  .large--three-tenths {
    width: 30%; }

  .large--four-tenths {
    width: 40%; }

  .large--five-tenths {
    width: 50%; }

  .large--six-tenths {
    width: 60%; }

  .large--seven-tenths {
    width: 70%; }

  .large--eight-tenths {
    width: 80%; }

  .large--nine-tenths {
    width: 90%; }

  .large--show {
    display: block !important; }

  .large--hide {
    display: none !important; }

  .large--text-left {
    text-align: left !important; }

  .large--text-right {
    text-align: right !important; }

  .large--text-center {
    text-align: center !important; }

  .large--left {
    float: left !important; }

  .large--right {
    float: right !important; }

  .grid-uniform .large--one-half:nth-child(2n+1),
  .grid-uniform .large--one-third:nth-child(3n+1),
  .grid-uniform .large--one-quarter:nth-child(4n+1),
  .grid-uniform .large--one-fifth:nth-child(5n+1),
  .grid-uniform .large--one-sixth:nth-child(6n+1),
  .grid-uniform .large--two-sixths:nth-child(3n+1),
  .grid-uniform .large--three-sixths:nth-child(2n+1),
  .grid-uniform .large--two-eighths:nth-child(4n+1),
  .grid-uniform .large--four-eighths:nth-child(2n+1),
  .grid-uniform .large--five-tenths:nth-child(2n+1),
  .grid-uniform .large--one-twelfth:nth-child(12n+1),
  .grid-uniform .large--two-twelfths:nth-child(6n+1),
  .grid-uniform .large--three-twelfths:nth-child(4n+1),
  .grid-uniform .large--four-twelfths:nth-child(3n+1),
  .grid-uniform .large--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (min-width: 968px) and (max-width: 1199px) {
  /** Whole */
  .post-large--one-whole {
    width: 100%; }

  /* Halves */
  .post-large--one-half {
    width: 50%; }

  /* Thirds */
  .post-large--one-third {
    width: 33.333%; }

  .post-large--two-thirds {
    width: 66.656%; }

  /* Quarters */
  .post-large--one-quarter {
    width: 25%; }

  .post-large--two-quarters {
    width: 50%; }

  .post-large--three-quarters {
    width: 75%; }

  /* Fifths */
  .post-large--one-fifth {
    width: 20%; }

  .post-large--two-fifths {
    width: 40%; }

  .post-large--three-fifths {
    width: 60%; }

  .post-large--four-fifths {
    width: 80%; }

  /* Sixths */
  .post-large--one-sixth {
    width: 16.666%; }

  .post-large--two-sixths {
    width: 33.333%; }

  .post-large--three-sixths {
    width: 50%; }

  .post-large--four-sixths {
    width: 66.666%; }

  .post-large--five-sixths {
    width: 83.333%; }

  /* Eighths */
  .post-large--one-eighth {
    width: 12.5%; }

  .post-large--two-eighths {
    width: 25%; }

  .post-large--three-eighths {
    width: 37.5%; }

  .post-large--four-eighths {
    width: 50%; }

  .post-large--five-eighths {
    width: 62.5%; }

  .post-large--six-eighths {
    width: 75%; }

  .post-large--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .post-large--one-tenth {
    width: 10%; }

  .post-large--two-tenths {
    width: 20%; }

  .post-large--three-tenths {
    width: 30%; }

  .post-large--four-tenths {
    width: 40%; }

  .post-large--five-tenths {
    width: 50%; }

  .post-large--six-tenths {
    width: 60%; }

  .post-large--seven-tenths {
    width: 70%; }

  .post-large--eight-tenths {
    width: 80%; }

  .post-large--nine-tenths {
    width: 90%; }

  .post-large--show {
    display: block !important; }

  .post-large--hide {
    display: none !important; }

  .post-large--text-left {
    text-align: left !important; }

  .post-large--text-right {
    text-align: right !important; }

  .post-large--text-center {
    text-align: center !important; }

  .post-large--left {
    float: left !important; }

  .post-large--right {
    float: right !important; }

  .grid-uniform .post-large--one-half:nth-child(2n+1),
  .grid-uniform .post-large--one-third:nth-child(3n+1),
  .grid-uniform .post-large--one-quarter:nth-child(4n+1),
  .grid-uniform .post-large--one-fifth:nth-child(5n+1),
  .grid-uniform .post-large--one-sixth:nth-child(6n+1),
  .grid-uniform .post-large--two-sixths:nth-child(3n+1),
  .grid-uniform .post-large--three-sixths:nth-child(2n+1),
  .grid-uniform .post-large--two-eighths:nth-child(4n+1),
  .grid-uniform .post-large--four-eighths:nth-child(2n+1),
  .grid-uniform .post-large--five-tenths:nth-child(2n+1),
  .grid-uniform .post-large--one-twelfth:nth-child(12n+1),
  .grid-uniform .post-large--two-twelfths:nth-child(6n+1),
  .grid-uniform .post-large--three-twelfths:nth-child(4n+1),
  .grid-uniform .post-large--four-twelfths:nth-child(3n+1),
  .grid-uniform .post-large--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (min-width: 1200px) and (max-width: 5000px) {
  /** Whole */
  .wide--one-whole {
    width: 100%; }

  /* Halves */
  .wide--one-half {
    width: 50%; }

  /* Thirds */
  .wide--one-third {
    width: 33.333%; }

  .wide--two-thirds {
    width: 66.656%; }

  /* Quarters */
  .wide--one-quarter {
    width: 25%; }

  .wide--two-quarters {
    width: 50%; }

  .wide--three-quarters {
    width: 75%; }

  /* Fifths */
  .wide--one-fifth {
    width: 20%; }

  .wide--two-fifths {
    width: 40%; }

  .wide--three-fifths {
    width: 60%; }

  .wide--four-fifths {
    width: 80%; }

  /* Sixths */
  .wide--one-sixth {
    width: 16.666%; }

  .wide--two-sixths {
    width: 33.333%; }

  .wide--three-sixths {
    width: 50%; }

  .wide--four-sixths {
    width: 66.666%; }

  .wide--five-sixths {
    width: 83.333%; }

  /* Eighths */
  .wide--one-eighth {
    width: 12.5%; }

  .wide--two-eighths {
    width: 25%; }

  .wide--three-eighths {
    width: 37.5%; }

  .wide--four-eighths {
    width: 50%; }

  .wide--five-eighths {
    width: 62.5%; }

  .wide--six-eighths {
    width: 75%; }

  .wide--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .wide--one-tenth {
    width: 10%; }

  .wide--two-tenths {
    width: 20%; }

  .wide--three-tenths {
    width: 30%; }

  .wide--four-tenths {
    width: 40%; }

  .wide--five-tenths {
    width: 50%; }

  .wide--six-tenths {
    width: 60%; }

  .wide--seven-tenths {
    width: 70%; }

  .wide--eight-tenths {
    width: 80%; }

  .wide--nine-tenths {
    width: 90%; }

  .wide--show {
    display: block !important; }

  .wide--hide {
    display: none !important; }

  .wide--text-left {
    text-align: left !important; }

  .wide--text-right {
    text-align: right !important; }

  .wide--text-center {
    text-align: center !important; }

  .wide--left {
    float: left !important; }

  .wide--right {
    float: right !important; }

  .grid-uniform .wide--one-half:nth-child(2n+1),
  .grid-uniform .wide--one-third:nth-child(3n+1),
  .grid-uniform .wide--one-quarter:nth-child(4n+1),
  .grid-uniform .wide--one-fifth:nth-child(5n+1),
  .grid-uniform .wide--one-sixth:nth-child(6n+1),
  .grid-uniform .wide--two-sixths:nth-child(3n+1),
  .grid-uniform .wide--three-sixths:nth-child(2n+1),
  .grid-uniform .wide--two-eighths:nth-child(4n+1),
  .grid-uniform .wide--four-eighths:nth-child(2n+1),
  .grid-uniform .wide--five-tenths:nth-child(2n+1),
  .grid-uniform .wide--one-twelfth:nth-child(12n+1),
  .grid-uniform .wide--two-twelfths:nth-child(6n+1),
  .grid-uniform .wide--three-twelfths:nth-child(4n+1),
  .grid-uniform .wide--four-twelfths:nth-child(3n+1),
  .grid-uniform .wide--six-twelfths:nth-child(2n+1) {
    clear: both; } }
/*============================================================================
  PUSH
    - Push classes, to move grid items over to the right by certain amounts
==============================================================================*/
/*============================================================================
  PULL
    - Pull classes, to move grid items back to the left by certain amounts
==============================================================================*/
/*================ Partials | Helper Classes ================*/
.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.display-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none; }

@media screen and (min-width: 768px) {
  .post-large--display-table {
    display: table;
    table-layout: fixed;
    width: 100%; }

  .post-large--display-table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none; } }
/*================ Partials | Links and Buttons ================*/
a, .text-link {
  color: white;
  text-decoration: none;
  background: transparent; }
  a:hover, a:focus, .text-link:hover, .text-link:focus {
    color: #934d4d; }

button {
  overflow: visible; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  margin: 0; }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  display: block;
  margin: 10px 0 10px;
  width: 100%;
  outline: none;
  -moz-appearance: textfield; }

.btn, .btn--secondary,
input.btn--secondary, .btn--tertiary,
input.btn--tertiary, input[type="submit"], .dt-sc-button {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.sidebar-tag li:last-child {
  border: none; }

.sidebar-tag li {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative; }

.sidebar-tag label {
  font-weight: normal;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.sidebar-tag input[type="checkbox"] + label {
  border-bottom: 1px dashed #36342f;
  padding: 12px 0 12px 20px;
  margin: 0; }

.sidebar-tag li:last-child input[type="checkbox"] + label {
  border-bottom: none; }

.btn, .btn--secondary,
input.btn--secondary, .btn--tertiary,
input.btn--tertiary, input[type="submit"], .dt-sc-button, .shopify-payment-button .shopify-payment-button__button--unbranded {
  border: none;
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  background: #b10a0a;
  color: white;
  position: relative; }

.btn:hover, .btn--secondary:hover, .btn--tertiary:hover, input[type="submit"]:hover, .dt-sc-button:hover {
  background-color: #934d4d;
  color: #fff; }

.btn, .btn--secondary,
input.btn--secondary, .btn--tertiary,
input.btn--tertiary, input[type="submit"], .dt-sc-button, .shopify-payment-button .shopify-payment-button__button--unbranded {
  display: inline-block;
  padding: 15px 40px;
  margin: 0;
  line-height: 1.42;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  letter-spacing: 0px;
  font-size: 16px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  outline: none; }
  @media screen and (max-width: 967px) {
    .btn, .btn--secondary,
    input.btn--secondary, .btn--tertiary,
    input.btn--tertiary, input[type="submit"], .dt-sc-button, .shopify-payment-button .shopify-payment-button__button--unbranded {
      padding: 13px 30px;
      font-size: 12px; } }
  @media screen and (max-width: 767px) {
    .btn, .btn--secondary,
    input.btn--secondary, .btn--tertiary,
    input.btn--tertiary, input[type="submit"], .dt-sc-button, .shopify-payment-button .shopify-payment-button__button--unbranded {
      padding: 15px 20px; } }
  @media screen and (max-width: 567px) {
    .btn, .btn--secondary,
    input.btn--secondary, .btn--tertiary,
    input.btn--tertiary, input[type="submit"], .dt-sc-button, .shopify-payment-button .shopify-payment-button__button--unbranded {
      font-size: 14px;
      padding: 12px 18px; } }

/*.btn,.dt-sc-button,.shopify-payment-button .shopify-payment-button__button--unbranded {background:$colorBtnPrimary;border:none;color: $colorBtnPrimaryText;
  &:hover { background-color: $colorBtnPrimaryHover;color: #fff; border-color: $colorBtnPrimaryHover; }}
.btn,.dt-sc-button,.shopify-payment-button .shopify-payment-button__button--unbranded { display: inline-block;padding:10px 45px;@include at-query ($max, $laplarge) { padding:10px 25px; }@include at-query ($max, $postlarge) { padding:10px 15px;font-size:14px; } @include at-query ($max, $medium) { padding:10px 20px; }  margin: 0;text-decoration: none;text-align: center; vertical-align: middle;white-space: nowrap; cursor: pointer; @include prefix('user-select', 'none'); -webkit-appearance: none; -moz-appearance: none;@include at-query ($max, $small) { font-size:14px;padding:7px 18px; }font-size:16px;@include transition(all 0.4s ease-in-out);outline:none;}*/
.shopify-payment-button .shopify-payment-button__button--unbranded {
  width: auto;
  line-height: 1.7; }

.shopify-payment-button .shopify-payment-button__button--hidden {
  display: none; }

.shopify-payment-button {
  float: left;
  margin-left: 10px; }

/*================ Set primary button colors - can override later ================*/
.btn:hover:not([disabled]), .btn--secondary:hover:not([disabled]), .btn--tertiary:hover:not([disabled]), input[type="submit"]:hover:not([disabled]), .btn:active, .btn--secondary:active, .btn--tertiary:active, input[type="submit"]:active, .btn:focus, .btn--secondary:focus, .btn--tertiary:focus, input[type="submit"]:focus, .dt-sc-button:hover:not([disabled]), .dt-sc-button:active, .dt-sc-button:focus, .shopify-payment-button .shopify-payment-button__button--unbranded:hover:not([disabled]), .shopify-payment-button .shopify-payment-button__button--unbranded:active, .shopify-payment-button .shopify-payment-button__button--unbranded:focus {
  background-color: #934d4d;
  color: #fff;
  border-color: #934d4d; }

.btn--small {
  padding: 4px 5px;
  font-size: em(12px); }

.btn--large {
  padding: 12px 15px;
  font-size: em(16px); }

.btn--full {
  width: 100%; }

.btn--tertiary,
input.btn--tertiary {
  margin: 0;
  background-color: #181616;
  color: #b10a0a;
  border: 1px solid #36342f; }
  .btn--tertiary:hover,
  input.btn--tertiary:hover {
    opacity: 1;
    background-color: #b10a0a; }
  .btn--tertiary:active, .btn--tertiary:focus,
  input.btn--tertiary:active,
  input.btn--tertiary:focus {
    background-color: #810707; }

select.btn--tertiary {
  padding: 6px 28px 6px 10px;
  font-size: 0.85em; }

/*================ Force an input/button to look like a text link ================*/
.text-link {
  display: inline;
  border: 0 none;
  padding: 0;
  margin: 0; }

/*================ Partials | Images, SVG, and iframes ================*/
img {
  border: 0 none; }

svg:not(:root) {
  overflow: hidden; }

img.auto, .grid__item img, .grid__item iframe {
  max-width: 100%; }

.table-wrapper {
  max-width: 100%;
  overflow: auto; }

/*================ Partials | Forms ================*/
form {
  margin-bottom: 30px; }

input, textarea, button, select {
  font-size: 1em; }

button, input[type="text"], input[type="search"], input[type="password"], input[type="email"], input[type="file"], input[type="tel"], input[type="submit"], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0 0 20px;
  outline: none; }

input, textarea, select, fieldset {
  border-radius: 2px;
  max-width: 100%; }
  input.input-full, textarea.input-full, select.input-full, fieldset.input-full {
    width: 100%; }

input, select, textarea {
  padding: 14px 15px;
  line-height: 1.42; }

fieldset {
  border: 1px solid #36342f;
  padding: 15px; }

legend {
  border: 0;
  padding: 0; }

optgroup {
  font-weight: bold; }

input {
  display: inline-block;
  width: auto; }

button, input[type="submit"] {
  cursor: pointer; }

/*================ Input width and border ================*/
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="tel"],
textarea,
select {
  border: 1px solid #36342f;
  max-width: 100%;
  color: #333; }
  input[type="text"]:focus,
  input[type="search"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="file"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  textarea:focus,
  select:focus {
    border: 1px solid #1b1a17; }
  input[type="text"][disabled], input[type="text"].disabled,
  input[type="search"][disabled],
  input[type="search"].disabled,
  input[type="password"][disabled],
  input[type="password"].disabled,
  input[type="email"][disabled],
  input[type="email"].disabled,
  input[type="file"][disabled],
  input[type="file"].disabled,
  input[type="number"][disabled],
  input[type="number"].disabled,
  input[type="tel"][disabled],
  input[type="tel"].disabled,
  textarea[disabled],
  textarea.disabled,
  select[disabled],
  select.disabled {
    cursor: default;
    background-color: #f6f6f6;
    border-color: #b6b6b6; }

textarea {
  min-height: 140px;
  overflow: auto; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin: 0;
  padding: 0; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: right center;
  background-image: url("//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/ico-select.svg?5955");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #fff;
  padding-right: 28px;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
  outline: none;
  /*================ Hide the svg arrow in IE9 and below ================*/ }
  .ie9 select, .lt-ie9 select {
    padding-right: 10px;
    background-image: none; }

option {
  color: #000;
  background-color: #fff; }

select::-ms-expand {
  display: none; }

/*================ Form labels ================*/
.hidden-label {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

label, legend {
  display: block;
  margin-bottom: 2px; }
  label.inline, legend.inline {
    display: inline; }
  .form-horizontal label.label--hidden, label.label--hidden, .form-horizontal legend.label--hidden, legend.label--hidden {
    height: 0;
    width: 0;
    margin-bottom: 0;
    overflow: hidden; }
    .ie9 .form-horizontal label.label--hidden, .lt-ie9 .form-horizontal label.label--hidden, .ie9 label.label--hidden, .lt-ie9 label.label--hidden, .ie9 .form-horizontal legend.label--hidden, .lt-ie9 .form-horizontal legend.label--hidden, .ie9 legend.label--hidden, .lt-ie9 legend.label--hidden {
      height: auto;
      width: auto;
      margin-bottom: 2px;
      overflow: visible; }
  .form-horizontal label:not(.label--hidden), .form-horizontal legend:not(.label--hidden) {
    font-size: 0.9em; }

/*================ We don't want the same label treatment for checkboxes/radios ================*/
input[type="checkbox"] + label,
input[type="radio"] + label {
  font-weight: normal; }

.label-hint {
  color: #999; }

/*================ Horizontal Form ================*/
form.form-horizontal,
.form-horizontal {
  margin-bottom: 0; }
  form.form-horizontal input[type="text"],
  form.form-horizontal input[type="search"],
  form.form-horizontal input[type="password"],
  form.form-horizontal input[type="email"],
  form.form-horizontal input[type="file"],
  form.form-horizontal input[type="number"],
  form.form-horizontal input[type="tel"],
  form.form-horizontal textarea,
  form.form-horizontal select,
  form.form-horizontal label,
  .form-horizontal input[type="text"],
  .form-horizontal input[type="search"],
  .form-horizontal input[type="password"],
  .form-horizontal input[type="email"],
  .form-horizontal input[type="file"],
  .form-horizontal input[type="number"],
  .form-horizontal input[type="tel"],
  .form-horizontal textarea,
  .form-horizontal select,
  .form-horizontal label {
    display: inline-block;
    margin-bottom: 0;
    width: auto; }

@media screen and (min-width: 768px) {
  .form-horizontal ~ .form-horizontal {
    padding-left: 15px; } }
@media screen and (max-width: 767px) {
  .form-horizontal ~ .form-horizontal {
    margin-top: 7.5px; } }
/*================ Error styles ================*/
input[type="text"].error,
input[type="search"].error,
input[type="password"].error,
input[type="email"].error,
input[type="file"].error,
input[type="number"].error,
input[type="tel"].error,
textarea.error {
  border-color: #d02e2e;
  background-color: #fff6f6;
  color: #d02e2e; }

label.error {
  color: #d02e2e; }

/*================ Input Group ================*/
.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .input-group .input-group-field:first-child, .input-group .input-group-btn:first-child,
  .input-group .input-group-btn:first-child > .btn,
  .input-group .input-group-btn:first-child > .btn--secondary,
  .input-group .input-group-btn:first-child > .btn--tertiary,
  .input-group .input-group-btn:first-child > input[type="submit"],
  .input-group input[type="hidden"]:first-child + .input-group-field,
  .input-group input[type="hidden"]:first-child + .input-group-btn > .btn,
  .input-group input[type="hidden"]:first-child + .input-group-btn > .btn--secondary,
  .input-group input[type="hidden"]:first-child + .input-group-btn > .btn--tertiary,
  .input-group input[type="hidden"]:first-child + .input-group-btn > input[type="submit"] {
    border-radius: 0; }
  .input-group .input-group-field:last-child,
  .input-group .input-group-btn:last-child > .btn,
  .input-group .input-group-btn:last-child > .btn--secondary,
  .input-group .input-group-btn:last-child > .btn--tertiary,
  .input-group .input-group-btn:last-child > input[type="submit"] {
    border-radius: 0; }
  .input-group input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin-top: -1px;
    margin-bottom: -1px; }

.input-group-field, .input-group-btn {
  display: table-cell;
  vertical-align: middle;
  margin: 0; }

.input-group .btn, .input-group .btn--secondary, .input-group .btn--tertiary, .input-group input[type="submit"], .input-group .input-group-field {
  height: 37px; }

.input-group .input-group-field {
  width: 100%;
  margin-bottom: 0; }

.input-group-btn {
  position: relative;
  white-space: nowrap;
  width: 1%;
  padding: 0; }

/*================ Search bar ================*/
@media screen and (max-width: 767px) {
  .search-bar {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; } }

/*================ Partials | Icons ================*/
@font-face {
  font-family: 'icons';
  src: url("//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/icons.eot?5955");
  src: url("//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/icons.eot?5955#iefix") format("embedded-opentype"), url("//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/icons.woff?5955") format("woff"), url("//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/icons.ttf?5955") format("truetype"), url("//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/icons.svg?5955#timber-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-fallback-text .icon {
  display: none; }
  .supports-fontface .icon-fallback-text .icon {
    display: inline-block; }

/*============================================================================
  A generic way to visually hide content while
  remaining accessible to screen readers (h5bp.com)
==============================================================================*/
.supports-fontface .icon-fallback-text .fallback-text {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

.icon:before {
  display: none; }

.supports-fontface .icon:before {
  display: inline;
  font-family: "icons";
  text-decoration: none;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*================ Module-specific styles ================*/
/*================ Module | Breadcrumbs and Tag Sorting ================*/
@media screen and (max-width: 967px) {
  .breadcrumb {
    display: none !important; } }
.breadcrumb a {
  color: gainsboro; }
  .breadcrumb a:hover, .breadcrumb a:focus, .breadcrumb a:active {
    color: #b10a0a; }

.breadcrumb {
  background: #ffffff;
  float: left;
  width: 100%;
  height: auto; }

.parallax {
  float: left;
  width: 100%;
  text-align: center;
  height: auto;
  background-attachment: fixed;
  padding: 60px 0px; }

.breadcrumb__sep {
  font-size: 1.4em; }

.breadcrumb h1, .breadcrumb .h1 {
  display: inline-block;
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em; }
  @media screen and (max-width: 767px) {
    .breadcrumb h1, .breadcrumb .h1 {
      font-size: 26px; } }
  @media screen and (max-width: 567px) {
    .breadcrumb h1, .breadcrumb .h1 {
      font-size: 22px;
      width: 100%;
      margin-top: 10px; } }

.tags {
  float: left;
  width: 100%;
  margin-bottom: 45px; }
  .tags a {
    display: inline-block;
    padding: 12px 7px 12px 0;
    margin-right: 12px;
    border-bottom: 1px dashed #36342f; }

.tags li:last-child a {
  border-bottom: none;
  padding-bottom: 0; }

.tag--active a {
  color: #b10a0a; }

/*================ Module | Footer ================*/
.site-footer a:hover {
  color: #934d4d; }

.site-footer {
  float: left;
  width: 100%; }
  @media screen and (max-width: 1199px) {
    .site-footer {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .site-footer {
      padding: 0px 0; } }
  .site-footer form {
    margin-bottom: 0; }
  .site-footer input {
    max-width: 250px;
    margin: 0; }
  .site-footer .text-center input {
    margin-left: auto;
    margin-right: auto;
    border: none; }
  .site-footer p {
    margin-bottom: 10px; }
  .site-footer ul {
    margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .site-footer {
      padding: 0px 20px 0px; } }
    @media screen and (min-width: 768px) and (max-width: 967px) {
      .site-footer {
        padding: 0px 0px 0px; } }
  @media screen and (max-width: 767px) {
    .site-footer .grid__item {
      margin-bottom: 30px; }
      .site-footer .grid__item:last-child {
        margin-bottom: 0; } }

input::-webkit-input-placeholder {
  color: #dcdcdc; }

input:-moz-placeholder {
  color: #dcdcdc; }

input::-moz-placeholder {
  color: #dcdcdc; }

input:-ms-input-placeholder {
  color: #dcdcdc; }

input {
  color: #dcdcdc; }

textarea::-webkit-input-placeholder {
  color: #dcdcdc; }

textarea:-moz-placeholder {
  color: #dcdcdc; }

textarea::-moz-placeholder {
  color: #dcdcdc; }

textarea:-ms-input-placeholder {
  color: #dcdcdc; }

textarea {
  color: #dcdcdc; }

/*================ Module | Notes and Form Feedback ================*/
.note,
.errors {
  display: inline-block;
  width: 100%;
  border-radius: 2px;
  padding: 15px 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  font-size: 0.9em;
  text-align: left; }
  .note ul,
  .note ol,
  .errors ul,
  .errors ol {
    margin-top: 0;
    margin-bottom: 0; }
  .note li:last-child,
  .errors li:last-child {
    margin-bottom: 0; }
  .note p,
  .errors p {
    margin-bottom: 0; }

.note {
  border-color: #36342f; }

.errors ul {
  list-style: disc outside;
  margin-left: 20px; }

.form-success {
  color: #56ad6a;
  background-color: #ecfef0;
  border-color: #56ad6a; }
  .form-success a {
    color: #56ad6a;
    text-decoration: underline; }
    .form-success a:hover {
      text-decoration: none; }

.form-error, .errors {
  color: #d02e2e;
  background-color: #fff6f6;
  border-color: #d02e2e; }
  .form-error a, .errors a {
    color: #d02e2e;
    text-decoration: underline; }
    .form-error a:hover, .errors a:hover {
      text-decoration: none; }

/*================ Module | Pagination ================*/
.pagination {
  margin-bottom: 1em; }

.pagination-custom {
  display: inline-block;
  width: 100%;
  padding-left: 0;
  margin-top: 30px;
  border-radius: 2px; }
  @media screen and (max-width: 567px) {
    .pagination-custom {
      margin-top: 40px; } }

.pagination-custom > li {
  display: inline-block; }

.pagination-custom > li > a,
.pagination-custom > li > span {
  position: relative;
  float: left;
  padding: 8px 13px;
  margin-left: -1px;
  line-height: 1.42;
  text-decoration: none;
  background: #b10a0a;
  color: #ffffff;
  margin-right: 2px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px; }
  .pagination-custom > li > a:hover,
  .pagination-custom > li > span:hover {
    background: #934d4d;
    color: #fff; }

.pagination-custom > li:first-child > a,
.pagination-custom > li:first-child > span {
  margin-left: 0; }

.pagination-custom > .active > a,
.pagination-custom > .active > span,
.pagination-custom > .active > a:hover,
.pagination-custom > .active > span:hover,
.pagination-custom > .active > a:focus,
.pagination-custom > .active > span:focus {
  z-index: 2;
  cursor: default;
  color: #fff;
  background: #934d4d; }

.pagination-custom > .disabled > span,
.pagination-custom > .disabled > a,
.pagination-custom > .disabled > a:hover,
.pagination-custom > .disabled > a:focus {
  cursor: not-allowed; }

.pagination-custom-lg > li > a,
.pagination-custom-lg > li > span {
  padding: 10px 16px;
  font-size: em(18px); }

.pagination-custom > li a.enable-arrow {
  background: #b10a0a;
  color: #ffffff; }
  .pagination-custom > li a.enable-arrow:hover {
    color: #fff;
    background: #934d4d; }

.pagination-custom-sm > li > a,
.pagination-custom-sm > li > span {
  padding: 8px 13px;
  font-size: em(12px); }

/*================ Module | Rich Text Editor ================*/
.rte h1, .rte .h1, .rte h2, .rte .h2, .rte h3, .rte .h3, .rte h4, .rte .h4, .rte h5, .rte .h5, .rte h6, .rte .h6 {
  margin-top: 2em; }
  .rte h1:first-child, .rte .h1:first-child, .rte h2:first-child, .rte .h2:first-child, .rte h3:first-child, .rte .h3:first-child, .rte h4:first-child, .rte .h4:first-child, .rte h5:first-child, .rte .h5:first-child, .rte h6:first-child, .rte .h6:first-child {
    margin-top: 0; }
.rte > div {
  margin-bottom: 15px; }
.rte ul, .rte ol {
  margin-left: 35px; }
.rte ul ul {
  list-style: circle outside; }
.rte li {
  margin-bottom: 0.4em; }
.rte img {
  max-width: 100%;
  height: auto; }
.rte table {
  table-layout: fixed; }

.rte--header {
  margin-bottom: 30px; }

/*================ Module | Section Headers ================*/
.section-header {
  margin-bottom: 15px; }

.section-header--small {
  margin-bottom: 0; }

.section-header--medium {
  margin-bottom: 7.5px; }

.section-header--large {
  margin-bottom: 30px; }

.section-header--breadcrumb {
  margin-bottom: 15px; }

.section-header__title {
  color: #b10a0a; }

.related-products-container .section-header__title {
  color: #b10a0a; }

span.large-font-text {
  font-size: 200px;
  font-weight: bold;
  float: left;
  width: 100%;
  margin: 0 0 30px;
  line-height: normal;
  color: #5a574f; }
  @media screen and (max-width: 967px) {
    span.large-font-text {
      font-size: 140px; } }
  @media screen and (max-width: 767px) {
    span.large-font-text {
      font-size: 90px; } }
  @media screen and (max-width: 767px) {
    span.large-font-text {
      font-size: 70px; } }

@media screen and (max-width: 1199px) {
  .site-footer h4:after, .site-footer .h4:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: 0; } }
@media screen and (max-width: 767px) {
  .site-footer h4:after, .site-footer .h4:after {
    position: initial;
    margin: 0 5px -3px; } }

.section-header__title--left,
.section-header__link--right {
  padding-bottom: 12px;
  margin-bottom: 0; }

.section-header__link--right {
  margin-top: 15px; }

@media screen and (min-width: 768px) {
  .section-header {
    display: table;
    width: 100%; }

  .section-header__title--left {
    display: table-cell;
    vertical-align: middle; }
    .section-header__title--left h1, .section-header__title--left .h1, .section-header__title--left h2, .section-header__title--left .h2, .section-header__title--left h3, .section-header__title--left .h3, .section-header__title--left h4, .section-header__title--left .h4,
    .section-header__title--left .h1, .section-header__title--left .h2, .section-header__title--left .h3, .section-header__title--left .h4 {
      margin-bottom: 0; }

  .section-header__link--right {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 550px;
    margin-top: 0; } }
  @media screen and (min-width: 768px) and (max-width: 767px) {
    .section-header__link--right {
      margin-bottom: 30px; } }

.section-header__link--right .form-horizontal {
  display: inline-block; }
.section-header__link--right .form-horizontal,
.section-header__link--right .collection-view {
  vertical-align: middle; }
.section-header__link--right select,
.section-header__link--right option {
  color: gainsboro; }
@media screen and (min-width: 568px) {
  .section-header__link--right label + select,
  .section-header__link--right .collection-view {
    margin-left: 15px; }
  .section-header__link--right label:not(.label--hidden) + select {
    margin-left: 5px; } }
@media screen and (min-width: 768px) {
  .section-header__link--right select {
    max-width: 200px; } }

@media screen and (max-width: 767px) {
  .template-collection .section-header__link--right {
    display: block;
    width: 100%;
    margin-top: 0; }
    .template-collection .section-header__link--right .form-horizontal {
      width: 100%; }
      .template-collection .section-header__link--right .form-horizontal select {
        width: 100%;
        margin-left: 0; } }
.view-more {
  clear: both;
  display: block;
  font-size: 0.85em;
  text-align: center;
  margin-top: 15px; }
  @media screen and (min-width: 768px) {
    .view-more {
      display: none; } }

/*================ Module | Site Header ================*/
.site-header__menubar {
  float: left;
  width: 100%;
  height: 150px; }
  @media screen and (max-width: 1199px) {
    .site-header__menubar {
      padding: 0 10px 0 10px; } }
  @media screen and (max-width: 967px) {
    .site-header__menubar {
      padding: 0 5px 0 5px; } }
  @media screen and (max-width: 767px) {
    .site-header__menubar {
      height: auto;
      padding: 10px 7px; } }

.menubar-section {
  padding: 0 0px; }

.site-header__logo {
  text-align: center;
  margin: 7px auto 7px 0px;
  max-width: 100%;
  font-size: 30px; }
  @media screen and (max-width: 967px) {
    .site-header__logo {
      font-size: 22px; } }
  @media screen and (max-width: 767px) {
    .site-header__logo {
      margin-bottom: 20px; } }
  .site-header__logo a {
    margin: 0 auto; }
  .site-header__logo a,
  .site-header__logo a:hover,
  .site-header__logo a:focus {
    text-decoration: none; }
  .site-header__logo img {
    margin: 0 auto; }

img {
  max-width: 100%; }

.site-header--text-links {
  margin-bottom: 0; }

/*============ If logo is on the same line as navigation ============ */
/*================ Header message (theme setting) ================*/
.header-message {
  text-align: center;
  margin: 15px 0 0;
  line-height: 1; }
  @media screen and (max-width: 1199px) {
    .header-message {
      margin-left: 20px; } }
  @media screen and (max-width: 767px) {
    .header-message {
      margin-bottom: 30px;
      margin-left: 0; } }
  @media screen and (min-width: 768px) {
    .header-message {
      margin-bottom: 15px; } }

/*================ View-specific styles ================*/
/*================ Templates | Blog and Comments ================*/
.comment {
  margin-bottom: 30px; }
  .comment + .comment {
    border-top: 1px solid #36342f;
    padding-top: 30px; }

.meta-sep {
  position: relative;
  top: -1px;
  padding: 0 5px;
  font-size: 0.7em; }

.blog-date {
  margin-top: -0.5em; }

aside h4 ~ h4, aside .h4 ~ h4, aside h4 ~ .h4, aside .h4 ~ .h4 {
  margin-top: 45px; }

aside time em {
  font-size: 0.8em; }

@media screen and (max-width: 767px) {
  article {
    margin-bottom: 30px; } }
.article__image {
  display: block;
  margin-bottom: 0; }

/*================ Templates | Cart Page ================*/
.cart__row {
  position: relative;
  padding: 20px 0;
  float: left;
  width: 100%; }
  .cart__row + .cart__row {
    border-bottom: 1px solid #36342f;
    border-left: 1px solid #36342f;
    border-right: 1px solid #36342f; }
  .cart__row:last-child {
    margin-bottom: 0;
    border-bottom: none;
    border-left: none;
    border-right: none; }
  .cart__row:last-child {
    padding-bottom: 0;
    border-top: 0 none; }
  .cart__row .js-qty {
    margin: 0; }

@media screen and (min-width: 768px) {
  .cart__row--table-large {
    display: table;
    table-layout: fixed;
    width: 100%; }
    .cart__row--table-large .grid__item {
      display: table-cell;
      vertical-align: middle;
      float: none;
      text-align: center; } }
.cart__image {
  display: block;
  margin-right: 15px; }
  @media screen and (max-width: 767px) {
    .cart__image {
      margin-right: 0; } }
  .cart__image img {
    display: block; }
    @media screen and (max-width: 767px) {
      .cart__image img {
        margin: auto; } }

.cart__subtotal,
.cart-additional-savings__price {
  margin: 0 0 0 10px;
  display: inline; }

.cart__mini-labels {
  display: block;
  font-size: em(12px); }
  @media screen and (min-width: 768px) {
    .cart__mini-labels {
      display: none; } }

input.cart__quantity-selector {
  width: 100px;
  margin: 0 auto; }

.cart__remove {
  display: block; }

.cart__note-add.is-hidden {
  display: none; }

.cart__note {
  display: none; }
  .cart__note.is-active {
    display: block; }

.cart-item__discount,
.cart-additional-savings__savings,
.cart-subtotal__savings {
  display: block; }

.cart .btn, .cart .btn--secondary, .cart .btn--tertiary, .cart input[type="submit"] {
  margin-bottom: 4px; }
  @media screen and (max-width: 567px) {
    .cart .btn, .cart .btn--secondary, .cart .btn--tertiary, .cart input[type="submit"] {
      padding: 10px; } }

.cart__additional_checkout {
  margin: 15px 0 0; }
  .cart__additional_checkout input {
    padding: 0; }
  .cart__additional_checkout .google-wallet-button-holder {
    display: inline-block;
    vertical-align: 10px; }

/*================ Templates | Product Page ================*/
.product-single__variants {
  display: none; }
  .no-js .product-single__variants {
    display: block; }

.product-single__photos {
  margin-bottom: 30px; }

.product-single__photos a, .product-single__photos img,
.product-single__thumbnails a,
.product-single__thumbnails img {
  display: block;
  margin: 0 auto;
  max-width: 100%; }

.image-zoom {
  cursor: move; }

.product-single__prices {
  margin-bottom: 15px;
  float: left;
  width: 100%; }

.product-single__price {
  color: #5a574f;
  font-size: 22.4px; }

.product-single__sale-price {
  opacity: 0.7;
  margin-left: 6px;
  font-size: 20.32px; }

.change-view.grid-view {
  background-color: #ebebeb;
  margin-right: 12px;
  color: #c2c2c2; }
  @media screen and (max-width: 967px) {
    .change-view.grid-view {
      margin-right: 5px; } }

.change-view {
  display: block;
  background: none;
  border: 0 none;
  float: left;
  padding: 7px 8px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: #36342f;
  line-height: 1; }
  @media screen and (max-width: 967px) {
    .change-view {
      width: 37px;
      height: 37px; } }
  @media screen and (max-width: 567px) {
    .change-view {
      float: none;
      display: inline-block;
      text-align: center;
      margin-left: -3px; } }
  .change-view:hover, .change-view:focus {
    color: #b10a0a; }

a {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.slide-link {
  display: block;
  position: relative; }
  .slide-link img {
    display: block; }

.slide_content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  margin: auto; }

.badge {
  float: left;
  position: absolute;
  color: #ffffff;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  font-size: em(12px);
  line-height: 1.1;
  z-index: 1; }

.badge--sold-out {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0%;
  right: 0;
  margin-top: 0;
  margin-left: 0;
  z-index: 2; }

.badge--sold-out .badge__text {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 0;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  line-height: 100px;
  background-color: #dcdcdc;
  color: #ffffff; }
  @media screen and (max-width: 1199px) {
    .badge--sold-out .badge__text {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      line-height: 100px; } }

.badge__text {
  display: inline-block;
  font-size: 13px;
  padding: 4px 6px;
  line-height: normal; }
  @media screen and (max-width: 1199px) {
    .badge__text {
      font-size: 12px;
      letter-spacing: 1px; } }
  @media screen and (max-width: 967px) {
    .badge__text {
      font-size: 12px;
      letter-spacing: 1px; } }

.badge__text--small {
  font-size: 8px;
  padding-top: 0; }

a.btn, a.btn--secondary, a.btn--tertiary {
  -webkit-transition-timing-function: linear; }

.dt-sc-hr-invisible-large {
  width: 100%;
  float: left;
  clear: both;
  margin: 45px 0px;
  padding: 0px;
  display: block; }
  @media screen and (max-width: 1599px) {
    .dt-sc-hr-invisible-large {
      margin: 30px 0px; } }

.dt-sc-hr-invisible-small {
  width: 100%;
  float: left;
  clear: both;
  margin: 20px 0px;
  padding: 0px;
  display: block; }

.dt-sc-hr-invisible-medium {
  width: 100%;
  float: left;
  clear: both;
  margin: 25px 0px;
  padding: 0px;
  display: block; }

.dt-sc-hr-invisible-very-small {
  width: 100%;
  float: left;
  clear: both;
  margin: 10px 0px;
  padding: 0px;
  display: block; }

.main-content {
  float: left;
  width: 100%;
  padding: 0 4%; }
  @media screen and (max-width: 1199px) {
    .main-content {
      padding: 0 20px; } }
  @media screen and (max-width: 967px) {
    .main-content {
      padding: 0 20px; } }
  @media screen and (max-width: 767px) {
    .main-content {
      padding: 0 15px; } }

.top_bar li button {
  background: none;
  border: none;
  margin: 0;
  line-height: normal;
  padding: 0; }

.top_bar li form {
  padding: 0; }

.top_bar {
  float: left;
  width: 100%;
  padding: 12px 15px 12px 15px;
  letter-spacing: 0; }
  @media screen and (max-width: 767px) {
    .top_bar {
      padding: 10px 7px 10px 7px; } }

.top_bar ul {
  list-style: none;
  padding: 0; }

.top_bar ul li {
  font-size: 13px;
  float: left;
  margin-right: 10px; }
  @media screen and (max-width: 967px) {
    .top_bar ul li {
      margin-right: 5px; } }
  @media screen and (max-width: 767px) {
    .top_bar ul li {
      display: inline-block;
      padding-right: 0; } }

.top_bar_left {
  float: left; }
  @media screen and (max-width: 767px) {
    .top_bar_left {
      float: none;
      display: inline-block;
      width: 100%;
      text-align: center; } }

.top_bar_menu {
  float: right; }
  @media screen and (max-width: 767px) {
    .top_bar_menu {
      float: left;
      width: 100%; } }

.top_bar_menu ul, .customer_account {
  float: left; }

.top_bar_menu ul li, .customer_account ul li {
  margin-right: 10px;
  padding-right: 0; }
  @media screen and (max-width: 767px) {
    .top_bar_menu ul li, .customer_account ul li {
      margin-bottom: 10px;
      margin-right: 10px; } }

.top_bar_menu ul li a, .customer_account ul li a, .top_bar ul li span {
  padding-right: 10px; }
  @media screen and (max-width: 767px) {
    .top_bar_menu ul li a, .customer_account ul li a, .top_bar ul li span {
      padding-right: 10px; } }

.top_bar_menu ul li:last-child a, .customer_account ul li:last-child a, .top_bar ul li:last-child span {
  padding-right: 0;
  border-right: none; }
  @media screen and (max-width: 767px) {
    .top_bar_menu ul li:last-child a, .customer_account ul li:last-child a, .top_bar ul li:last-child span {
      padding-right: 0px; } }

.top_bar_menu ul li:last-child, .customer_account ul li:last-child, .top_bar ul li:last-child {
  margin-right: 0;
  padding-right: 0; }

.site-header {
  z-index: 999;
  padding: 0px;
  float: left;
  width: 100%;
  position: relative; }
  @media screen and (max-width: 767px) {
    .site-header {
      padding: 0; } }

#slidedown-modal form {
  padding: 0;
  margin: 0; }

#slidedown-modal form button {
  background: none;
  border: none;
  margin: 0;
  padding: 0; }

#slidedown-modal form button span {
  margin: 5px 0px 0px 20px;
  float: right; }

#slidedown-modal {
  -webkit-box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.15);
  width: 210px;
  padding: 10px 0px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  webkit-transform: scaleY(0);
  -khtml-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0;
  -khtml-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0; }
  @media screen and (max-width: 1199px) {
    #slidedown-modal {
      right: 10px; } }
  @media screen and (max-width: 967px) {
    #slidedown-modal {
      top: 100%; } }

.slidedown_section:hover #slidedown-modal, .wrapper-top-cart:hover #slidedown-cart {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1); }

.slidedown_section {
  cursor: pointer;
  float: left;
  z-index: 9;
  position: relative; }
  @media screen and (max-width: 767px) {
    .slidedown_section {
      float: none; } }

#menubar-section .slidedown_section ul li a {
  float: left;
  text-transform: capitalize;
  padding: 0 3px; }

.slidedown_section ul li span {
  float: right;
  margin: 0 0 0 20px;
  font-size: 14px;
  line-height: 24px;
  display: none; }

.slidedown_section ul li.logout-link span.fa {
  padding: 5px 0; }

.slidedown_section ul li span.fa {
  float: left;
  margin: 0 15px 0 0;
  display: block;
  font-size: 14px;
  line-height: 24px; }

.slidedown_section ul {
  width: 100%;
  float: left;
  padding: 0; }

#slidedown-modal form {
  padding: 0;
  margin: 0; }

#slidedown-modal form button {
  background: none;
  border: none;
  margin: 0;
  padding: 0; }

#slidedown-modal form button span {
  margin: 0px 0px 0px 0px;
  float: right; }

.slidedown_section:hover #slidedown-modal {
  opacity: 1;
  visibility: visible; }

.slidedown_section ul li span {
  float: right;
  margin: 0 0 0 20px;
  font-size: 14px;
  line-height: 24px;
  display: none; }

.slidedown_section ul li.logout-link span.fa {
  padding: 5px 0; }

.slidedown_section ul li span.fa {
  float: left;
  margin: 0 15px 0 0;
  display: block;
  font-size: 14px;
  line-height: 24px; }

.slidedown_section ul {
  width: 100%;
  float: left;
  list-style: none; }

#currencies-picker .selection-box {
  float: right;
  margin: 0;
  position: relative; }

#currencies-picker {
  width: 100%;
  margin: 0; }

#currencies-picker .selection-box select {
  border: none;
  padding: 0; }

#currencies-picker p {
  margin: 0 12px 0 0px; }

#currencies-picker {
  padding: 35px 0;
  margin: 0 !important; }
  @media screen and (max-width: 767px) {
    #currencies-picker {
      margin: 0 !important; } }

.currency_wrapper.dropdown-toggle {
  position: relative; }

.header-bar__left ul {
  list-style: none;
  margin: 0;
  float: left;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .header-bar__left ul {
      display: inline-block;
      width: 100%;
      text-align: center;
      float: none; } }

.header-bar .social-icons li {
  float: left; }
  @media screen and (max-width: 767px) {
    .header-bar .social-icons li {
      float: none;
      display: inline-block;
      text-align: center; } }

.header-bar .social-icons li a {
  float: left;
  padding: 0;
  font-size: 14px; }
  @media screen and (max-width: 767px) {
    .header-bar .social-icons li a {
      float: none; } }

.header-bar .social-icons li:last-child a {
  padding-right: 0; }

.header-bar__left li a:hover {
  color: #934d4d; }

.header-bar__left li span {
  margin-right: 5px; }
  @media screen and (max-width: 967px) {
    .header-bar__left li span {
      margin-right: 3px; } }
  @media screen and (max-width: 767px) {
    .header-bar__left li span {
      margin-left: 5px; } }

.header-bar__left li {
  float: left;
  padding: 11px 15px 11px 0; }
  @media screen and (max-width: 967px) {
    .header-bar__left li {
      padding: 11px 5px 11px 0; } }
  @media screen and (max-width: 767px) {
    .header-bar__left li {
      float: none;
      display: inline-block; } }

.header-bar__left li.phone-no:before {
  content: "\f095";
  font-family: FontAwesome;
  float: left;
  margin-right: 5px; }
  @media screen and (max-width: 967px) {
    .header-bar__left li.phone-no:before {
      margin-right: 3px; } }

.header-bar__left li.skype:before {
  content: "\f17e";
  font-family: FontAwesome;
  float: left;
  margin-right: 5px; }
  @media screen and (max-width: 967px) {
    .header-bar__left li.skype:before {
      margin-right: 3px; } }

.social-icons li a span {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

/*.header-bar__left #currencies-picker p:before { content:"";background:url(//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/currency.png?5955) no-repeat; width:15px;height:15px;float:left;margin-right:5px; }*/
.header-bar__left #currencies-picker p {
  margin: 0; }

#currencies-picker select {
  margin: 0;
  width: 45px;
  float: left;
  line-height: normal; }

.header-bar__left #currencies-picker .selection-box {
  float: right; }

#currencies-picker {
  margin-top: 1px; }
  @media screen and (max-width: 767px) {
    #currencies-picker {
      float: none !important;
      display: inline-block;
      position: relative;
      top: 4px; } }

#currencies-picker select {
  background: inherit;
  border: none;
  background: transparent;
  outline: none;
  padding: 0; }

.header-bar__left ul li i {
  float: left; }

.header-bar .selection-box {
  position: relative; }

.header-sticky {
  z-index: 999;
  float: left;
  width: 100%; }

.is-sticky {
  height: auto !important; }

.is-sticky .header-sticky {
  float: left;
  width: 100%;
  left: 0;
  right: 0; }

.is-sticky .site-header__menubar {
  position: static; }

.is-sticky .header-sticky {
  width: 100%; }
  @media screen and (max-width: 767px) {
    .is-sticky .header-sticky {
      position: relative !important; } }

.is-sticky .sticky-logo img {
  display: inline-block;
  float: left;
  max-width: 97%; }
  @media screen and (max-width: 967px) {
    .is-sticky .sticky-logo img {
      max-width: 15%;
      padding-top: 10px;
      padding-right: 10px; } }

.is-sticky .header-bar__search span {
  border: none; }

.header-bar__search form .header-bar__search-input {
  background: transparent;
  border: none; }

.is-sticky #slidedown-cart h3, .is-sticky #slidedown-cart .h3 {
  border-top: 1px solid #fff; }

.is-sticky #slidedown-cart:before {
  border-bottom: 9px solid #fff;
  right: 65px; }

.is-sticky #slidedown-cart:after {
  border-bottom: 9px solid #000;
  right: 66px;
  top: -7px; }

.is-sticky .header-bar__search form .btn:hover, .is-sticky .header-bar__search form .btn--secondary:hover, .is-sticky .header-bar__search form .btn--tertiary:hover, .is-sticky .header-bar__search form input[type="submit"]:hover {
  background: transparent;
  color: #934d4d;
  border-color: #934d4d; }

.is-sticky ul.search-results {
  display: none !important; }

.sticky-logo img {
  display: none; }

.sticky-animate {
  width: 100%;
  float: left;
  position: relative; }

#currencies-picker .selection-box:before {
  content: "\f107";
  font-family: FontAwesome;
  margin: auto;
  right: 5px;
  top: 0px;
  z-index: 1;
  position: absolute;
  pointer-events: none; }
  @media screen and (max-width: 967px) {
    #currencies-picker .selection-box:before {
      right: 3px; } }

.menubar-section #Togglemodal {
  cursor: pointer;
  float: left;
  padding: 33px 0 33px 15px;
  font-size: 16px;
  cursor: pointer; }

#menubar-section ul li a.icon-cart-arrow {
  padding: 33px 0px 33px 25px;
  font-size: 16px;
  cursor: pointer; }
  @media screen and (max-width: 967px) {
    #menubar-section ul li a.icon-cart-arrow {
      padding: 33px 10px 33px 10px; } }

.header-bar__search {
  float: left;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .header-bar__search {
      width: 100%;
      float: none;
      display: inline-block;
      text-align: center; } }
  @media screen and (max-width: 567px) {
    .header-bar__search {
      width: 100%; } }

.header-bar__search form {
  margin: 0; }
  @media screen and (max-width: 767px) {
    .header-bar__search form {
      width: 85%;
      display: inline-block;
      text-align: center;
      margin: auto;
      border: 1px solid #36342f; } }
  @media screen and (max-width: 567px) {
    .header-bar__search form {
      width: 100%; } }

.header-bar {
  float: left;
  width: 100%;
  padding: 5px 15%; }
  @media screen and (max-width: 1199px) {
    .header-bar {
      padding: 0 10px; } }
  @media screen and (max-width: 967px) {
    .header-bar {
      padding: 0; } }
  @media screen and (max-width: 767px) {
    .header-bar {
      border: none; } }

.header-bar__left {
  float: left; }
  @media screen and (max-width: 767px) {
    .header-bar__left {
      float: none;
      padding: 10px 0; } }

.menu-middle-section {
  padding: 60px 0 0px 30px; }
  @media screen and (max-width: 767px) {
    .menu-middle-section {
      float: none;
      padding: 0 0 0px;
      display: inline-block;
      text-align: center;
      width: 100%; } }

.menu-middle-section ul {
  list-style: none;
  padding: 0;
  float: right; }

.header-bar__search form .header-bar__search-input {
  float: left;
  float: left;
  width: 85%;
  display: inline-block;
  margin: 0;
  padding: 14px 10px;
  background: transparent;
  min-height: 50px; }
  @media screen and (max-width: 1199px) {
    .header-bar__search form .header-bar__search-input {
      width: 78%; } }
  @media screen and (max-width: 967px) {
    .header-bar__search form .header-bar__search-input {
      width: 75%; } }
  @media screen and (max-width: 767px) {
    .header-bar__search form .header-bar__search-input {
      width: 87%;
      border: none; } }
  @media screen and (max-width: 567px) {
    .header-bar__search form .header-bar__search-input {
      width: 75%; } }

.header-bar__search form .btn, .header-bar__search form .btn--secondary, .header-bar__search form .btn--tertiary, .header-bar__search form input[type="submit"] {
  font-size: 16px;
  font-weight: normal;
  background: transparent;
  border: none;
  border-left: none;
  border-radius: 0;
  display: inline-block;
  padding: 33px 20px;
  min-height: 50px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 1199px) {
    .header-bar__search form .btn, .header-bar__search form .btn--secondary, .header-bar__search form .btn--tertiary, .header-bar__search form input[type="submit"] {
      padding: 33px 5px;
      font-size: 13px; } }
  @media screen and (max-width: 767px) {
    .header-bar__search form .btn, .header-bar__search form .btn--secondary, .header-bar__search form .btn--tertiary, .header-bar__search form input[type="submit"] {
      float: right;
      border-left: 1px solid #36342f;
      border-top: none;
      border-right: none;
      border-bottom: none; } }

.header-bar__search form .btn:hover, .header-bar__search form .btn--secondary:hover, .header-bar__search form .btn--tertiary:hover, .header-bar__search form input[type="submit"]:hover {
  color: #934d4d; }

.header-bar__search form .btn:hover span, .header-bar__search form .btn--secondary:hover span, .header-bar__search form .btn--tertiary:hover span, .header-bar__search form input[type="submit"]:hover span, .header-bar__search form .btn span, .header-bar__search form .btn--secondary span, .header-bar__search form .btn--tertiary span, .header-bar__search form input[type="submit"] span {
  border: none; }

.header-bar__search span {
  padding: 0; }

#minicart_total {
  display: inline-block;
  line-height: normal;
  text-align: left;
  float: left;
  margin-top: -4px;
  font-size: 14px; }
  @media screen and (max-width: 967px) {
    #minicart_total {
      font-size: 14px;
      line-height: 12px; } }
  @media screen and (max-width: 767px) {
    #minicart_total {
      float: none;
      display: inline-block; } }

#minicart_total span {
  margin: 0; }

.cart_text {
  float: left; }

.cart_text p {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  line-height: normal; }

.mini-cart-icon {
  float: left; }

.mini-cart-details span {
  line-height: normal;
  margin: 0; }

.mini-cart-details #cartCount i {
  font-size: 30px;
  margin-right: 28px;
  margin-top: 4px;
  float: left; }

.mini-cart-details {
  float: right;
  margin: 0px 0px 0px 0px; }
  @media screen and (max-width: 967px) {
    .mini-cart-details {
      margin: 0; } }
  @media screen and (max-width: 767px) {
    .mini-cart-details {
      border-right: 1px solid #36342f;
      float: none;
      display: inline-block;
      width: 100%;
      text-align: center; } }

.mini-cart-icon i {
  float: right; }

#cartCount {
  font-size: 16px;
  text-align: left;
  position: relative;
  display: inline-block; }
  @media screen and (max-width: 1199px) {
    #cartCount {
      font-size: 13px; } }

.cart__note-add {
  text-decoration: underline;
  margin-bottom: 10px; }

.cart__note textarea {
  width: 85%; }

/* Menu */
.menu-right li a.icon-cart-arrow {
  float: left;
  margin: 0 !important;
  position: relative; }

.menu-right ul {
  list-style: none; }

.menu-right {
  list-style: none; }

@media screen and (max-width: 767px) {
  .sb-icon-search {
    background: #934d4d;
    color: #fff; } }

.sb-search-submit:hover, .sb-search.sb-search-open .sb-icon-search {
  background: #934d4d;
  color: #fff; }
  @media screen and (max-width: 767px) {
    .sb-search-submit:hover, .sb-search.sb-search-open .sb-icon-search {
      background: none;
      color: none; } }

.menu {
  list-style: none; }

.menu-right > li a {
  float: left;
  padding: 40px 15px;
  font-size: 20px; }

.menu-right {
  list-style: none; }
  @media screen and (max-width: 767px) {
    .menu-right {
      float: none;
      display: inline-block;
      width: 100%; } }

.menu-right {
  list-style: none;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .menu-right {
      float: none;
      display: inline-block;
      line-height: 10px; } }

.menu-right-section {
  float: right; }
  @media screen and (max-width: 767px) {
    .menu-right-section {
      float: none;
      display: inline-block;
      width: 100%;
      text-align: center; } }

@media screen and (max-width: 767px) {
  .menu-right li {
    float: none;
    display: inline-block; } }

.featured-img-content .featured-img img, .featuredItems .product-container a img, .doubleside-section-products img {
  -webkit-transition: all 5s ease;
  -moz-transition: all 5s ease;
  -o-transition: all 5s ease;
  -ms-transition: all 5s ease;
  transition: all 5s ease; }

.sb-search {
  position: relative;
  margin: 23px 0;
  width: 0%;
  min-width: 50px;
  height: 50px;
  float: right;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  transition: width 0.3s;
  -webkit-backface-visibility: hidden; }
  @media screen and (max-width: 767px) {
    .sb-search {
      width: 100%; } }

.sb-search input[type="text"] {
  border: none; }
  @media screen and (max-width: 767px) {
    .sb-search input[type="text"] {
      border: 1px solid #36342f; } }

.sb-search input[type="text"] {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 16px 65px 16px 20px; }
  @media screen and (max-width: 767px) {
    .sb-search input[type="text"] {
      height: 55px;
      border: 1px solid #36342f; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .sb-search input[type="text"] {
    text-indent: -9999px; } }
@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .sb-search.sb-search-open input[type="text"] {
    text-indent: 0px; } }
.sb-icon-search, .sb-search input[type="submit"] {
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  line-height: 50px !important;
  text-align: center;
  cursor: pointer; }

.sb-search input[type="submit"] {
  background: #fff;
  /* IE needs this */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 8 */
  filter: alpha(opacity=0);
  /* IE 5-7 */
  opacity: 0;
  color: transparent;
  border: none;
  outline: none;
  z-index: -1; }

.sb-icon-search {
  z-index: 90;
  font-size: 18px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased; }

/* Open state */
.sb-search.sb-search-open, .no-js .sb-search {
  width: 100%; }

.sb-search.sb-search-open .sb-icon-search, .no-js .sb-search .sb-icon-search {
  z-index: 11; }

.sb-search.sb-search-open .sb-search-submit, .no-js .sb-search .sb-search-submit {
  z-index: 90; }

input[type="search"].sb-search-input {
  -webkit-appearance: none;
  -webkit-border-radius: 0px; }

/* search page  */
.search-page-form.search-bar {
  background: none; }

.search-page-form.search-bar {
  width: 45%; }
  @media screen and (max-width: 967px) {
    .search-page-form.search-bar {
      width: 70%; } }
  @media screen and (max-width: 767px) {
    .search-page-form.search-bar {
      width: 100%; } }

/* Title */
.border-title h2, .border-title .h2 {
  font-family: Libre Baskerville;
  margin-bottom: 5px; }

.border-title h6, .border-title .h6 {
  color: #5a574f; }

.border-title p:after {
  background: #b10a0a;
  float: left;
  width: 4px;
  height: 72px;
  position: absolute;
  left: 0;
  top: 0; }

.border-title p {
  position: relative; }

.border-title {
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 40px; }
  @media screen and (max-width: 567px) {
    .border-title {
      margin-bottom: 20px; } }

/* Grid banner type 11 */
.grid-banner-type-11 {
  margin-top: 1%; }

.grid-banner-type-11 .grid__item.img-hover-effect {
  width: 40%; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .grid__item.img-hover-effect {
      width: 100%; } }

.grid-banner-type-11 .grid__item:first-child.img-hover-effect {
  margin-right: 1%; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .grid__item:first-child.img-hover-effect {
      margin-right: 0;
      margin-bottom: 10px; } }

.grid-banner-type-11 .grid-banner-type-11-left-column {
  float: left;
  width: 40%; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .grid-banner-type-11-left-column {
      width: 100%; } }

.grid-banner-type-11 .grid-banner-type-11-right-column {
  float: left;
  width: 60%; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .grid-banner-type-11-right-column {
      width: 100%;
      margin-right: 0; } }

.grid-banner-type-11 .grid-banner-type-11-right-2-column {
  float: left;
  width: 40%; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .grid-banner-type-11-right-2-column {
      width: 100%;
      margin-right: 0; } }

.grid-banner-type-11 .grid-banner-type-11-left-2-column {
  float: left;
  width: 60%; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .grid-banner-type-11-left-2-column {
      width: 100%;
      margin-bottom: 10px; } }

@media screen and (max-width: 767px) {
  .grid-banner-type-11 .grid-banner-type-11_top {
    margin-top: 0; } }

.grid-banner-type-11 .img-hover-effect:hover img {
  display: block;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2); }

.grid-banner-type-11 .img-hover-effect img {
  display: block;
  -webkit-transition: all 1.5s ease;
  -moz-transition: all 1.5s ease;
  -ms-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  transition: all 1.5s ease;
  filter: grayscale(100%); }

.grid-banner-type-11 .img-hover-effect:hover img {
  filter: grayscale(0%); }

.grid-banner-type-11 .img-hover-effect {
  position: relative;
  box-shadow: 0 0 0 0; }

.grid-banner-type-11 .featured-content {
  z-index: 1;
  width: 70%;
  position: absolute;
  padding: 40px 30px;
  bottom: 30px;
  left: 30px;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -moz-transform: translateY(0%); }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content {
      width: 80%;
      padding: 20px 20px; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .featured-content {
      left: 0;
      padding-left: 15px; } }

.grid-banner-type-11 .featured-content h6, .grid-banner-type-11 .featured-content .h6 {
  margin: 0 0 20px;
  text-transform: inherit;
  font-family: Maven Pro;
  font-weight: normal;
  font-size: 14px; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content h6, .grid-banner-type-11 .featured-content .h6 {
      font-size: 14px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-11 .featured-content h6, .grid-banner-type-11 .featured-content .h6 {
      font-size: 12px; } }

.grid-banner-type-11 .featured-content h2, .grid-banner-type-11 .featured-content .h2 {
  text-transform: inherit;
  margin-bottom: 25px;
  font-size: 35px; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content h2, .grid-banner-type-11 .featured-content .h2 {
      font-size: 20px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .featured-content h2, .grid-banner-type-11 .featured-content .h2 {
      font-size: 28px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-11 .featured-content h2, .grid-banner-type-11 .featured-content .h2 {
      font-size: 22px;
      margin-bottom: 7px; } }

.grid-banner-type-11 .featured-content p {
  font-size: 36px;
  font-weight: normal;
  position: relative; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content p {
      font-size: 20px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-11 .featured-content p {
      font-size: 14px;
      margin-bottom: 7px; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .featured-content p {
      font-size: 20px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-11 .featured-content p {
      font-size: 14px;
      margin-bottom: 10px; } }

.grid-banner-type-11 .featured-content p span:before {
  content: "";
  float: left;
  width: 40px;
  height: 1px;
  position: relative;
  top: 23px;
  margin-right: 10px; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content p span:before {
      top: 13px; } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-11 .featured-content p span:before {
      top: 7px;
      width: 20px; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .featured-content p span:before {
      top: 13px;
      width: 40px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-11 .featured-content p span:before {
      content: none;
      background: none !important; } }

.grid-banner-type-11 .featured-content p span:after {
  content: "";
  float: right;
  width: 40px;
  height: 1px;
  position: relative;
  top: 23px;
  margin-left: 10px; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content p span:after {
      top: 13px; } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-11 .featured-content p span:after {
      top: 7px;
      width: 20px; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .featured-content p span:after {
      top: 13px;
      width: 40px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-11 .featured-content p span:after {
      content: none;
      background: none !important; } }

.grid-banner-type-11 .featured-content p {
  float: left;
  width: 100%; }

@media screen and (max-width: 567px) {
  .grid-banner-type-11 .featured-content .btn, .grid-banner-type-11 .featured-content .btn--secondary, .grid-banner-type-11 .featured-content .btn--tertiary, .grid-banner-type-11 .featured-content input[type="submit"] {
    padding: 6px 10px; } }

.grid-banner-type-11 .featured-content p span {
  float: left;
  font-weight: 300; }

.grid-banner-type-11 .featured-content .offer_text {
  font-size: 70px;
  line-height: 1;
  font-weight: bold; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-11 .featured-content .offer_text {
      font-size: 24px; } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-11 .featured-content .offer_text {
      font-size: 18px; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-11 .featured-content .offer_text {
      font-size: 40px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-11 .featured-content .offer_text {
      font-size: 20px; } }

.grid-banner-type-11 .img-hover-effect {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden; }

.grid-banner-type-11 .img-hover-effect::before {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.grid-banner-type-11 .featured-content .btn, .grid-banner-type-11 .featured-content .btn--secondary, .grid-banner-type-11 .featured-content .btn--tertiary, .grid-banner-type-11 .featured-content input[type="submit"] {
  background: transparent;
  padding: 0; }

/* Grid banner type 18 Ugo*/
.grid-banner-type-18 .grid__item {
  padding: 0; }

.grid-banner-type-18 .banner-images {
  margin: 0; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-18 .banner-images {
      margin: 0 0; } }

.grid-banner-type-18 .collectionItems {
  margin-left: 0px; }
  @media screen and (max-width: 1599px) {
    .grid-banner-type-18 .collectionItems {
      margin-left: 0px; } }
  @media screen and (max-width: 567px) {
    .grid-banner-type-18 .collectionItems {
      margin-left: 0px; } }

.grid-banner-type-18 .featured-content {
  opacity: 0;
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(1.1);
  -webkit-transform: translateY(-50%) scale(1.1);
  -moz-transform: translateY(-50%) scale(1.1);
  -ms-transform: translateY(-50%) scale(1.1);
  width: 75%;
  margin: auto;
  z-index: 1; }

.grid-banner-type-18 .ovrly17:hover .featured-content {
  opacity: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(1);
  -webkit-transform: translateY(-50%) scale(1);
  -moz-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1); }

.grid-banner-type-18 .featured-content h2, .grid-banner-type-18 .featured-content .h2 {
  border-radius: 30px;
  font-size: 20px;
  padding: 15px 45px;
  display: inline-block;
  margin: auto;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-18 .featured-content h2, .grid-banner-type-18 .featured-content .h2 {
      padding: 15px 30px;
      font-size: 14px; } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-18 .featured-content h2, .grid-banner-type-18 .featured-content .h2 {
      font-size: 18px; } }

.grid-banner-type-18 .collectionItems img {
  display: block;
  position: relative; }

.grid-banner-type-18 .collectionItems .ovrly17:hover:before, .grid-banner-type-18 .collectionItems .ovrly17:hover:after {
  -webkit-transform: scale(1);
  opacity: 1;
  transform: scale(1);
  opacity: 0.7; }

.grid-banner-type-18 .collectionItems .ovrly17:after {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1); }

.grid-banner-type-18 .collectionItems .ovrly17:before {
  z-index: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1); }

.grid-banner-type-18 .ovrly17 .ovrly {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.grid-banner-type-18 .ovrly17 {
  width: 100%;
  overflow: hidden;
  position: relative; }

.grid-banner-type-18 .ovrly17:hover .ovrly {
  opacity: 1; }

.grid-banner-type-18 img {
  display: block;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }

.grid-banner-type-18 .ovrly17:hover img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1); }

/* home-product-grid-type-12-b */
.home-product-grid-type-12-b .product-grid-type-12 {
  position: relative;
  float: left;
  width: 100%; }

.home-product-grid-type-12-b ul li .products {
  margin: 0; }

.home-product-grid-type-12-b .product-detail .grid-link__title {
  font-weight: normal; }

.home-product-grid-type-12-b .products .product-detail .grid-link__meta {
  float: none;
  display: inline-block; }

.home-product-grid-type-12-b .products .product-detail .grid-link__meta .product_price {
  float: none;
  display: inline-block; }

.home-product-grid-type-12-b .nav_product-grid-type__12-b {
  text-align: center; }

.home-product-grid-type-12-b .nav_product-grid-type__12-b a i {
  font-weight: bold;
  line-height: 40px; }

.home-product-grid-type-12-b .nav_product-grid-type__12-b a.next {
  right: -5%; }
  @media screen and (max-width: 1599px) {
    .home-product-grid-type-12-b .nav_product-grid-type__12-b a.next {
      right: 0; } }

.home-product-grid-type-12-b .nav_product-grid-type__12-b a {
  opacity: 1; }

.home-product-grid-type-12-b .nav_product-grid-type__12-b a.prev {
  left: -5%; }
  @media screen and (max-width: 1599px) {
    .home-product-grid-type-12-b .nav_product-grid-type__12-b a.prev {
      left: 0; } }

.home-product-grid-type-12-b .nav_product-grid-type__12-b a {
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media screen and (max-width: 967px) {
    .home-product-grid-type-12-b .nav_product-grid-type__12-b a {
      position: static;
      -webkit-transform: translateY(0%);
      -moz-transform: translateY(0%);
      -ms-transform: translateY(0%);
      -o-transform: translateY(0%);
      transform: translateY(0%); } }

@media screen and (max-width: 967px) {
  .home-product-grid-type-12-b .nav_product-grid-type__12-b .owl-prev, .home-product-grid-type-12-b .nav_product-grid-type__12-b .owl-next {
    float: none;
    display: inline-block; } }

.home-product-grid-type-12-b {
  float: left;
  width: 100%; }

/* Header Type 8 */
.header-type-8 .site-header__menubar {
  float: none;
  display: table;
  position: relative;
  padding: 0%; }

.header-type-8 .menubar-section {
  float: none;
  display: table-cell;
  vertical-align: middle;
  width: auto; }
  @media screen and (max-width: 967px) {
    .header-type-8 .menubar-section {
      width: auto; } }

.header-type-8 .site-header__menubar h1, .header-type-8 .site-header__menubar .h1 {
  float: none;
  display: table-cell;
  vertical-align: middle;
  width: auto; }
  @media screen and (max-width: 767px) {
    .header-type-8 .site-header__menubar h1, .header-type-8 .site-header__menubar .h1 {
      display: inline-block;
      width: 100%; } }

.header-type-8 .site-header__logo img {
  vertical-align: middle;
  position: static;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%); }

.header-type-8 .site-header__menubar h1, .header-type-8 .site-header__menubar .h1 {
  text-align: left; }
  @media screen and (max-width: 767px) {
    .header-type-8 .site-header__menubar h1, .header-type-8 .site-header__menubar .h1 {
      text-align: center;
      padding: 20px 0; } }

.header-type-8 .flag-dropdown-menu {
  opacity: 1;
  position: static;
  visibility: visible; }

.header-type-8 .flag-dropdown-menu li {
  display: inline-block;
  width: 18%; }

.header-type-8 .menu_bar_right, .header-type-8 .slidedown_section, .header-type-8 .header_currency ul li.currency, .header-type-8 .header-search {
  float: none;
  display: inline-block;
  width: auto; }

.header-type-8 .header-panel-top ul li, .header-type-8 .flag-dropdown-menu {
  padding-bottom: 10px;
  box-shadow: 0 0 0 0;
  -webkit-box-shadow: 0px 0px 0px 0;
  -ms-transform: 0px 0px 0px 0px;
  -o-transform: 0px 0px 0px 0px;
  box-shadow: 0px 0px 0px 0; }

.header-type-8 .flag-dropdown-menu {
  box-shadow: 0 0 0 0;
  -webkit-box-shadow: 0px 0px 0px 0;
  -ms-transform: 0px 0px 0px 0px;
  -o-transform: 0px 0px 0px 0px;
  box-shadow: 0px 0px 0px 0; }

.header-type-8 .flag-dropdown-menu {
  background: transparent; }

.header-type-8 .slidedown_section ul {
  padding: 0px;
  text-align: left; }

.header-type-8 .customer_account a i {
  margin-right: 7px; }

.header-type-8 .customer_account li {
  border: none; }

.header-type-8 .slidedown_section ul li span {
  display: block; }

.header-type-8 .slidedown_section ul.flag-dropdown-menu li span, .header-type-8 #minicart_total {
  display: none; }

.header-type-8 #slidedown-modal {
  padding: 20px; }

.header-type-8 .header-panel-top ul li.header_cart {
  padding: 0px; }
  @media screen and (max-width: 767px) {
    .header-type-8 .header-panel-top ul li.header_cart {
      float: none;
      display: inline-block; } }

.header-type-8 .header-panel-top ul li {
  margin: 0;
  padding: 0 0 10px; }
  @media screen and (max-width: 767px) {
    .header-type-8 .header-panel-top ul li {
      padding: 0; } }

.header-type-8 #slidedown-modal {
  top: 100%;
  right: 0; }

.header-type-8 .header-search span, .header-type-8 a.icon-cart-arrow, .header-type-8 .header_currency ul select {
  font-size: 18px;
  padding: 0px 0px;
  display: inline-block; }
  @media screen and (max-width: 1199px) {
    .header-type-8 .header-search span, .header-type-8 a.icon-cart-arrow, .header-type-8 .header_currency ul select {
      font-size: 14px; } }
  @media screen and (max-width: 967px) {
    .header-type-8 .header-search span, .header-type-8 a.icon-cart-arrow, .header-type-8 .header_currency ul select {
      padding: 0;
      font-size: 16px; } }
  @media screen and (max-width: 767px) {
    .header-type-8 .header-search span, .header-type-8 a.icon-cart-arrow, .header-type-8 .header_currency ul select {
      padding: 0 3px 0 0; } }

.header-type-8 .header-search, .header-type-8 .header_cart {
  padding: 0;
  float: none;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .header-type-8 .header-search, .header-type-8 .header_cart {
      vertical-align: middle; } }

.header-type-8 .header-search span, .header-type-8 .header-bar__module.cart .baskettop a.icon-cart-arrow:before, .header-type-8 .header_currency ul li.currency {
  padding: 0px 0px 0 15px;
  display: inline-block; }
  @media screen and (max-width: 1199px) {
    .header-type-8 .header-search span, .header-type-8 .header-bar__module.cart .baskettop a.icon-cart-arrow:before, .header-type-8 .header_currency ul li.currency {
      padding: 0px 0px 0 10px; } }
  @media screen and (max-width: 767px) {
    .header-type-8 .header-search span, .header-type-8 .header-bar__module.cart .baskettop a.icon-cart-arrow:before, .header-type-8 .header_currency ul li.currency {
      float: left; } }

.header-type-8 .slidedown_section ul li span.fa, .header-type-8 .slidedown_section ul li span {
  display: none; }

.header-type-8 .menu-icon {
  text-align: right; }
  @media screen and (max-width: 767px) {
    .header-type-8 .menu-icon {
      padding: 20px 0;
      display: inline-block;
      width: 100%;
      text-align: center;
      border-top: 1px solid #36342f;
      border-bottom: 1px solid #36342f; } }

.header-type-8 .slidedown_section #Togglemodal i {
  padding: 0 0 0px 7px; }

.header-type-8 #shopify-section-header {
  float: none;
  width: auto;
  display: table-cell;
  vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .header-type-8 #shopify-section-header {
      display: block; } }

.header-type-8 .menu-tool {
  float: left;
  width: 100%; }

.header-type-8 .customer_account ul li {
  border: none;
  margin: 0;
  padding: 0 0 10px; }

.header-type-8 .customer_account ul li:last-child {
  padding-bottom: 0; }

.header-type-8 .mini-cart-details #cartCount i {
  margin-right: 0px;
  font-size: 18px; }

.header-type-8 #minicart_total {
  float: left;
  margin-top: -8px;
  font-size: 14px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%; }

.header-type-8 #slidedown-modal {
  padding: 20px; }

.header-type-8 .menubar-section #Togglemodal {
  cursor: pointer;
  font-size: 18px;
  padding: 0; }
  @media screen and (max-width: 967px) {
    .header-type-8 .menubar-section #Togglemodal {
      font-size: 16px; } }

.template-index .header-type-8 .site-header {
  position: absolute;
  top: 0;
  z-index: 999; }
  @media screen and (max-width: 967px) {
    .template-index .header-type-8 .site-header {
      position: relative; } }

.header-type-8 .site-header__menubar {
  background: none; }

.header-type-8 #cartCount {
  float: left;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.header-type-8 .mini-cart-details #cartCount i {
  margin-top: 0; }

.header-type-8 .site-header {
  padding: 0 30px; }
  @media screen and (max-width: 1199px) {
    .header-type-8 .site-header {
      padding: 0 20px; } }
  @media screen and (max-width: 767px) {
    .header-type-8 .site-header {
      padding: 0 7px; } }

.header-type-8 .site-header__menubar {
  position: relative; }

.header-type-8 .menubar-section #Togglemodal i, .header-type-8 .header-search span {
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.header-type-8 .header-bar__module.cart #cartCount {
  font-size: 10px;
  float: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  line-height: 15px;
  text-align: center; }

.header-type-8 #slidedown-cart li {
  background: transparent; }

.header-type-8 .header-bar__module.cart .baskettop a.icon-cart-arrow {
  display: inline-block;
  text-align: center; }

.header-type-8 .header-bar__module.cart .baskettop a.icon-cart-arrow i {
  display: inline-block; }

.header-type-8 .detail {
  top: -18px;
  float: right;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto; }

@media screen and (max-width: 767px) {
  .header-type-8 #slidedown-modal:before, .header-type-8 #slidedown-modal:after {
    right: 0;
    left: auto; } }

@media screen and (max-width: 767px) {
  .header-type-8 .header-search input#search {
    display: none; } }

@media screen and (max-width: 767px) {
  .header-type-8 .search-bar__form {
    border: none !important; } }

@media screen and (max-width: 767px) {
  .header-type-8 .header-search button i {
    line-height: 50px; } }

@media screen and (max-width: 767px) {
  .header-type-8 .customer_account {
    width: 100%;
    text-align: left;
    margin-top: 10px; } }

.header-type-8 .flag-dropdown-menu {
  opacity: 1;
  visibility: visible;
  display: block;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1); }

@media screen and (max-width: 767px) {
  .header-type-8 .menubar-section {
    float: right;
    width: auto; } }

.header-type-8 .search-bar__form button {
  position: static; }

.header-type-8 .search-bar__form {
  border: none;
  width: 90%; }
  @media screen and (max-width: 767px) {
    .header-type-8 .search-bar__form {
      width: 80%; } }

@media screen and (max-width: 767px) {
  .header-type-8 .search-bar__form button, .header-type-8 .search-bar input[type="search"] {
    font-size: 16px; } }

.header-type-8 .search-bar button {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.header-type-8 .search-bar input[type="search"], .header-type-8 .search-bar__form button, .header-type-8 .search-bar__icon-button {
  font-size: 70px;
  height: auto;
  line-height: normal; }
  @media screen and (max-width: 1599px) {
    .header-type-8 .search-bar input[type="search"], .header-type-8 .search-bar__form button, .header-type-8 .search-bar__icon-button {
      font-size: 50px; } }
  @media screen and (max-width: 967px) {
    .header-type-8 .search-bar input[type="search"], .header-type-8 .search-bar__form button, .header-type-8 .search-bar__icon-button {
      font-size: 16px; } }

.supports-fontface .header-type-8 .search-bar__icon-cell {
  width: auto; }

.header-type-8 .drawer--top {
  width: 80%; }
  @media screen and (max-width: 1199px) {
    .header-type-8 .drawer--top {
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .header-type-8 .drawer--top {
      width: 98%; } }

.header-type-8 .search-bar {
  background: transparent; }

.header-type-8 .search-bar__form {
  border-bottom: 2px solid #36342f; }

.supports-fontface .header-type-8 .search-bar__icon-cell {
  float: right; }

.header-type-8 .header_currency {
  padding: 0;
  float: none;
  display: inline-block;
  width: auto; }

.header-type-8 .header_currency ul select {
  padding: 0 15px 0 5px; }

.header-type-8 .header_currency ul li.currency {
  padding: 0px 0px 0 10px;
  float: none;
  vertical-align: middle; }
  @media screen and (max-width: 1199px) {
    .header-type-8 .header_currency ul li.currency {
      padding: 0 0 0 0px; } }

.header-type-8 .is-sticky .grid__item:nth-child(2n) {
  float: none; }

/* New Css */
.header-type-8 .site-nav > li > a {
  padding: 0px 20px; }
  @media screen and (max-width: 1199px) {
    .header-type-8 .site-nav > li > a {
      padding: 0 12px; } }

/* Grid banner type 32 */
.grid-banner-type-32 .section-one {
  margin-bottom: 0px; }

.grid-banner-type-32 .section-one:nth-child(3n) {
  margin-bottom: 0; }

.grid-banner-type-32 .section-one .grid__item, .grid-banner-type-32 .section-two .grid__item {
  float: left;
  margin: 0; }
  @media screen and (max-width: 767px) {
    .grid-banner-type-32 .section-one .grid__item, .grid-banner-type-32 .section-two .grid__item {
      width: 100%; } }

.grid-banner-type-32 .section-one, .grid-banner-type-32 .section-two {
  position: relative;
  float: left;
  width: 100%; }

.grid-banner-type-32 .block-content {
  text-align: left;
  float: left;
  width: 45%;
  padding: 0 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: 1; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-32 .block-content {
      padding: 30px;
      width: 100%;
      position: static;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
      -moz-transform: translateY(0%);
      -ms-transform: translateY(0%); } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-32 .block-content {
      padding: 50px; } }

.grid-banner-type-32 .block-content h2, .grid-banner-type-32 .block-content .h2 {
  margin-bottom: 30px; }
  @media screen and (max-width: 967px) {
    .grid-banner-type-32 .block-content h2, .grid-banner-type-32 .block-content .h2 {
      font-size: 20px;
      margin-bottom: 15px; } }

.grid-banner-type-32 .block-content h4, .grid-banner-type-32 .block-content .h4 {
  font-size: 40px;
  text-transform: inherit;
  margin-bottom: 15px; }
  @media screen and (max-width: 1599px) {
    .grid-banner-type-32 .block-content h4, .grid-banner-type-32 .block-content .h4 {
      margin-bottom: 15px;
      font-size: 24px; } }
  @media screen and (max-width: 967px) {
    .grid-banner-type-32 .block-content h4, .grid-banner-type-32 .block-content .h4 {
      margin-bottom: 10px;
      font-size: 18px; } }

.grid-banner-type-32 .block-content p {
  margin-bottom: 30px; }
  @media screen and (max-width: 1199px) {
    .grid-banner-type-32 .block-content p {
      margin-bottom: 15px;
      letter-spacing: 0; } }
  @media screen and (max-width: 767px) {
    .grid-banner-type-32 .block-content p {
      font-size: 13px;
      line-height: 1.7; } }

.grid-banner-type-32 img {
  display: block; }

.grid-banner-type-32 .ovrly09 {
  width: 100%;
  overflow: hidden;
  position: relative; }

.grid-banner-type-32 .ovrly09 .overlay {
  height: 100%;
  left: -195%;
  width: 155%;
  position: absolute;
  top: 0;
  -webkit-transform: skewX(-36deg);
  -moz-transform: skewX(-36deg);
  -ms-transform: skewX(-36deg);
  -o-transform: skewX(-36deg);
  transform: skewX(-36deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.grid-banner-type-32 .ovrly09:hover .overlay {
  left: -35%; }

.grid-banner-type-32 .ovrly10 {
  width: 100%;
  overflow: hidden;
  position: relative; }

.grid-banner-type-32 .ovrly10 .overlay {
  height: 100%;
  left: 195%;
  width: 155%;
  position: absolute;
  top: 0;
  -webkit-transform: skewX(36deg);
  -moz-transform: skewX(36deg);
  -ms-transform: skewX(36deg);
  -o-transform: skewX(36deg);
  transform: skewX(36deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.grid-banner-type-32 .ovrly10:hover .overlay {
  left: -35%; }

/*Triangle Icon Block - A */
.triangle-icon-block-a .icon_wrapper {
  position: relative;
  float: left;
  margin: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.triangle-icon-block-a .icon_wrapper .icon {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
  font-size: 20px;
  float: left;
  text-align: center;
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 80px;
  width: 80px; }

.triangle-icon-block-a .icon_wrapper.icon-left .icon {
  float: right;
  position: absolute; }

.triangle-icon-block-a .icon_wrapper.icon-right .icon-content {
  float: right; }

.triangle-icon-block-a h4, .triangle-icon-block-a .h4 {
  margin-bottom: 25px;
  font-size: 20px; }
  @media screen and (max-width: 1199px) {
    .triangle-icon-block-a h4, .triangle-icon-block-a .h4 {
      font-size: 14px;
      margin-bottom: 15px; } }

.triangle-icon-block-a .center-block {
  text-align: center; }

.triangle-icon-block-a .center-block img {
  display: inline-block;
  padding: 10% 10px; }

.triangle-icon-block-a .triangle-icon-wrapper {
  float: left;
  position: relative;
  width: 100%; }

.triangle-icon-block-a .icon_wrapper.icon-left {
  text-align: right;
  padding: 30px 100px 30px 0px; }
  @media screen and (max-width: 1199px) {
    .triangle-icon-block-a .icon_wrapper.icon-left {
      padding: 15px 100px 15px 0px; } }

.triangle-icon-block-a .triangle-icon-wrapper p {
  margin-bottom: 0; }

.triangle-icon-block-a .center-block h4, .triangle-icon-block-a .center-block .h4 {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%) rotate(-45deg);
  -webkit-transform: translateY(-50%) rotate(-45deg);
  -moz-transform: translateY(-50%) rotate(-45deg);
  -ms-transform: translateY(-50%) rotate(-45deg);
  -o-transform: translateY(-50%) rotate(-45deg);
  font-size: 24px; }
  @media screen and (max-width: 967px) {
    .triangle-icon-block-a .center-block h4, .triangle-icon-block-a .center-block .h4 {
      font-size: 14px; } }

.triangle-icon-block-a .icon_wrapper.icon-right .icon {
  position: absolute;
  left: 0; }

.triangle-icon-block-a .icon_wrapper.icon-left .icon {
  right: 0; }

.triangle-icon-block-a .icon_wrapper.icon-right {
  padding: 30px 0px 30px 100px; }
  @media screen and (max-width: 1199px) {
    .triangle-icon-block-a .icon_wrapper.icon-right {
      padding: 15px 0px 15px 100px; } }

.triangle-icon-block-a {
  float: left;
  width: 100%; }

.triangle-icon-block-a i {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

/*  Support Block Type 4 Cosmetics */
.support-block-type-4 ul {
  margin: 0 -20px;
  list-style: none;
  padding: 0;
  display: inline-block;
  width: auto;
  padding: 20px 0 0; }
  @media screen and (max-width: 967px) {
    .support-block-type-4 ul {
      padding: 40px 0; } }
  @media screen and (max-width: 767px) {
    .support-block-type-4 ul {
      margin: 0; } }

.support-block-type-4 .support_section {
  position: relative;
  padding: 0 20px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: auto; }
  @media screen and (max-width: 767px) {
    .support-block-type-4 .support_section {
      margin-bottom: 30px; } }

@media screen and (max-width: 967px) {
  .support-block-type-4 .support_section p {
    font-size: 14px; } }

.support-block-type-4 .support_section h6, .support-block-type-4 .support_section .h6 {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  width: 200px;
  position: relative;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: Maven Pro; }

.support-block-type-4 .support_icon a {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  font-size: 20px;
  margin-bottom: 20px; }

.support-block-type-4 .support-wrapper {
  z-index: 1;
  position: relative;
  float: left;
  width: 100%;
  margin: 0; }

.support-block-type-4 .support_section p {
  margin-bottom: 0; }

.support-block-type-4 .support_section h6::before, .support-block-type-4 .support_section .h6::before, .support-block-type-4 .support_section h6::after, .support-block-type-4 .support_section .h6::after {
  margin: 0px -5px 0px 0px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  bottom: -5px;
  right: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.support-block-type-4 .support_section h6::before, .support-block-type-4 .support_section .h6::before {
  left: 50%;
  margin: 0px 0px 0px -5px; }

.support-block-type-4 .support_section:hover h6::before, .support-block-type-4 .support_section:hover .h6::before {
  left: 100%; }

.support-block-type-4 .support_section:hover h6::after, .support-block-type-4 .support_section:hover .h6::after {
  right: 100%; }

.support-block-type-4 .support_section:hover .support_icon a {
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.support-block-type-4 .support-wrapper::before {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  opacity: 0.8; }

/* Home page */
.collectionItems .wide--one-half {
  width: 49.25%;
  margin-right: 1.5%; }
  @media screen and (max-width: 767px) {
    .collectionItems .wide--one-half {
      width: 100%;
      margin: 0; } }

.collectionItems .wide--one-half:last-child {
  margin-right: 0; }

.full-width-grid-banner.wide--one-half {
  width: 48.5%;
  margin-right: 3%;
  margin-top: 0; }
  @media screen and (max-width: 567px) {
    .full-width-grid-banner.wide--one-half {
      width: 100%;
      margin: 0 0 15px; } }

.first .full-width-grid-banner.wide--one-half {
  margin-bottom: 3%; }

.full-width-grid-banner.wide--one-half:last-child {
  margin-right: 0; }

.full-width-grid-banner.wide--one-half:last-child {
  margin-right: 0; }

.img-hover-effect {
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.img-hover-effect .featured-content {
  float: left; }

.full-width-grid-banner .img-hover-effect .featured-content h4, .full-width-grid-banner .img-hover-effect .featured-content .h4 {
  position: absolute;
  top: 0;
  padding: 30px;
  text-transform: uppercase;
  font-family: Maven Pro; }
  @media screen and (max-width: 967px) {
    .full-width-grid-banner .img-hover-effect .featured-content h4, .full-width-grid-banner .img-hover-effect .featured-content .h4 {
      font-size: 16px; } }

.full-width-grid-banner .img-hover-effect .featured-content .text_count {
  color: #36342f;
  position: absolute;
  bottom: 0;
  font-size: 100px;
  font-weight: bold;
  padding: 20px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 1599px) {
    .full-width-grid-banner .img-hover-effect .featured-content .text_count {
      font-size: 70px; } }
  @media screen and (max-width: 1199px) {
    .full-width-grid-banner .img-hover-effect .featured-content .text_count {
      font-size: 50px; } }

.full-width-grid-banner .img-hover-effect:hover .featured-content .text_count {
  opacity: 1; }

/* Client Section */
.client-section .owl-carousel .owl-item {
  padding: 4px 10px 0; }

.client-section .owl-carousel img {
  margin: auto;
  padding: 10px; }

.client-section .owl-carousel .item {
  padding: 0;
  border-radius: 0; }

.client-section .owl-carousel {
  padding: 45px 0;
  position: relative; }

.client-section {
  float: left;
  width: 100%;
  padding: 0; }

.client-section .section-seven {
  position: relative; }

.client-section .nav_brand_type a.prev {
  left: -15px; }
  @media screen and (max-width: 1599px) {
    .client-section .nav_brand_type a.prev {
      left: 0; } }

.client-section .nav_brand_type a {
  color: #dcdcdc;
  font-size: 25px;
  border: none;
  background: transparent;
  float: left;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute; }

.client-section .nav_brand_type a.next {
  right: -15px; }
  @media screen and (max-width: 1599px) {
    .client-section .nav_brand_type a.next {
      right: 0; } }

.client-section .nav_brand_type {
  z-index: 2;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 40%;
  -ms-transform: translateY(-40%);
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%); }

.client-section .owl-theme .owl-controls {
  text-align: center;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0; }

.client-section .owl-theme .owl-controls .owl-page span {
  width: 10px;
  height: 10px;
  border-radius: 100%; }

.client-section .nav_brand_type a:hover {
  color: #934d4d; }

/* Sorting */
.portfolio-container {
  margin-top: 60px; }

.filter-grid-type-1 .sorting-container {
  display: inline-block;
  width: 100%;
  text-align: center; }

.filter-grid-type-1 .sorting-container a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  padding: 0 10px; }

.short-desc p {
  position: relative;
  display: inline-block;
  width: 42%;
  padding-bottom: 7px;
  padding-bottom: 7px;
  margin-bottom: 0px;
  letter-spacing: 1px; }
  @media screen and (max-width: 1599px) {
    .short-desc p {
      width: 60%; } }
  @media screen and (max-width: 967px) {
    .short-desc p {
      width: 70%; } }
  @media screen and (max-width: 767px) {
    .short-desc p {
      width: 100%; } }

.column.no-space {
  padding: 15px;
  margin-bottom: 20px; }
  @media screen and (max-width: 967px) {
    .column.no-space {
      padding: 5px; } }

.short-desc p:after {
  background: #b10a0a;
  content: "";
  display: inline-block;
  margin: auto;
  width: 70px;
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: -5px; }

/*wide-banner-type-4-block*/
.wide-banner-type-4 .wide-banner-type-4-block {
  position: relative;
  float: left;
  width: 100%;
  padding: 100px 0; }

.wide-banner-type-4 .wide-banner-type-4-content {
  position: relative;
  width: 50%;
  margin: auto;
  text-align: center; }
  @media screen and (max-width: 967px) {
    .wide-banner-type-4 .wide-banner-type-4-content {
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .wide-banner-type-4 .wide-banner-type-4-content {
      width: 100%;
      padding: 0 20px; } }

.wide-banner-type-4 .wide-banner-type-4-content h2, .wide-banner-type-4 .wide-banner-type-4-content .h2 {
  padding: 15px 0;
  margin-bottom: 0px;
  line-height: normal;
  text-transform: inherit; }
  @media screen and (max-width: 1199px) {
    .wide-banner-type-4 .wide-banner-type-4-content h2, .wide-banner-type-4 .wide-banner-type-4-content .h2 {
      font-size: 20px; } }
  @media screen and (max-width: 567px) {
    .wide-banner-type-4 .wide-banner-type-4-content h2, .wide-banner-type-4 .wide-banner-type-4-content .h2 {
      font-size: 27px; } }

.wide-banner-type-4 .wide-banner-type-4-content p {
  font-size: 18px;
  margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .wide-banner-type-4 .wide-banner-type-4-content p {
      font-size: 14px; } }

.wide-banner-type-4 {
  float: left;
  width: 100%; }

.wide-banner-type-4 .small-icon {
  font-size: 40px;
  margin-bottom: 15px; }
  @media screen and (max-width: 1199px) {
    .wide-banner-type-4 .small-icon {
      font-size: 30px; } }

/* Newsletter Kids */
.newsletter-block-type-3 .parallax {
  margin: auto;
  text-align: center;
  padding: 0; }

.newsletter-block-type-3 form .btn:before, .newsletter-block-type-3 form .btn--secondary:before, .newsletter-block-type-3 form .btn--tertiary:before, .newsletter-block-type-3 form input[type="submit"]:before {
  border: none; }

.newsletter-block-type-3 form .btn, .newsletter-block-type-3 form .btn--secondary, .newsletter-block-type-3 form .btn--tertiary, .newsletter-block-type-3 form input[type="submit"] {
  border-right: none;
  border-top: none;
  border-left: none;
  border-radius: 0;
  width: auto;
  background: transparent;
  border-bottom: 1px solid #b10a0a;
  display: inline-block;
  height: auto;
  padding: 20px 50px;
  margin-left: 10px; }
  @media screen and (max-width: 967px) {
    .newsletter-block-type-3 form .btn, .newsletter-block-type-3 form .btn--secondary, .newsletter-block-type-3 form .btn--tertiary, .newsletter-block-type-3 form input[type="submit"] {
      margin-left: 0;
      margin-top: 5px; } }

.newsletter-block-type-3 form .btn:hover, .newsletter-block-type-3 form .btn--secondary:hover, .newsletter-block-type-3 form .btn--tertiary:hover, .newsletter-block-type-3 form input[type="submit"]:hover {
  border-bottom: 1px solid #934d4d; }

.newsletter-block-type-3 form {
  float: none;
  padding: 0;
  display: inline-block;
  width: 55%; }
  @media screen and (max-width: 1199px) {
    .newsletter-block-type-3 form {
      width: 60%; } }
  @media screen and (max-width: 967px) {
    .newsletter-block-type-3 form {
      width: 85%; } }
  @media screen and (max-width: 767px) {
    .newsletter-block-type-3 form {
      width: 90%; } }
  @media screen and (max-width: 567px) {
    .newsletter-block-type-3 form {
      width: 100%; } }

.newsletter-block-type-3 form input[type="email"] {
  box-shadow: none;
  display: inline-block;
  padding: 20px;
  width: 60%;
  border: none;
  font-size: 16px;
  line-height: 1.7; }
  @media screen and (max-width: 567px) {
    .newsletter-block-type-3 form input[type="email"] {
      width: 100%; } }

.newsletter-block-type-3 .newslet_title {
  text-align: center; }
  @media screen and (max-width: 767px) {
    .newsletter-block-type-3 .newslet_title {
      font-size: 20px; } }

.newsletter-block-type-3 form input[type="email"]::-webkit-input-placeholder {
  opacity: 0.8;
  color: #fff; }

.newsletter-block-type-3 form input[type="email"]::-moz-placeholder {
  opacity: 0.8;
  color: #fff; }

.newsletter-block-type-3 form input[type="email"]:-ms-input-placeholder {
  opacity: 0.8;
  color: #fff; }

.newsletter-block-type-3 form input[type="email"]:-moz-placeholder {
  opacity: 0.8;
  color: #fff; }

.newsletter-block-type-3 form input[type="email"]:focus {
  background-color: rgba(255, 255, 255, 0.4); }

.newsletter-block-type-3 p {
  text-align: center;
  width: 60%;
  margin: 20px auto 0 auto; }
  @media screen and (max-width: 567px) {
    .newsletter-block-type-3 p {
      width: 100%; } }

.newsletter-block-type-3 {
  margin: 0 auto;
  text-align: center;
  float: left;
  width: 100%; }
  @media screen and (max-width: 567px) {
    .newsletter-block-type-3 {
      padding: 40px 20px; } }

.newsletter-block {
  float: left;
  width: 100%;
  padding: 5% 0;
  position: relative;
  z-index: 1; }

.newsletter-block.news-block-3::before {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  z-index: -1; }

/*  Home Product Grid Type 5 */
@media screen and (max-width: 1199px) {
  .home-product-grid-type-5 .product-detail .grid-link__meta {
    font-size: 16px; } }

@media screen and (max-width: 1199px) {
  .home-product-grid-type-5 .products .spr-badge {
    font-size: 10px !important;
    padding: 5px 0; } }
@media screen and (max-width: 967px) {
  .home-product-grid-type-5 .products .spr-badge {
    font-size: 9px !important; } }

@media screen and (max-width: 1199px) {
  .home-product-grid-type-5 .products .product-detail form a.add-cart-btn {
    font-size: 18px; } }

.home-product-grid-type-5 .full_width_tab ul li.grid__item.item-row {
  padding-bottom: 20px; }

@media screen and (max-width: 767px) {
  .home-product-grid-type-5 .full_width_tab .post-large--one-half {
    width: 100%;
    argin: 0; } }

.home-product-grid-type-5 .left {
  padding-right: 10px; }
  @media screen and (max-width: 767px) {
    .home-product-grid-type-5 .left {
      padding-right: 0px; } }

.home-product-grid-type-5 .right {
  padding-left: 10px; }
  @media screen and (max-width: 767px) {
    .home-product-grid-type-5 .right {
      padding-right: 0px; } }

.home-product-grid-type-5 .full_width_tab ul {
  margin: 0 -15px; }
  @media screen and (max-width: 767px) {
    .home-product-grid-type-5 .full_width_tab ul {
      margin: 0; } }

.home-product-grid-type-5 {
  float: left;
  width: 100%; }

.site-header {
  z-index: 999;
  padding: 0px;
  float: left;
  width: 100%;
  position: relative; }
  @media screen and (max-width: 767px) {
    .site-header {
      padding: 0; } }

/* Top Products */
.full_width_tab li {
  padding: 0px; }

ul li.grid__item.item-row .products, #recently-viewed-products-grid .grid__item .products {
  border-radius: 0px 0px; }

ul li.grid__item.item-row, #recently-viewed-products-grid .grid__item {
  padding: 0 15px 20px; }
  @media screen and (max-width: 1599px) {
    ul li.grid__item.item-row, #recently-viewed-products-grid .grid__item {
      padding: 0 10px 20px; } }
  @media screen and (max-width: 567px) {
    ul li.grid__item.item-row, #recently-viewed-products-grid .grid__item {
      margin-bottom: 20px;
      padding: 0; } }

.full_width_tab ul {
  list-style: none;
  padding: 0; }

@media screen and (max-width: 567px) {
  .full_width_tab ul li.post-large--one-third {
    margin-bottom: 20px; } }

.full_width_tab ul li .products .product-container, .products .product-container {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.full_width_tab {
  float: left;
  width: 100%; }

.full_width_tab .tab-inner ul .owl-item:last-child li .products {
  border-right: 1px solid #36342f; }

#recently-viewed-products-grid .product-image img {
  display: block; }

#recently-viewed-products-grid .products form {
  float: none;
  margin: 0; }

#recently-viewed-products-grid .products .product-label {
  display: none; }

#recently-viewed-products-grid .products .btn, #recently-viewed-products-grid .products .btn--secondary, #recently-viewed-products-grid .products .btn--tertiary, #recently-viewed-products-grid .products input[type="submit"] {
  margin: 0; }

.recently-viewed-products .regular-product, .recently-viewed-products .sale {
  margin: 0; }

.recently-viewed-products {
  float: left;
  width: 100%;
  clear: both;
  display: none; }

.recently-viewed-products .action {
  float: left;
  width: 100%;
  margin-top: 7px; }

.recently-viewed-products .products:hover .product-detail .grid-link__title {
  opacity: 1;
  visibility: visible; }

.recently-viewed-products .products:hover .spr-badge {
  opacity: 1;
  visibility: visible; }

.recently-viewed-products .products:hover .grid-link__meta {
  opacity: 1;
  visibility: visible; }

#recently-viewed-products-grid .grid__item:nth-child(4n+1) {
  clear: both; }
  @media screen and (max-width: 967px) {
    #recently-viewed-products-grid .grid__item:nth-child(4n+1) {
      clear: none; } }

@media screen and (max-width: 967px) {
  #recently-viewed-products-grid .grid__item:nth-child(3n+1) {
    clear: both; } }
@media screen and (max-width: 767px) {
  #recently-viewed-products-grid .grid__item:nth-child(3n+1) {
    clear: none; } }

@media screen and (max-width: 767px) {
  #recently-viewed-products-grid .grid__item:nth-child(2n+1) {
    clear: both; } }
@media screen and (max-width: 567px) {
  #recently-viewed-products-grid .grid__item:nth-child(2n+1) {
    clear: none; } }

@media screen and (max-width: 767px) {
  .quick-view-text {
    display: none; } }

.full_width_tab {
  float: left;
  width: 100%; }

.full_width_tab .tab-inner ul .owl-item:last-child li .products {
  border-right: 1px solid #36342f; }

.products:hover .badge--sold-out .badge__text {
  opacity: 0; }

.product-hover-1.products:hover .product-container, .full_width_tab ul li .product-hover-1.products:hover .product-container {
  border: 2px solid transparent;
  -moz-border-image: -moz-linear-gradient(left, white 0%, white 100%);
  -webkit-border-image: -webkit-linear-gradient(left, white 0%, white 100%);
  border-image: linear-gradient(to right, white 0%, white 100%);
  border-image-slice: 1; }

.product-hover-1 .product-container .ImageOverlayCa {
  height: 100%;
  left: 0;
  top: 0%;
  opacity: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

.product-hover-1 .product-container:hover .ImageOverlayCa {
  opacity: 1; }

.product-hover-1 .product-container:hover .product-button {
  top: 50%; }

.product-hover-1.products .product-detail .product_left {
  float: left;
  width: 75%;
  margin-right: 5%; }

.product-hover-1.products .product-detail form {
  width: 20%; }

.product-hover-1.products .product-container, .full_width_tab ul li .product-hover-1.products .product-container {
  border: 2px solid #36342f; }

.product-hover-1.products .product-detail form a.add-cart-btn {
  text-align: center;
  margin: auto;
  float: right;
  font-size: 16px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #b10a0a;
  color: #ffffff; }
  @media screen and (max-width: 1599px) {
    .product-hover-1.products .product-detail form a.add-cart-btn {
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 14px; } }

@media screen and (max-width: 1599px) {
  .product-hover-1.products .product-detail .grid-link__meta del {
    width: 100%;
    margin: 0; } }

@media screen and (max-width: 967px) {
  .product-hover-1.products .product-detail .grid-link__meta .grid-link__org_price, .product-hover-1.products .product-detail del {
    width: 100%;
    margin: 0; } }

.product-hover-14.products .product-container .ImageOverlayCa {
  opacity: 0;
  top: 0; }

.product-hover-14.products .product-container:hover .ImageOverlayCa {
  opacity: 1; }

.product-hover-14.products .product-container .product-button a, .product-hover-14.products .product-container .product-button button, .product-hover-14.products .product-container .product-button span {
  -webkit-transform: translatey(100%);
  -moz-transform: translatey(100%);
  -ms-transform: translatey(100%);
  -o-transform: translatey(100%);
  transform: translatey(100%);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.product-hover-14.products .product-container:hover .product-button a, .product-hover-14.products .product-container:hover .product-button button, .product-hover-14.products .product-container:hover .product-button span {
  -webkit-transform: translatey(0%);
  -moz-transform: translatey(0%);
  -ms-transform: translatey(0%);
  -o-transform: translatey(0%);
  transform: translatey(0%); }

.product-hover-14.products .product-container .product-button {
  bottom: 0%;
  left: 0%;
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  top: auto;
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

.product-hover-14.products .product-container:hover .product-button {
  top: auto;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

.product-hover-14.products .product-detail .grid-link__meta .grid-link__sale_price {
  font-size: 20px; }
  @media screen and (max-width: 967px) {
    .product-hover-14.products .product-detail .grid-link__meta .grid-link__sale_price {
      font-size: 14px; } }

.product-hover-14.products .product-detail {
  text-align: center; }

.product-hover-14.products .product-container .product-button a, .product-hover-14.products .product-container .product-button span {
  text-transform: uppercase;
  width: 50%;
  height: auto;
  line-height: normal;
  padding: 15px;
  font-size: 12px; }
  @media screen and (max-width: 1599px) {
    .product-hover-14.products .product-container .product-button a, .product-hover-14.products .product-container .product-button span {
      padding: 15px 10px; } }
  @media screen and (max-width: 1199px) {
    .product-hover-14.products .product-container .product-button a, .product-hover-14.products .product-container .product-button span {
      padding: 10px;
      font-size: 12px; } }
  @media screen and (max-width: 967px) {
    .product-hover-14.products .product-container .product-button a, .product-hover-14.products .product-container .product-button span {
      padding: 10px 5px;
      font-size: 10px; } }
  @media screen and (max-width: 767px) {
    .product-hover-14.products .product-container .product-button a, .product-hover-14.products .product-container .product-button span {
      padding: 15px 5px;
      font-size: 12px; } }

.product-hover-14.products .product-button form {
  width: 50%; }
  @media screen and (max-width: 767px) {
    .product-hover-14.products .product-button form {
      width: 100%; } }

.product-hover-14.products .product-container .product-button form a {
  width: 100%; }

.product-hover-14.products .product-container .product-button a {
  float: left; }

.product-hover-14.products .product-container .product-button a b {
  margin-right: 10px;
  display: none; }

.product-hover-14.products .product-container .product-button a i {
  font-style: normal; }

.home-product-grid-type-11 .product-hover-14.products .product-container a i {
  display: none; }

.home-product-grid-type-11 .product-hover-14.products .product-container a b {
  margin-right: 0px; }

.product-hover-14.products .item-swatch li {
  float: none;
  display: inline-block; }

.product-hover-14.products .product-container .product-button form a, .product-hover-14.products .product-container .product-button span {
  background: #b10a0a; }

.product-hover-14.products .product-container .product-button form a:hover, .product-hover-14.products .product-container .product-button span:hover {
  background-color: #934d4d;
  color: #fff; }

.product-hover-9 .product-container:hover .product-button {
  top: auto; }

.product-hover-9.products .product-detail .grid-link__meta .product_price {
  width: 100%; }

.product-hover-9 .product-detail .grid-link__meta .grid-link__org_price, .product-hover-9 .product-detail .grid-link__meta .grid-link__sale_price {
  float: none;
  display: inline-block;
  width: auto; }

.product-hover-8.products .product-detail {
  text-align: center; }

.product-hover-9.products .product-detail .item-swatch li {
  float: none;
  display: inline-block; }

.product-hover-9.product-container .ImageOverlayCa {
  height: 100%;
  left: 0;
  top: 0%;
  opacity: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

.product-hover-9 .product-container:hover .ImageOverlayCa {
  opacity: 1; }

.product-hover-9.products .product-container .product-button {
  top: 0;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
  opacity: 0; }

@-webkit-keyframes prdmove {
  0% {
    -webkit-transform: scale(1) translateX(0);
    -moz-transform: scale(1) translateX(0);
    -ms-transform: scale(1) translateX(0);
    -o-transform: scale(1) translateX(0);
    transform: scale(1) translateX(0); }

  25% {
    -webkit-transform: scale(1.35) translateX(0);
    -moz-transform: scale(1.35) translateX(0);
    -ms-transform: scale(1.35) translateX(0);
    -o-transform: scale(1.35) translateX(0);
    transform: scale(1.35) translateX(0); }

  50% {
    -webkit-transform: scale(1.35) translateX(30px);
    -moz-transform: scale(1.35) translateX(30px);
    -ms-transform: scale(1.35) translateX(30px);
    -o-transform: scale(1.35) translateX(30px);
    transform: scale(1.35) translateX(30px); }

  75% {
    -webkit-transform: scale(1.35) translateX(-30px);
    -moz-transform: scale(1.35) translateX(-30px);
    -ms-transform: scale(1.35) translateX(-30px);
    -o-transform: scale(1.35) translateX(-30px);
    transform: scale(1.35) translateX(-30px); }

  100% {
    -webkit-transform: scale(1) translateX(0);
    -moz-transform: scale(1) translateX(0);
    -ms-transform: scale(1) translateX(0);
    -o-transform: scale(1) translateX(0);
    transform: scale(1) translateX(0); } }

@keyframes prdmove {
  0% {
    -webkit-transform: scale(1) translateX(0);
    -moz-transform: scale(1) translateX(0);
    -ms-transform: scale(1) translateX(0);
    -o-transform: scale(1) translateX(0);
    transform: scale(1) translateX(0); }

  25% {
    -webkit-transform: scale(1.35) translateX(0);
    -moz-transform: scale(1.35) translateX(0);
    -ms-transform: scale(1.35) translateX(0);
    -o-transform: scale(1.35) translateX(0);
    transform: scale(1.35) translateX(0); }

  50% {
    -webkit-transform: scale(1.35) translateX(30px);
    -moz-transform: scale(1.35) translateX(30px);
    -ms-transform: scale(1.35) translateX(30px);
    -o-transform: scale(1.35) translateX(30px);
    transform: scale(1.35) translateX(30px); }

  75% {
    -webkit-transform: scale(1.35) translateX(-30px);
    -moz-transform: scale(1.35) translateX(-30px);
    -ms-transform: scale(1.35) translateX(-30px);
    -o-transform: scale(1.35) translateX(-30px);
    transform: scale(1.35) translateX(-30px); }

  100% {
    -webkit-transform: scale(1) translateX(0);
    -moz-transform: scale(1) translateX(0);
    -ms-transform: scale(1) translateX(0);
    -o-transform: scale(1) translateX(0);
    transform: scale(1) translateX(0); } }

@-webkit-keyframes topright {
  0% {
    opacity: 0;
    -webkit-transform: translate(-7px, 7px);
    -moz-transform: translate(-7px, 7px);
    -ms-transform: translate(-7px, 7px);
    -o-transform: translate(-7px, 7px);
    transform: translate(-7px, 7px); }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0); } }

@keyframes topright {
  0% {
    opacity: 0;
    -webkit-transform: translate(-7px, 7px);
    -moz-transform: translate(-7px, 7px);
    -ms-transform: translate(-7px, 7px);
    -o-transform: translate(-7px, 7px);
    transform: translate(-7px, 7px); }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0); } }

.product-hover-9.products:hover .product-container .product-button {
  -webkit-animation: topright 700ms ease forwards;
  -moz-animation: topright 700ms ease forwards;
  -ms-animation: topright 700ms ease forwards;
  -o-animation: topright 700ms ease forwards;
  animation: topright 700ms ease forwards; }

li .products.product-hover-9 .product-detail form a.add-cart-btn i {
  margin-right: 5px; }

li .products.product-hover-9 .product-detail form a.add-cart-btn {
  margin-top: 5px;
  font-size: 14px;
  width: auto;
  height: auto;
  padding: 9px 18px;
  border-radius: 7px;
  opacity: 1;
  line-height: normal; }

.products.product-hover-9 .product-container .product-button {
  width: auto;
  display: block;
  right: 10px;
  left: auto;
  top: 10px; }

.products.product-hover-9 .product-container .product-button a, .product-hover-9 .product-container .product-button button {
  display: block;
  margin-bottom: 5px; }

.product-hover-9 .product-detail .product-button a, .product-hover-9 .product-container .product-button button {
  border-radius: 50%; }

.product-hover-9.products .product-button .wishlist {
  margin: 0; }

.product-hover-9.products:hover .product-detail .product-button {
  visibility: visible;
  opacity: 1; }

.product-hover-9.products .product-detail .product-button {
  z-index: 10;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; }

.product-hover-9.products .product-detail form {
  float: none; }

.product-hover-9.products:hover {
  background: #fff; }

.product-hover-9.products .product-container:hover .product-button {
  top: 10px; }

li .products.product-hover-9 .product-button .add-to-wishlist .loading {
  top: 0; }

/* Newsletter */
.newsletter-section .border-title {
  float: left;
  width: 40%;
  text-align: left;
  margin: 0; }
  @media screen and (max-width: 767px) {
    .newsletter-section .border-title {
      width: 100%;
      text-align: center; } }

.newsletter-section form {
  float: right;
  width: 58%;
  margin-top: 20px;
  padding-right: 0; }
  @media screen and (max-width: 767px) {
    .newsletter-section form {
      width: 100%;
      text-align: center; } }

.newsletter-section form .subscribe {
  width: auto; }

.newsletter-section .short-desc p {
  width: 100%; }

.newsletter-section .short-desc p::after {
  content: none; }

.newsletter-section.parallax {
  margin: auto;
  text-align: center;
  padding: 0; }

.newsletter-section form .btn, .newsletter-section form .btn--secondary, .newsletter-section form .btn--tertiary, .newsletter-section form input[type="submit"] {
  line-height: 1.7;
  display: inline-block;
  height: 45px;
  font-size: 13px;
  padding: 10px 20px;
  margin-left: 10px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none; }
  @media screen and (max-width: 967px) {
    .newsletter-section form .btn, .newsletter-section form .btn--secondary, .newsletter-section form .btn--tertiary, .newsletter-section form input[type="submit"] {
      margin-left: 0px;
      width: auto; } }
  @media screen and (max-width: 767px) {
    .newsletter-section form .btn, .newsletter-section form .btn--secondary, .newsletter-section form .btn--tertiary, .newsletter-section form input[type="submit"] {
      margin-left: 10px;
      width: auto; } }
  @media screen and (max-width: 567px) {
    .newsletter-section form .btn, .newsletter-section form .btn--secondary, .newsletter-section form .btn--tertiary, .newsletter-section form input[type="submit"] {
      margin-left: 0px;
      width: 100%; } }

.newsletter-section form {
  margin-bottom: 0; }

.newsletter-section form input[type="email"] {
  height: 45px;
  font-size: 13px;
  background: transparent;
  display: inline-block;
  padding: 10px 15px;
  width: 75%;
  border: 1px solid #36342f;
  line-height: 1.7; }
  @media screen and (max-width: 567px) {
    .newsletter-section form input[type="email"] {
      width: 100%; } }

.newsletter-section form input[type="email"]:active, .newsletter-section form input[type="email"]:focus {
  border: 1px solid #36342f; }

/* Tab Hover Effects 1 */
.product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:hover:before, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:hover:after, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:focus:before, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:focus:after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px); }

.product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:hover:before, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:hover:after, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:focus:before, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:focus:after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px); }

.product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:before, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:before {
  margin-right: 10px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px); }

.product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:after, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:after {
  margin-left: 10px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px); }

.product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:before, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li a:after, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:after, .product-tab-type-3 .dt-sc-tabs-container .dt-sc-tabs li:last-child a:before {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s; }

/* Blog-post-type-4 */
.blog-post-type-4 .blog-date {
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 0; }

.blog-post-type-4 .comments-count {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  padding-right: 10px; }

.blog-post-type-4 .comments-count:before {
  content: "";
  float: left;
  width: 1px;
  height: 15px;
  top: 5px;
  position: absolute;
  right: 0; }

.blog-post-type-4 .blog-detail p, .blog-post-type-4 .blog-btn {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 0; }

.blog-post-type-4 .author {
  display: inline-block;
  margin-bottom: 10px; }

.blog-post-type-4 .date i {
  font-style: inherit; }

.blog-post-type-4 .blog-btn {
  text-decoration: underline;
  margin-top: 0px; }

.blog-post-type-4 .blog-btn a span {
  margin-left: 7px; }

.blog-post-type-4 .nav_article {
  text-align: center;
  margin-top: 30px;
  display: inline-block;
  width: 100%; }

.blog-post-type-4 .nav_article a i {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block; }

.blog-post-type-4 .nav_article a.prev {
  margin-right: 7px; }

.blog-post-type-4 .nav_article a {
  padding: 0; }

.blog-post-type-4 .home-blog-content.blog-detail h4, .blog-post-type-4 .home-blog-content.blog-detail .h4 {
  margin-bottom: 7px;
  float: left;
  width: 100%;
  font-size: 20px; }
  @media screen and (max-width: 967px) {
    .blog-post-type-4 .home-blog-content.blog-detail h4, .blog-post-type-4 .home-blog-content.blog-detail .h4 {
      font-size: 18px; } }

.blog-post-type-4 .article {
  padding: 0 15px;
  float: left;
  width: 100%; }
  @media screen and (max-width: 967px) {
    .blog-post-type-4 .article {
      padding: 0 5px; } }
  @media screen and (max-width: 767px) {
    .blog-post-type-4 .article {
      margin-bottom: 20px;
      padding: 0px; } }

.blog-post-type-4 .blog-description {
  padding: 40px;
  text-align: center; }
  @media screen and (max-width: 1199px) {
    .blog-post-type-4 .blog-description {
      padding: 20px; } }

.blog-post-type-4 .blog-description h4, .blog-post-type-4 .blog-description .h4 {
  text-transform: uppercase;
  margin: 0 0 8px; }

.blog-post-type-4 .article img {
  display: block; }

.blog-post-type-4 .owl-prev, .blog-post-type-4 .owl-next {
  float: none;
  display: inline-block; }

.blog-post-type-4 {
  float: left;
  width: 100%; }

.blog-post-type-4 .home-blog.blog-section {
  float: none;
  width: auto;
  margin: 0 -15px; }
  @media screen and (max-width: 767px) {
    .blog-post-type-4 .home-blog.blog-section {
      margin: 0; } }

.blog-post-type-4 .author i {
  padding-right: 5px; }

.blog-post-type-4 .home-blog-image a {
  position: relative;
  display: block; }

.blog-post-type-4 .home-blog-image a::before, .blog-post-type-4 .home-blog-image a::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  top: 0; }

.blog-post-type-4 .home-blog-image a::before {
  left: 0;
  top: 0; }

.blog-post-type-4 .home-blog-image a::after {
  right: 0;
  bottom: 0;
  top: auto; }

.blog-post-type-4 .article:hover .home-blog-image a::before, .blog-post-type-4 .article:hover .home-blog-image a::after {
  height: 0;
  width: 0; }

.flex-prev:before {
  content: "\f2ea";
  font-family: "Material-Design-Iconic-Font";
  float: left; }

.flex-next:before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIuM3B4IiBoZWlnaHQ9IjQwcHgiIHZpZXdCb3g9IjAgMCAyMi4zIDQwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi4zIDQwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNEM0QzRDMiIGQ9Ik0wLjUsMy4xYzIuMSwyLjEsMTYuMSwxNi42LDE2LjEsMTYuNlMyLjYsMzQuOCwwLjUsMzYuOVMzLjYsNDAsMy42LDQwbDE4LjctMjAuM0wzLjYsMEMzLjYsMC0xLjYsMSwwLjUsMy4xDQoJeiIvPg0KPC9zdmc+DQo=");
  background-position: center center;
  background-repeat: no-repeat; }

/* Footer Type 9 */
.footer-model-9 .footer_newsletter {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin: auto;
  padding: 0 30px;
  vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .footer-model-9 .footer_newsletter {
      width: 100%;
      text-align: center;
      margin-bottom: 20px; } }
  @media screen and (max-width: 567px) {
    .footer-model-9 .footer_newsletter {
      padding: 0; } }

.footer-model-9 .footer_newsletter h4, .footer-model-9 .footer_newsletter .h4 {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 0;
  vertical-align: middle; }

.footer-model-9 .input-group .btn, .footer-model-9 .input-group .btn--secondary, .footer-model-9 .input-group .btn--tertiary, .footer-model-9 .input-group input[type="submit"] {
  padding: 0 14px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  margin: 5px; }

.footer-model-9 .input-group input {
  background: transparent;
  border: none; }

.footer-model-9 .input-group {
  float: left;
  width: 100%;
  border-radius: 30px; }

.footer-model-9 .site-footer .grid-uniform .grid__item form {
  border: none;
  float: none;
  display: inline-block;
  padding: 0;
  vertical-align: middle; }

.footer-model-9 .site-footer .grid-uniform .grid__item {
  border: none; }
  @media screen and (max-width: 767px) {
    .footer-model-9 .site-footer .grid-uniform .grid__item {
      padding-bottom: 30px; } }

.footer-model-9 .site-footer .social-icons li a span {
  border: none;
  width: auto;
  height: auto;
  line-height: normal;
  margin-right: 15px;
  margin-top: 15px; }

.footer-model-9 .footer_social_links {
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .footer-model-9 .footer_social_links {
      width: 100%; } }

.footer-model-9 .site-footer .social-icons {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  text-align: left; }

.footer-model-9 .site-footer {
  padding: 60px 0px; }
  @media screen and (max-width: 767px) {
    .footer-model-9 .site-footer {
      padding: 30px 20px; } }

.footer-model-9 .footer-bottom {
  float: left;
  width: 100%;
  margin: 0px 0 0; }

.footer-model-9 .footer-bottom__menu {
  float: left; }
  @media screen and (max-width: 967px) {
    .footer-model-9 .footer-bottom__menu {
      width: 100%;
      margin-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .footer-model-9 .footer-bottom__menu {
      margin-bottom: 20px; } }

.footer-model-9 .site-footer .address p {
  width: 100%; }

.footer-model-9 .site-footer .footer-bottom .footer-bottom__menu.grid__item {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 0px; }

.footer-model-9 .footer-bottom .footer-bottom__menu ul {
  list-style: none;
  padding: 0; }

.footer-model-9 .copyright {
  float: left;
  width: 100%;
  padding: 20px 5px; }
  @media screen and (max-width: 967px) {
    .footer-model-9 .copyright {
      text-align: center; } }

.footer-model-9 .footer-bottom .footer-bottom__menu ul li {
  display: inline-block; }

.footer-model-9 .copyright_left {
  float: right;
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 967px) {
    .footer-model-9 .copyright_left {
      width: 100%;
      margin-bottom: 10px; } }

.footer-model-9 .footer-bottom .footer-bottom__menu ul li:last-child a {
  border-right: none !important; }

.footer-model-9 .footer-bottom .footer-bottom__menu ul li a {
  padding: 0 10px;
  display: inline-block;
  line-height: normal; }
  @media screen and (max-width: 1199px) {
    .footer-model-9 .footer-bottom .footer-bottom__menu ul li a {
      padding: 0 7px; } }

.footer-model-9 .site-footer input {
  height: 45px;
  line-height: 45px; }

@media screen and (max-width: 967px) {
  .footer-model-9 .site-footer input[type="email"] {
    width: 80%; } }

.footer-model-9 .footer-logo {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  vertical-align: middle;
  text-align: left; }
  @media screen and (max-width: 767px) {
    .footer-model-9 .footer-logo {
      width: 100%;
      text-align: left;
      margin-bottom: 20px; } }

.footer-model-9 .site-footer .grid__item {
  padding-right: 40px; }
  @media screen and (max-width: 967px) {
    .footer-model-9 .site-footer .grid__item {
      padding-right: 10px; } }

.footer-model-9 .site-footer .grid__item:last-child {
  padding-right: 0; }

.footer-model-9 .footer-icons .payment-icons li a img {
  width: 30px;
  height: auto; }

.footer-model-9 .payment_section {
  float: left;
  width: 100%;
  margin-top: 20px; }

.footer-model-9 h5, .footer-model-9 .h5 {
  margin-bottom: 10px; }

.footer-model-9 .site-footer p {
  margin-bottom: 20px; }

@media screen and (max-width: 767px) {
  .footer-model-9 .footer-icons .payment-icons {
    text-align: left; } }

@media screen and (max-width: 1199px) {
  .footer-model-9 .inline-list li {
    margin-bottom: 5px; } }

.footer-model-9 .footer-bottom .footer-bottom__menu ul li:first-child a {
  padding-left: 0; }

.site-footer .instagram .lush-instagram .info {
  display: none; }

.site-footer h4, .site-footer .h4 {
  color: #b10a0a;
  letter-spacing: 0; }

.collection_sidebar .widget {
  position: relative; }

.collection_sidebar .widget.widget_top_rated_products ul, .widget_promo_img.widget ul {
  border: none; }

.product-list.products form {
  float: left; }
  @media screen and (max-width: 967px) {
    .product-list.products form {
      width: 100%;
      margin-bottom: 10px; } }

@media screen and (max-width: 967px) {
  .product-list .add-to-wishlist {
    float: left;
    width: 100%; } }

.product-list.products .product-detail a.add-cart-btn, .product-list.products .product-detail .stock-disabled {
  float: left;
  font-size: 16px;
  margin-right: 10px; }
  @media screen and (max-width: 567px) {
    .product-list.products .product-detail a.add-cart-btn, .product-list.products .product-detail .stock-disabled {
      margin-left: 0; } }

.product-list .product-detail {
  text-align: left;
  padding: 15px 20px 0; }

.product-list .product-detail .grid-link__title {
  border-bottom: none;
  text-align: left; }

.product-list .products:hover {
  box-shadow: 0 0 0 0; }

.product-list .product-container {
  border-right: 1px solid #36342f; }
  @media screen and (max-width: 1199px) {
    .product-list .product-container {
      border: none; } }

.product-list .cart-button form {
  left: 55px;
  position: absolute; }

.product-list .product-button {
  float: left;
  width: 100%;
  margin-bottom: 30px; }

.product-list .products .product-button button {
  display: inline-block;
  font-size: 14px;
  height: 45px;
  position: relative;
  width: 45px;
  margin-top: 0px;
  background: #000;
  color: #fff;
  border: none;
  top: 14px; }

.product-list .products .product-button a {
  display: inline-block;
  font-size: 14px;
  height: 45px;
  position: relative;
  width: 45px;
  margin-top: -3px;
  background: #000;
  color: #fff;
  border: none;
  top: 0px; }

.product-list .cart-button {
  margin-bottom: 30px; }

.product-list .product-button {
  text-align: center; }

.product-list .products .product-button {
  bottom: 0;
  display: inline-block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

@media screen and (max-width: 967px) {
  .product-list .products .product-button a, .product-list .products .product-button button {
    width: 34px;
    height: 34px;
    line-height: 34x; } }

.product-list .products .product-button a {
  position: static;
  background-color: #000;
  color: #fff;
  border: none;
  margin-left: 0px; }

.product-list .products:hover .product-button {
  bottom: 0px;
  opacity: 1;
  z-index: 9; }

.products.product-list .spr-badge {
  float: left;
  width: 100%; }

.product-list-view ul li.grid__item.item-row {
  padding: 0; }

.product-list-view .products .product-detail .btn i, .product-list-view .products .product-detail .btn--secondary i, .product-list-view .products .product-detail .btn--tertiary i, .product-list-view .products .product-detail input[type="submit"] i {
  margin-right: 5px; }

@media screen and (max-width: 1199px) {
  .product-list-view .products .product-detail p {
    font-size: 13px; } }

.product-list-view .products .product-detail {
  text-align: left;
  border-top: none;
  background: none;
  padding: 30px 30px; }
  @media screen and (max-width: 1199px) {
    .product-list-view .products .product-detail {
      padding: 20px 30px; } }
  @media screen and (max-width: 967px) {
    .product-list-view .products .product-detail {
      padding: 20px 20px; } }
  @media screen and (max-width: 567px) {
    .product-list-view .products .product-detail {
      padding: 10px; } }

.product-list-view .products .product-detail .grid-link__meta {
  margin: 0;
  padding: 0 0 0px; }

.product-list .product-detail .grid-link__vendor {
  margin: 0 0 5px; }

.product-list-view .products .product-container {
  border: none; }

.product-list-view .products .product-container:hover {
  border: none; }

.product-list-view .products .item-swatch {
  margin-bottom: 20px;
  text-align: left; }

.product-list-view .products .product-detail .grid-link__meta .product_price {
  text-align: left; }

ul.product-list-view li.grid__item.item-row {
  padding: 0;
  border-bottom: 1px solid #36342f;
  border-top: none;
  border-left: none;
  border-right: none; }

.tab-inner {
  float: left;
  width: 100%;
  position: relative; }

.product-list-view .products .product-detail .spr-badge {
  margin-bottom: 10px; }

.tab-inner .products .product-detail .spr-badge, .top_rated_section .products .product-detail .spr-badge, .product-list-view .products .product-detail .spr-badge {
  position: static;
  visibility: visible;
  opacity: 1;
  text-align: left; }

.selector-wrapper .selector-arrow {
  position: relative;
  float: left;
  width: 80%; }
  @media screen and (max-width: 767px) {
    .selector-wrapper .selector-arrow {
      width: 50%; } }
  @media screen and (max-width: 567px) {
    .selector-wrapper .selector-arrow {
      width: 100%; } }

/* products detail section */
.products .product-detail .grid-link__meta .product_price {
  display: inline-block;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.products .product-detail .grid-link__meta {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0;
  padding: 0 0px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 967px) {
    .products .product-detail .grid-link__meta {
      padding: 0; } }

.grid-link__org_price {
  display: inline-block; }

.products form {
  float: right;
  margin: 0; }

.products .product_left {
  float: left;
  width: 100%; }

.products .product-detail .product-vendor {
  float: left;
  width: 100%;
  padding: 0; }

.products .product-detail label {
  float: left; }

.products .product-detail .product-vendor span {
  float: left;
  width: 100%;
  padding: 0;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #bcbcbc; }

.products .product-detail {
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
  padding: 20px 15px 20px;
  z-index: 1;
  border-radius: 0 0 0px 0px;
  background: #ffffff; }
  @media screen and (max-width: 1199px) {
    .products .product-detail {
      padding: 20px 10px 20px; } }
  @media screen and (max-width: 767px) {
    .products .product-detail {
      padding: 20px 12px 20px; } }

.products:hover .product-detail .grid-link__title {
  color: #934d4d; }

@media screen and (max-width: 767px) {
  .products .product-button .button-row-1, .products .product-button .button-row-2 {
    display: inline-block; } }

.products .product-button .add-in-wishlist-js span, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button .add-in-wishlist-js span {
  display: none; }

.products .product-button .loading {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 0;
  background: none; }

.products .product-button .add-to-wishlist {
  display: inline-block; }

.products .product-button .add-to-wishlist a {
  display: block; }

.products .product-button .added-wishlist .tooltip-label {
  display: none; }

.products .spr-badge {
  float: left;
  width: 100%;
  font-size: 12px !important;
  padding: 5px 0 5px 0; }
  @media screen and (max-width: 1199px) {
    .products .spr-badge {
      font-size: 12px !important; } }
  @media screen and (max-width: 967px) {
    .products .spr-badge {
      font-size: 10px !important; } }
  @media screen and (max-width: 767px) {
    .products .spr-badge {
      font-size: 10px !important; } }

.product-detail .spr-badge-starrating {
  margin: 0; }

.product-detail .grid-link__title {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 18px;
  color: #b10a0a;
  display: inline-block;
  width: 100%;
  margin: 0px 0 10px;
  line-height: normal; }
  @media screen and (max-width: 1199px) {
    .product-detail .grid-link__title {
      font-size: 14px; } }

.product-shop.summary .prices {
  font-size: 24px;
  line-height: normal;
  font-weight: bold;
  color: #5a574f;
  margin: 0 0 0px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 1199px) {
    .product-shop.summary .prices {
      font-size: 22px; } }
  @media screen and (max-width: 967px) {
    .product-shop.summary .prices {
      font-size: 18px; } }
  @media screen and (max-width: 767px) {
    .product-shop.summary .prices {
      font-size: 20px; } }

.product-detail .grid-link__meta .grid-link__org_price {
  font-size: 20px;
  line-height: normal;
  font-weight: normal;
  color: #5a574f;
  margin: 0 0 0px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 967px) {
    .product-detail .grid-link__meta .grid-link__org_price {
      font-size: 16px; } }

.product-container .ImageOverlayCa {
  height: 100%;
  left: 0;
  top: -100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

.product-container:hover .ImageOverlayCa {
  top: 0; }

.product_single_detail_section .product_price {
  float: left; }

.product_single_detail_section .grid-link__sale_price {
  float: left;
  margin-top: 7px; }

.product-detail .grid-link__meta del, .quick-view .product-shop.summary .compare-price, #ComparePrice, del {
  display: inline-block;
  margin-left: 5px;
  color: #bcbcbc;
  font-weight: normal;
  font-size: 14px; }
  @media screen and (max-width: 1199px) {
    .product-detail .grid-link__meta del, .quick-view .product-shop.summary .compare-price, #ComparePrice, del {
      font-size: 12px; } }
  @media screen and (max-width: 967px) {
    .product-detail .grid-link__meta del, .quick-view .product-shop.summary .compare-price, #ComparePrice, del {
      font-size: 12px; } }

.product-vendor, .product-type, .product-inventory {
  padding-bottom: 5px;
  margin-bottom: 10px;
  float: left;
  width: 100%; }

.grid-link__image--product {
  margin: 0; }

.footer-logo {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: auto; }

.site-footer i {
  margin-right: 5px;
  margin-top: 5px; }

.site-footer h4, .site-footer .h4 {
  font-size: 20px;
  position: relative;
  margin-bottom: 20px; }
  @media screen and (max-width: 1199px) {
    .site-footer h4, .site-footer .h4 {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .site-footer h4, .site-footer .h4 {
      font-size: 20px; } }

.site-footer ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.site-footer__links a:hover {
  color: #934d4d; }

.site-footer .grid-uniform .grid__item form {
  border-bottom: 1px solid #36342f;
  display: inline-block;
  width: 72%; }
  @media screen and (max-width: 1199px) {
    .site-footer .grid-uniform .grid__item form {
      width: 90%; } }
  @media screen and (max-width: 967px) {
    .site-footer .grid-uniform .grid__item form {
      width: 94%; } }
  @media screen and (max-width: 767px) {
    .site-footer .grid-uniform .grid__item form {
      width: 100%; } }

.site-footer__links a {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 0 7px 0px; }
  @media screen and (max-width: 967px) {
    .site-footer__links a {
      padding-right: 10px; } }
  @media screen and (max-width: 767px) {
    .site-footer__links a {
      padding-right: 0px; } }

.footer-logo p {
  margin: 0;
  padding-left: 30px; }
  @media screen and (max-width: 567px) {
    .footer-logo p {
      padding-left: 0; } }

.footer-logo img {
  margin: 0;
  padding: 0px 0 0; }

footer .grid.footer-icons, footer .footer-icons.grid--rev, footer .footer-icons.grid--full {
  margin: 20px 0 0; }

.footer-icons .payment-icons li a:hover img {
  opacity: 1; }

.footer-icons .payment-icons li a img {
  float: left;
  width: 40px;
  height: 25px; }

.footer-icons .payment-icons li a img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.footer-icons .payment-icons li a {
  display: block; }

.grid.footer-icons, .footer-icons.grid--rev, .footer-icons.grid--full {
  float: left; }
  @media screen and (max-width: 567px) {
    .grid.footer-icons, .footer-icons.grid--rev, .footer-icons.grid--full {
      float: none;
      display: inline-block; } }

.footer-icons h4, .footer-icons .h4 {
  float: left;
  width: 100%;
  padding: 0; }

@media screen and (max-width: 967px) {
  .footer-icons .payment-icons li img {
    width: 35px; } }
@media screen and (max-width: 767px) {
  .footer-icons .payment-icons li img {
    width: 50px; } }
@media screen and (max-width: 567px) {
  .footer-icons .payment-icons li img {
    width: 43px; } }

.footer-icons .payment-icons {
  float: left;
  padding: 0px 0 0px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .footer-icons .payment-icons {
      padding: 0;
      float: none;
      display: inline-block;
      text-align: center;
      width: 100%;
      margin: auto; } }

.copyright-section .footer-icons {
  float: right; }
  @media screen and (max-width: 767px) {
    .copyright-section .footer-icons {
      float: none; } }

.copyright-section {
  float: left;
  width: 100%;
  padding: 20px 4%; }
  @media screen and (max-width: 1599px) {
    .copyright-section {
      padding: 20px 2%; } }
  @media screen and (max-width: 967px) {
    .copyright-section {
      padding: 20px 1%; } }

.copyright, .copyright a {
  margin: 0; }
  @media screen and (max-width: 767px) {
    .copyright, .copyright a {
      text-align: center; } }

.copyright {
  padding-top: 8px; }

.site-footer .address {
  line-height: 26px;
  float: left;
  width: 100%; }

.site-footer .address p {
  display: inline-block;
  margin-bottom: 5px; }

.site-footer .footer_contact p {
  display: inline-block;
  margin-bottom: 5px; }

.site-footer input {
  float: left;
  padding: 0 0 0 17px;
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: none; }
  @media screen and (max-width: 1199px) {
    .site-footer input {
      width: 70%; } }
  @media screen and (max-width: 767px) {
    .site-footer input {
      width: 80%; } }

.site-footer .btn, .site-footer .btn--secondary, .site-footer .btn--tertiary, .site-footer input[type="submit"] {
  border: none;
  float: right;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0; }

.site-footer .btn i, .site-footer .btn--secondary i, .site-footer .btn--tertiary i, .site-footer input[type="submit"] i {
  margin: 0; }

.site-footer .btn:before, .site-footer .btn--secondary:before, .site-footer .btn--tertiary:before, .site-footer input[type="submit"]:before, #mc_embed_signup .btn:before, #mc_embed_signup .btn--secondary:before, #mc_embed_signup .btn--tertiary:before, #mc_embed_signup input[type="submit"]:before, .sorting-section .btn:before, .sorting-section .btn--secondary:before, .sorting-section .btn--tertiary:before, .sorting-section input[type="submit"]:before {
  content: none;
  border: none; }

.site-footer .btn:hover, .site-footer .btn--secondary:hover, .site-footer .btn--tertiary:hover, .site-footer input[type="submit"]:hover {
  background: none;
  color: #934d4d; }

.badge.badge--sale .badge__text, .badge.badge--sale {
  background: #11b00d; }

.product-container:hover .product-button {
  top: 50%; }

.product-container .product-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: -50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 2; }

.product-container .product-button a:hover, .product-container .product-button button:hover, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button a:hover, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button button:hover {
  background: #934d4d;
  color: #ffffff; }

.product-container .product-button a, .product-container .product-button button, .product-container .product-button span, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button a, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button button, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button span {
  background: #b10a0a;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 1599px) {
    .product-container .product-button a, .product-container .product-button button, .product-container .product-button span, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button a, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button button, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button span {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      margin-right: 0px; } }
  @media screen and (max-width: 767px) {
    .product-container .product-button a, .product-container .product-button button, .product-container .product-button span, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button a, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button button, .product-tab-type-3 .dt-sc-tabs-content .one-whole .product-button span {
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      margin-right: 0px; } }

.products .ImageWrapper {
  float: left;
  width: 100%;
  overflow: hidden; }

.products .product-button button {
  background: none;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 0; }

.products .product-button .wishlist {
  margin: 0; }

.product-container .badge.badge--sale .badge__text {
  webkit-transition-delay: 0s, 0s, 0s;
  -moz-transition-delay: 0s, 0s, 0s;
  -ms-transition-delay: 0s, 0s, 0s;
  -o-transition-delay: 0s, 0s, 0s;
  transition-delay: 0s, 0s, 0s;
  -webkit-transition-duration: 0.3s, 0.25s, 0.25s;
  -moz-transition-duration: 0.3s, 0.25s, 0.25s;
  -ms-transition-duration: 0.3s, 0.25s, 0.25s;
  -o-transition-duration: 0.3s, 0.25s, 0.25s;
  transition-duration: 0.3s, 0.25s, 0.25s;
  -webkit-transition-property: opacity, padding, width;
  -moz-transition-property: opacity, padding, width;
  -ms-transition-property: opacity, padding, width;
  -o-transition-property: opacity, padding, width;
  transition-property: opacity, padding, width;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -ms-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }

.product-container .badge.badge--sale i {
  position: absolute;
  right: 12px; }

.product-container .badge.badge--sale .badge__text {
  float: left;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: px; }

.product-container .product-button a, .product-container .product-button button, .product-container .product-button span {
  border-radius: 50%; }

.product-container .product-button span {
  cursor: no-drop; }

.products .product-container {
  border-radius: 0px 0px 0 0;
  text-align: -webkit-center;
  background-color: white;
  position: relative;
  overflow: hidden;
  line-height: 0; }
  @media screen and (max-width: 767px) {
    .products .product-container {
      width: 100%; } }

.products .product-container a.grid__image {
  float: left; }

.products .product-container label.deal-lable {
  display: none; }

.product-container img {
  margin: auto;
  display: block; }

.products:hover .hover {
  opacity: 1; }

@media screen and (max-width: 767px) {
  .products .product-container .product-button a.quick-view-text, .products .product-container .product-button a.compare {
    display: none; } }

.product-button form {
  display: inline-block;
  margin: 0;
  width: initial;
  padding: 0;
  float: none; }

.product-list .product-button form {
  float: none; }

.product-list .product-button a {
  display: inline-block; }

.product-list .product-button span {
  position: inherit; }

.product_tab_section {
  background: #ebebeb;
  border-left: none;
  border-right: none;
  padding: 30px 0; }

.tab_content_section {
  float: left;
  width: 100%;
  padding: 50px 0;
  background: #f4f4f4; }

.dt-sc-tabs-container .dt-sc-tabs {
  display: inline-block;
  text-align: center;
  width: 100%; }

.dt-sc-tabs-container .dt-sc-tabs li {
  float: none;
  display: inline-block; }

.template-product .dt-sc-tabs-container .dt-sc-tabs li {
  float: left; }

.template-product .dt-sc-tabs-container {
  float: left;
  width: 100%; }

.template-product .dt-sc-tabs-container ol, .template-collection .product-detail ol {
  margin-bottom: 0; }

blockquote {
  position: relative; }

blockquote:before {
  content: "\"";
  font-size: 70px;
  left: -28px;
  position: absolute;
  top: -28px;
  font-family: Maven Pro;
  width: 70px;
  text-align: center; }

.product-container .product-button a:last-child {
  margin-right: 0; }

/* Zoom */
.no-js #loader {
  display: none; }

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0; }

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/preloader.gif?v=9834303884704603244) center no-repeat white; }

.product-img-box .product-photo-container {
  position: relative;
  overflow: hidden;
  text-align: center; }

.product-img-box .p-video {
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  float: left;
  width: 100%;
  letter-spacing: 1px; }

.zoomWrapper {
  overflow: hidden;
  height: auto !important;
  width: auto !important;
  text-align: center; }

.product-photo-container img {
  height: auto !important;
  width: auto !important;
  position: static !important; }

.zoomWrapper > div {
  width: 64px !important;
  height: 64px !important;
  background-color: white !important;
  left: 50% !important;
  margin: -32px 0 0 -32px !important;
  top: 50% !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 99 !important; }

.zoomWrapper img {
  z-index: -1 !important; }

.zoomContainer {
  z-index: 99;
  width: auto !important;
  height: auto !important; }

.zoomWrapper {
  overflow: hidden;
  height: auto !important;
  width: auto !important;
  text-align: center; }

.zoomWrapper img:nth-child(2) {
  display: none; }

.product-photo-container #product-featured-image {
  position: static !important;
  height: auto !important;
  width: auto !important; }

.product-photo-container > a {
  position: relative;
  display: block;
  width: 100%; }

/*----*****---- << 8.Go To Top >> ----*****----*/
#toTopHover {
  display: block;
  float: left;
  opacity: 0;
  -moz-opacity: 0; }

#toTop:hover {
  background: #934d4d;
  color: #fff; }

#toTop:active, #toTop:focus {
  outline: none; }

#toTop {
  position: fixed;
  bottom: 86px;
  right: 15px;
  z-index: 99; }
  @media screen and (max-width: 767px) {
    #toTop {
      bottom: 40px; } }

#toTop {
  float: left;
  background: #934d4d;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff; }

#toTop {
  float: left;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase; }
  @media screen and (max-width: 567px) {
    #toTop {
      font-size: 14px; } }

#toTop i {
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  font-style: inherit;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  line-height: 50px;
  margin: auto;
  text-align: center;
  font-size: 26px; }

#slidedown-cart h3, #slidedown-cart .h3 {
  background: #000;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
  padding: 15px 20px;
  margin-bottom: 0px; }

#slidedown-cart .actions .btn, #slidedown-cart .actions .btn--secondary, #slidedown-cart .actions .btn--tertiary, #slidedown-cart .actions input[type="submit"] {
  margin-bottom: 0;
  font-size: 13px;
  padding: 12px 20px;
  border: none; }
  @media screen and (max-width: 567px) {
    #slidedown-cart .actions .btn, #slidedown-cart .actions .btn--secondary, #slidedown-cart .actions .btn--tertiary, #slidedown-cart .actions input[type="submit"] {
      font-size: 12px;
      padding: 12px 14px;
      margin-top: 5px; } }

#slidedown-cart .actions .btn i, #slidedown-cart .actions .btn--secondary i, #slidedown-cart .actions .btn--tertiary i, #slidedown-cart .actions input[type="submit"] i {
  margin-right: 12px; }

#slidedown-cart .actions {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 0 0 24px 0; }

#slidedown-cart li a.product-image {
  line-height: normal;
  float: left;
  display: block;
  margin-right: 15px;
  margin-left: 0px;
  padding: 0 !important;
  width: 70px;
  height: auto; }
  @media screen and (max-width: 567px) {
    #slidedown-cart li a.product-image {
      width: 50px;
      height: auto; } }

#slidedown-cart .text-cart {
  margin-left: 5px; }
  @media screen and (max-width: 567px) {
    #slidedown-cart .text-cart {
      margin-left: 0; } }

#slidedown-cart .product-details {
  float: left;
  width: 55%; }

#slidedown-cart .btn-remove {
  position: absolute;
  right: 15px;
  top: 7px;
  padding: 0 !important; }

#slidedown-cart li .product-name {
  margin: 0; }

#slidedown-cart li .product-name a {
  line-height: 1.7;
  padding: 0;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    #slidedown-cart li .product-name a {
      font-size: 14px; } }

#slidedown-cart ul {
  float: left;
  list-style: none;
  width: 100%;
  padding: 10px 0 0;
  overflow-y: auto;
  max-height: 394px; }
  @media screen and (max-width: 567px) {
    #slidedown-cart ul {
      padding: 10px 10px 10px; } }

#slidedown-cart li {
  padding: 5px 15px 10px 15px !important;
  margin-bottom: 10px;
  float: left;
  border-radius: 0;
  display: inline-block;
  width: 100%;
  position: relative; }

#slidedown-cart ul li .cart-collateral {
  display: inline-block;
  text-align: left;
  width: 100%;
  list-style: none; }
  @media screen and (max-width: 567px) {
    #slidedown-cart ul li .cart-collateral {
      font-size: 11px; } }

#slidedown-cart p {
  margin: 0; }

#slidedown-cart p.text-continue a {
  line-height: normal;
  font-size: 14px;
  margin-top: 15px;
  padding: 10px 28px;
  float: none; }

#slidedown-cart .total .label {
  text-transform: uppercase; }

#slidedown-cart .total {
  display: inline-block;
  margin-left: 0px;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    #slidedown-cart .total {
      font-size: 16px; } }

#slidedown-cart .total .price {
  color: #934d4d;
  margin-left: 15px; }

#slidedown-modal:before {
  border-bottom: 9px solid #36342f;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  height: 0;
  right: 0px;
  position: absolute;
  top: -9px;
  width: 0; }

#slidedown-modal:after {
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  height: 0;
  right: 1px;
  position: absolute;
  top: -8px;
  width: 0; }

#slidedown-cart .actions {
  display: inline-block;
  text-align: center;
  width: 100%; }

#slidedown-cart .no-items {
  padding: 15px 25px;
  float: left;
  width: 100%;
  text-align: center; }

#slidedown-cart p.text-continue a {
  display: none; }

@media screen and (max-width: 967px) {
  .more-view-wrapper-owlslider {
    margin-bottom: 30px; } }

/* product */
.product_single_detail_section .product-single__quantity {
  float: left;
  width: 100%;
  margin-bottom: 0px; }

.product_single_detail_section .product-single__quantity input#quantity {
  border-top: 1px solid #36342f;
  border-bottom: 1px solid #36342f;
  width: 50px;
  font-size: 20px; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section .product-single__quantity input#quantity {
      width: 40px; } }

.product_single_detail_section .total-price {
  float: left;
  width: 100%;
  margin-top: 10px; }

.product_single_detail_section .product-single__quantity .dec, .product_single_detail_section .product-single__quantity .inc {
  cursor: pointer;
  border: 1px solid #36342f;
  font-size: 24px;
  font-weight: normal; }

.product_single_detail_section .product-single__quantity .dec, .product_single_detail_section .product-single__quantity input, .product_single_detail_section .product-single__quantity .inc {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin: auto;
  padding: 0; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section .product-single__quantity .dec, .product_single_detail_section .product-single__quantity input, .product_single_detail_section .product-single__quantity .inc {
      width: 35px;
      height: 35px;
      line-height: 32px; } }

.product-single__hero h1, .product-single__hero .h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #b10a0a;
  margin-bottom: 5px; }
  @media screen and (max-width: 967px) {
    .product-single__hero h1, .product-single__hero .h1 {
      font-size: 24px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    .product-single__hero h1, .product-single__hero .h1 {
      font-size: 22px;
      margin-bottom: 5px; } }

.product-single__hero .social-sharing {
  float: right; }

.product_single_detail_section .product-infor {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  text-align: left; }

.product-single__hero .product-infor label {
  float: left;
  margin-right: 7px; }

.product-single__hero .contact-form {
  margin-bottom: 0; }

.product_single_detail_section .product-single-button {
  float: left;
  width: 100%;
  margin-top: 30px; }

.product_single_detail_section .share_this_btn {
  float: left;
  width: 100%;
  margin-top: 20px; }

.product_single_detail_section .add-to-wishlist .btn i, .product_single_detail_section .add-to-wishlist .btn--secondary i, .product_single_detail_section .add-to-wishlist .btn--tertiary i, .product_single_detail_section .add-to-wishlist input[type="submit"] i {
  margin-right: 5px; }

.product_single_detail_section .add-to-wishlist .btn, .product_single_detail_section .add-to-wishlist .btn--secondary, .product_single_detail_section .add-to-wishlist .btn--tertiary, .product_single_detail_section .add-to-wishlist input[type="submit"] {
  float: left;
  margin-left: 10px;
  letter-spacing: 0.06em; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section .add-to-wishlist .btn, .product_single_detail_section .add-to-wishlist .btn--secondary, .product_single_detail_section .add-to-wishlist .btn--tertiary, .product_single_detail_section .add-to-wishlist input[type="submit"] {
      margin-left: 0;
      margin-top: 10px; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .add-to-wishlist .btn, .product_single_detail_section .add-to-wishlist .btn--secondary, .product_single_detail_section .add-to-wishlist .btn--tertiary, .product_single_detail_section .add-to-wishlist input[type="submit"] {
      margin-left: 10px;
      margin-top: 0px; } }
  @media screen and (max-width: 567px) {
    .product_single_detail_section .add-to-wishlist .btn, .product_single_detail_section .add-to-wishlist .btn--secondary, .product_single_detail_section .add-to-wishlist .btn--tertiary, .product_single_detail_section .add-to-wishlist input[type="submit"] {
      margin-left: 0;
      margin-top: 10px; } }

.product_single_detail_section .add-to-wishlist {
  float: left; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section .add-to-wishlist {
      clear: both;
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .add-to-wishlist {
      clear: inherit;
      width: auto; } }
  @media screen and (max-width: 567px) {
    .product_single_detail_section .add-to-wishlist {
      clear: both;
      width: 100%; } }

.product_single_detail_section label.deal-lable {
  float: left;
  text-transform: uppercase;
  width: 100%;
  margin: 0px 0 5px;
  text-align: left; }

.product_single_detail_section .spr-badge {
  float: left;
  width: 100%;
  margin-bottom: 0px;
  padding: 0; }

.product_single_detail_section {
  display: inline-block;
  text-align: left;
  margin: 0 0 0px 0;
  padding: 0px 0 0px 0px; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section {
      padding-left: 10px; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section {
      margin: 0px;
      padding: 0px; } }

.product_single_detail_section h2, .product_single_detail_section .h2 {
  float: left;
  width: 100%;
  font-weight: normal;
  text-transform: inherit;
  margin: 0 0 10px;
  font-size: 34px; }
  @media screen and (max-width: 967px) {
    .product_single_detail_section h2, .product_single_detail_section .h2 {
      font-size: 24px;
      letter-spacing: 0; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section h2, .product_single_detail_section .h2 {
      width: 100%; } }

.product_single_detail_section .product-arrows {
  float: right;
  margin: 0;
  display: none; }

.product_single_detail_section .product-arrows span.left {
  margin-right: 10px; }

.product_single_detail_section .product-arrows span {
  font-size: 28px; }

.product_single_detail_section .product-arrows span a {
  color: #ffffff; }

.product_single_detail_section .product-description {
  float: left;
  width: 100%;
  margin-bottom: 0px;
  margin-bottom: 20px;
  padding: 0px 0 20px; }

.product_single_detail_section .social-sharing {
  margin: 0px 0 0; }
  @media screen and (max-width: 1199px) {
    .product_single_detail_section .social-sharing {
      margin: 20px 0 0; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .social-sharing {
      margin: 0px 0 0; } }

.product_single_detail_section .social-sharing label {
  display: none; }

.product_single_detail_section .selector-wrapper-secton {
  float: left;
  width: 100%;
  margin: 0px 0;
  text-align: left; }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .selector-wrapper-secton {
      width: 100%; } }

.product_single_detail_section .selector-wrapper-secton select {
  background: none;
  padding: 10px 10px 10px 0px;
  width: 100%;
  border-bottom: 1px solid #36342f;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #dcdcdc; }

.product_single_detail_section .selector-wrapper-secton .selector-arrow:after, .product-single__quantity .quantity-box:after {
  content: "\e604";
  font-size: 11px;
  bottom: 12px;
  font-family: 'simple-line-icons';
  float: left;
  position: absolute;
  right: 0;
  bottom: 10px;
  pointer-events: none; }

.product_single_detail_section .selector-wrapper-secton .selector-wrapper {
  float: left;
  width: 50%;
  margin-bottom: 15px; }
  @media screen and (max-width: 567px) {
    .product_single_detail_section .selector-wrapper-secton .selector-wrapper {
      width: 100%; } }

.product_single_detail_section .selector-wrapper-secton .selector-wrapper label {
  margin-top: 5px; }

.product_single_detail_section .total-price, .product_single_detail_section .grid-link__org_price, .price_varies {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #934d4d; }

.product_single_detail_section .selector-wrapper-secton .selector-wrapper label, .prices.product_price label {
  color: #b10a0a;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  float: left;
  width: 150px; }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .selector-wrapper-secton .selector-wrapper label, .prices.product_price label {
      width: 150px; } }
  @media screen and (max-width: 567px) {
    .product_single_detail_section .selector-wrapper-secton .selector-wrapper label, .prices.product_price label {
      width: 110px; } }

.product-infor label, .product-single .price_range label {
  color: #b10a0a;
  text-align: left;
  font-size: 16px;
  margin-bottom: 0px;
  float: left;
  width: 40%; }
  @media screen and (max-width: 1599px) {
    .product-infor label, .product-single .price_range label {
      width: 40%; } }
  @media screen and (max-width: 567px) {
    .product-infor label, .product-single .price_range label {
      width: 40%; } }

.product_single_price label {
  line-height: 35px;
  color: #b10a0a;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  float: left;
  width: 30%; }
  @media screen and (max-width: 967px) {
    .product_single_price label {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .product_single_price label {
      width: 40%; } }
  @media screen and (max-width: 567px) {
    .product_single_price label {
      width: 100%; } }

.product-single__quantity label {
  color: #b10a0a;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  float: left;
  width: 30%; }
  @media screen and (max-width: 767px) {
    .product-single__quantity label {
      margin-bottom: 15px;
      width: 40%; } }
  @media screen and (max-width: 567px) {
    .product-single__quantity label {
      margin-bottom: 15px; } }

.product_single_detail_section .swatch .header {
  line-height: 35px;
  color: #b10a0a;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  float: left;
  width: 30%; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section .swatch .header {
      width: 40%; } }
  @media screen and (max-width: 967px) {
    .product_single_detail_section .swatch .header {
      width: 100%;
      margin-top: 0;
      margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .swatch .header {
      width: 40%; } }

.product-single__quantity label {
  color: #b10a0a;
  text-align: left;
  margin: 0px;
  float: left;
  width: 30%; }
  @media screen and (max-width: 1599px) {
    .product-single__quantity label {
      width: 40%; } }
  @media screen and (max-width: 567px) {
    .product-single__quantity label {
      width: 40%; } }

.product-single__quantity .quantity-box {
  float: left;
  margin-right: 15px;
  margin-bottom: 20px;
  position: relative; }

.product-single__quantity.is-hidden {
  display: none; }
.product-single__quantity .quantity-selector {
  border-radius: 0;
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 50px;
  line-height: 50px;
  background: #f5f5f5;
  float: left;
  margin: 0px 0;
  padding: 0; }

.quick-view a div {
  display: none !important; }

.quick-view .product-shop.summary .details form {
  float: left;
  width: 100%;
  margin: 0; }

.quick-view .product-shop.summary .details .selector-wrapper {
  display: none;
  margin-bottom: 20px;
  float: left;
  width: 46%;
  margin-right: 4%;
  position: relative; }
  @media screen and (max-width: 767px) {
    .quick-view .product-shop.summary .details .selector-wrapper {
      margin-bottom: 10px; } }

.quick-view .quantity {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: 1px solid #36342f;
  border-bottom: 1px solid #36342f;
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0; }

.quick-view .product-inventory label, .quick-view .prices.product_price label {
  float: left;
  width: 150px;
  margin-right: 5px; }
  @media screen and (max-width: 567px) {
    .quick-view .product-inventory label, .quick-view .prices.product_price label {
      width: 110px; } }

.quick-view .product-shop.summary .compare-price {
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 13px;
  float: left; }

.quick-view .product-shop.summary {
  padding-left: 30px; }
  @media screen and (max-width: 967px) {
    .quick-view .product-shop.summary {
      padding-left: 0; } }

.quick-view .product-shop.summary .total-price {
  float: left;
  width: 100%;
  margin-top: 20px; }

.product-single__quantity input {
  border: none; }

.quantity_width {
  width: 60%;
  float: left; }

.quantity_width .min-qty-alert {
  margin-top: 5px;
  float: left;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.4; }

.quantity_width .min-qty-alert, .product_single_detail_section label.deal-lable {
  color: #d02e2e; }

.product-single__quantity label {
  color: #b10a0a;
  font-size: 14px;
  margin-top: 0;
  float: left;
  line-height: 38px; }
  @media screen and (max-width: 1599px) {
    .product-single__quantity label {
      line-height: 32px; } }

.product-single__quantity .qty-buttons {
  float: left; }

.product-single__quantity .qtyplus:hover, .product-single__quantity .qtyminus:hover {
  opacity: 0.7; }

.quick-view .qtyplus, .quick-view .qtyminus {
  border: 1px solid #36342f;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  float: left;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  appearance: none;
  -webkit-appearance: none; }

.product-single__quantity .qtyplus, .product-single__quantity .qtyminus {
  font-size: 20px;
  border: 1px solid #36342f;
  width: 40px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  display: block;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  appearance: none;
  -webkit-appearance: none; }

.product_single_detail_section #AddToCart {
  float: left; }

.product_single_detail_section #AddToCart.disabled i {
  display: none; }

.product_single_detail_section #AddToCart i {
  margin-right: 5px; }

.product_single_detail_section .product_single_buttons .btn, .product_single_detail_section .product_single_buttons .btn--secondary, .product_single_detail_section .product_single_buttons .btn--tertiary, .product_single_detail_section .product_single_buttons input[type="submit"] {
  display: inline-block;
  margin: 0 0 0px; }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .product_single_buttons .btn, .product_single_detail_section .product_single_buttons .btn--secondary, .product_single_detail_section .product_single_buttons .btn--tertiary, .product_single_detail_section .product_single_buttons input[type="submit"] {
      margin-bottom: 20px; } }

.product_single_detail_section .product_single_buttons .wishlist.btn, .product_single_detail_section .product_single_buttons .wishlist.btn--secondary, .product_single_detail_section .product_single_buttons .wishlist.btn--tertiary, .product_single_detail_section .product_single_buttons input.wishlist[type="submit"] {
  padding: 14px; }

.product_single_detail_section .product_single_buttons {
  width: 100%;
  margin: 0px 0 0; }
  @media screen and (max-width: 567px) {
    .product_single_detail_section .product_single_buttons {
      text-align: left; } }

.product_single_detail_section .selector-wrapper-secton .product-single__quantity.selector-wrapper {
  float: left;
  width: 100%;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .selector-wrapper-secton .product-single__quantity.selector-wrapper {
      width: 100%; } }

.product-single .product-description {
  float: left;
  width: 100%; }

.product-single .wishlist, .product-single button.wishlist {
  float: left;
  margin: 0px 0 20px 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 967px) {
    .product-single .wishlist, .product-single button.wishlist {
      margin-left: 5px; } }
  @media screen and (max-width: 767px) {
    .product-single .wishlist, .product-single button.wishlist {
      margin: 0px 0 20px 10px; } }

.product-single .wishlist i, .product-single button.wishlist i {
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #36342f; }

.breadcrumb {
  color: gainsboro;
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 30px 10px;
  margin: 0; }
  .breadcrumb a,
  .breadcrumb span {
    display: inline;
    padding-right: 4px;
    margin-right: 4px;
    font-size: 14px;
    color: #b10a0a; }
    .breadcrumb a:first-child,
    .breadcrumb span:first-child {
      padding-left: 0; }

.breadcrumb h1, .breadcrumb .h1 {
  color: #b10a0a; }

.breadcrumb a:hover {
  color: #181616; }

.breadcrumb {
  margin: 0; }

.product-single h4, .product-single .h4 {
  font-weight: bold;
  color: #b10a0a;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 13px; }

.product-single .gallery {
  list-style: none; }

.product-single .wishlist span {
  font-size: 16px;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.product-single .share-facebook {
  border-radius: 0;
  background: none;
  padding: 0px;
  font-size: 16px; }
  @media screen and (max-width: 567px) {
    .product-single .share-facebook {
      font-size: 11px; } }

.product-single .share-twitter {
  border-radius: 0;
  background: none;
  padding: 0px;
  font-size: 16px; }
  @media screen and (max-width: 567px) {
    .product-single .share-twitter {
      font-size: 11px; } }

.product-single .share-pinterest {
  border-radius: 0;
  background: none;
  padding: 0px;
  font-size: 16px; }
  @media screen and (max-width: 567px) {
    .product-single .share-pinterest {
      font-size: 11px; } }

.product-single .share-fancy {
  border-radius: 0;
  background: none;
  padding: 0px;
  font-size: 16px; }
  @media screen and (max-width: 567px) {
    .product-single .share-fancy {
      font-size: 11px; } }

.product-single .share-google {
  border-radius: 0;
  background: none;
  padding: 0px;
  font-size: 16px; }
  @media screen and (max-width: 567px) {
    .product-single .share-google {
      font-size: 11px; } }

.product-single .social-sharing a {
  float: left;
  margin: 0; }

.product-single .social-sharing a.share-facebook:hover {
  background: #35538f;
  color: #fff; }

.product-single .social-sharing a.share-twitter:hover {
  background: #34b3d3;
  color: #fff; }

.product-single .social-sharing a.share-pinterest:hover {
  background: #b70400;
  color: #fff; }

.product-single .social-sharing a.share-fancy:hover {
  background: #d53e2b;
  color: #fff; }

.product-single .social-sharing a.share-google:hover {
  background: #d53e2b;
  color: #fff; }

/* Collection */
.collection-list ul.list-method .products:hover .product-detail .spr-badge {
  text-align: left; }

.collection-list .selection-box, .selection-box.custom {
  position: relative;
  float: left; }

.collection-list .selection-box {
  min-width: 185px; }
  @media screen and (max-width: 967px) {
    .collection-list .selection-box {
      min-width: 170px; } }
  @media screen and (max-width: 767px) {
    .collection-list .selection-box {
      min-width: 180px; } }

.collection-list .selection-box:before {
  z-index: 1;
  right: 12px;
  top: 3px;
  bottom: 0px;
  height: 0px;
  margin: auto;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff; }

.selection-box.custom {
  width: 100%; }

.selection-box.custom:before {
  z-index: 1;
  right: 16px;
  top: 0px;
  bottom: 0px;
  height: 30px;
  margin: auto;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff; }

.collection-list .selection-box:before, .selection-box:after, .selection-box.custom:before, .selection-box.custom:after {
  content: "";
  pointer-events: none;
  position: absolute; }

.collection-list .selection-box:after {
  width: 37px;
  height: 37px;
  right: 0px;
  top: 0px;
  border-radius: 0 5px 5px 0; }
  @media screen and (max-width: 967px) {
    .collection-list .selection-box:after {
      width: 35px;
      height: 35px; } }

.selection-box.custom:after {
  width: 51px;
  height: 51px;
  right: 0;
  top: 0; }

.collection-list .selection-box:after, .selection-box.custom:after {
  background: #934d4d; }

.collection-list select {
  width: 100%;
  height: 37px;
  color: gainsboro; }
  @media screen and (max-width: 967px) {
    .collection-list select {
      font-size: 11px;
      height: 35px; } }
  @media screen and (max-width: 567px) {
    .collection-list select {
      font-size: 12px; } }

.collection-list ul.products-grid-view li {
  padding: 10px 10px; }

.per-page-section ul {
  list-style: none;
  margin: 0px;
  padding: 0; }

.widget ul {
  float: left;
  width: 100%;
  margin: 0 0 50px;
  list-style: none;
  padding: 0; }

.widget .widget_product_categories h4, .widget .widget_product_categories .h4 {
  margin-bottom: 20px; }

.widget h4, .widget .h4 {
  float: left;
  width: 100%;
  border-radius: 30px;
  border-left: 5px solid #934d4d;
  font-size: 14px;
  position: relative;
  background: #dcdcdc;
  color: #fff;
  padding: 15px 15px 15px 20px; }
  @media screen and (max-width: 967px) {
    .widget h4, .widget .h4 {
      font-size: 14px; } }

.collection-list .products-grid-view ul {
  list-style: none;
  padding: 0;
  margin: 0 -10px; }
  @media screen and (max-width: 767px) {
    .collection-list .products-grid-view ul {
      margin: 0; } }

.collection-list ul {
  list-style: none;
  padding: 0; }

.collection-list .products-grid-view ul.item-swatch {
  margin: 10px 0 0; }

.collection-list .product-list.products ul {
  text-align: left; }

.listview.active, .gridview.active {
  color: #934d4d; }

.gridview, .listview {
  float: left;
  color: #ffffff; }

.collection-list ul.list-method .grid__item.item-row {
  float: left;
  width: 100%; }

.collection-list ul.list-method .products .product-detail {
  float: left;
  width: 70%; }

.collection-list ul.list-method .products .product-container {
  float: left;
  width: 30%; }

.collection-list ul.list-method .products .product-detail {
  text-align: left; }

.collection-list ul.list-method .products .product-detail .grid-link__title {
  margin-bottom: 10px; }

.collection-list ul.list-method .products .product-detail {
  padding: 30px 20px; }

.collection-list ul.list-method .products .product-detail .grid-link__meta {
  padding: 0; }

.collection-list ul.list-method .products .product-detail .item-swatch {
  text-align: left; }

.collection-list ul.grid-method .products .product-detail p {
  display: none; }

.collection-list {
  float: left;
  width: 100%; }

.collection-list .form-horizontal {
  float: left;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .collection-list .form-horizontal {
      float: none; } }

.collection-list select {
  text-align: left;
  padding: 8px 0px 8px 10px;
  border-radius: 3px; }
  @media screen and (max-width: 967px) {
    .collection-list select {
      width: 70%;
      padding: 8px 0 8px 5px; } }

.collection-list .form-horizontal > label {
  margin-right: 10px;
  margin-top: 7px;
  float: left; }
  @media screen and (max-width: 1199px) {
    .collection-list .form-horizontal > label {
      margin-top: 5px; } }
  @media screen and (max-width: 967px) {
    .collection-list .form-horizontal > label {
      margin-top: 7px; } }

.collection-list h1, .collection-list .h1 {
  float: left;
  width: 100%;
  text-align: left; }

.collection-list .collection-view {
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .collection-list .collection-view {
      display: none; } }

.collection-list .section-header__link--right {
  float: right;
  width: auto; }

.collection-list .section-header__link--right .form-horizontal {
  float: left;
  margin-right: 20px; }

.collection-list .section-header__link--right .form-horizontal label {
  font-size: 13px; }

.collection-list .per-page-section {
  float: right; }
  @media screen and (max-width: 767px) {
    .collection-list .per-page-section {
      display: none; } }

.collection-list .per-page-section li {
  float: left; }

.collection-list .per-page-section li a:hover {
  color: #934d4d; }

.collection-list .per-page-section li.active a {
  color: #934d4d; }

.collection-list .per-page-section li a {
  float: left;
  padding: 7px 10px;
  border-right: 1px solid #36342f; }
  @media screen and (max-width: 1199px) {
    .collection-list .per-page-section li a {
      padding: 7px 7px;
      font-size: 12px; } }
  @media screen and (max-width: 767px) {
    .collection-list .per-page-section li a {
      padding: 5px 8px;
      font-size: 12px; } }

.collection-list .per-page-section li:last-child a {
  border-right: none; }

.collection-list .per-page-section p {
  float: left;
  margin-right: 10px;
  font-size: 13px;
  margin-top: 7px; }
  @media screen and (max-width: 967px) {
    .collection-list .per-page-section p {
      margin-right: 5px;
      font-size: 12px; } }
  @media screen and (max-width: 767px) {
    .collection-list .per-page-section p {
      margin: 0; } }
  @media screen and (max-width: 567px) {
    .collection-list .per-page-section p {
      float: none; } }

.collection-list .per-page-section ul {
  float: left;
  border: 1px solid #36342f;
  border-radius: 3px; }
  @media screen and (max-width: 567px) {
    .collection-list .per-page-section ul {
      float: none;
      display: inline-block; } }

.collection-list .product-list a img {
  margin: auto; }

.collection-list .list-product-detial {
  float: right; }

@media screen and (max-width: 767px) {
  .featured .grid__item {
    margin-bottom: 50px; } }

.deal-items ul li .products {
  border-right: none; }

.deal-items ul li:last-child .products {
  border-right: 1px solid #36342f; }

ul li .products {
  margin: 0px 0;
  float: left;
  width: 100%; }

@media screen and (max-width: 767px) {
  .collection-list .post-large--one-third:nth-child(2n) {
    margin-right: 0; } }

@media screen and (min-width: 768px) {
  .collection-list .post-large--one-third:nth-child(3n) {
    margin-right: 0; } }

.product-list {
  border: 1px solid #36342f;
  margin-bottom: 20px; }

.collection_sidebar {
  padding: 0px;
  float: left;
  width: 100%; }
  @media screen and (max-width: 967px) {
    .collection_sidebar {
      margin: 0; } }
  @media screen and (max-width: 767px) {
    .collection_sidebar {
      display: none; } }

.collection_sidebar .widget {
  float: left;
  width: 100%; }

.collection_sidebar .widget h4 a.clear, .collection_sidebar .widget .h4 a.clear {
  float: right;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  color: #fff;
  border: none; }

.collection_sidebar .top_products_nav a {
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center; }

.collection_sidebar .top_products_nav a.prev {
  left: 0; }

.collection_sidebar .top_products_nav a.next {
  right: 0; }

.collection_sidebar .widget_top_rated_products:hover .top_products_nav a {
  opacity: 1; }

.collection_sidebar .top-products li {
  float: left;
  width: 100%; }

.collection_sidebar .top-products li a.thumb img {
  margin: auto; }

.top-products-detail .sale {
  display: none;
  background: #11b00d;
  color: #fff;
  font-size: 10px;
  padding: 2px 6px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
  float: left; }

.collection_sidebar .top-products .top-products-detail a {
  font-size: 18px;
  float: left;
  width: 100%;
  margin-bottom: 5px; }
  @media screen and (max-width: 1599px) {
    .collection_sidebar .top-products .top-products-detail a {
      font-size: 14px; } }

.collection_sidebar .top-products .top-products-detail .price {
  font-size: 16px;
  color: #5a574f; }
  @media screen and (max-width: 967px) {
    .collection_sidebar .top-products .top-products-detail .price {
      font-size: 12px; } }

.collection_sidebar .top-products-detail {
  padding: 10px 0;
  text-align: center; }

.collection_sidebar .widget .product-categories li {
  float: left;
  width: 100%;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.collection_sidebar .widget .product-categories li, .product_sidebar .widget .product-categories li {
  float: left;
  width: 100%;
  padding: 12px 0 12px 20px;
  border-bottom: 1px dashed #36342f; }

.collection_sidebar .widget .product-categories li:last-child, .product_sidebar .widget .product-categories li:last-child {
  border-bottom: none; }

.collection_sidebar .widget .product-categories li a span {
  position: absolute;
  right: 0;
  float: right; }

.collection_sidebar .widget .product-categories li .dt-menu-expand {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  font-size: 16px; }

.collection_sidebar .widget .product-categories li:before, .sidebar-tag.brand li:before, .widget_categories li:before, .sidebar-tag li::before, .sidebar-tag.price li:before, .sidebar-tag.material li:before, .product_sidebar .widget .product-categories li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 100%;
  display: inline-block;
  background: #934d4d;
  position: absolute;
  left: 0;
  right: 0;
  top: 22px; }

.sidebar-tag.size ul li::before {
  content: none; }

.collection_sidebar .widget .product-categories li, .sidebar-tag.brand li, .widget_categories li, .sidebar-tag.price li, .sidebar-tag.material li {
  position: relative; }

.collection_sidebar .widget .product-categories ul.children {
  padding: 15px 0 15px 0px;
  margin: 0;
  border: none; }

@media screen and (max-width: 967px) {
  .collection_sidebar .widget ul li a {
    font-size: 13px; } }

.collection_sidebar .widget ul li a:hover, .collection_sidebar .widget .product-categories li:hover .dt-menu-expand {
  color: #934d4d; }

.collection_sidebar .product-detail .grid-link__meta .grid-link__org_price, .collection_sidebar .product-detail .grid-link__meta del {
  float: left; }

.collection_sidebar .widget .product-categories ul.children li a {
  font-size: 13px; }

.collection_sidebar .widget .product-categories ul.children li {
  position: relative; }

.widget .product-categories li span.dt-sc-toggle:hover:before {
  color: #934d4d; }

.widget.sidebar-tag ul {
  margin-bottom: 50px; }

.sidebar-tag.brand li i, .sidebar-tag.price li i, .sidebar-tag.material li i {
  margin-top: 10px; }

.widget.sidebar-tag:last-child ul {
  margin-bottom: 0; }

.nav_featured, .nav_featured_best-selling, .nav_home-collection-2 {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  z-index: 1; }

.nav_featured, .nav_featured_best-selling, .nav_home-collection-2 {
  margin: 40px 0 0; }

.nav_featured_best-selling a, .nav_home-collection-2 a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  line-height: 38px;
  border: 1px solid #36342f;
  cursor: pointer; }

.nav_featured_best-selling a.next, .nav_home-collection-2 a.next {
  margin-right: 10px; }

.nav_featured_best-selling a:hover, .nav_home-collection-2 a:hover {
  color: #934d4d; }

ul#featured {
  list-style: none;
  padding: 50px 0 0; }

ul#featured.owl-carousel .owl-item, #home-collection4.owl-carousel .owl-item, #home-collection2.owl-carousel .owl-item {
  padding: 0; }

@media screen and (max-width: 567px) {
  .products a img {
    margin: auto; } }

@media screen and (max-width: 567px) {
  .products .reveal .swipe {
    left: 0;
    right: 0; } }

.products {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative;
  float: left;
  width: 100%; }

/* Column */
.top_rated_section, .related-products-container {
  position: relative;
  float: left;
  width: 100%; }

.top_rated_section .grid-uniform {
  position: relative; }

.top_rated_section .carousel-arrow a {
  position: absolute;
  top: 55%; }
  @media screen and (max-width: 967px) {
    .top_rated_section .carousel-arrow a {
      top: 40%; } }
  @media screen and (max-width: 767px) {
    .top_rated_section .carousel-arrow a {
      top: 20%; } }
  @media screen and (max-width: 567px) {
    .top_rated_section .carousel-arrow a {
      top: 15%; } }

.related-products-container .nav_featured a.next {
  right: 0; }

.related-products-container .nav_featured a.left {
  left: 0; }

.related-products-container .nav_featured a.prev {
  left: 0; }

.related-products-container .nav_featured a {
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin-top: -10px;
  position: absolute;
  top: 33%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.related-products-container.recent_article .nav_featured a {
  top: 45%; }

.related-products-container .border-title h4, .related-products-container .border-title .h4 {
  font-weight: normal;
  color: #5a574f; }

.related-products-container .nav_featured {
  display: block;
  margin: 0; }

.related_products_container {
  float: left;
  width: 100%;
  position: relative; }

.related-products.owl-carousel .owl-wrapper-outer {
  padding: 0 0 20px; }

.related-products .post-large--one-quarter {
  width: 23.5%;
  margin-right: 2%; }

.image-block {
  position: relative; }

.image-block:before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.image-block {
  background: rgba(0, 0, 0, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.two_section_banner {
  float: left;
  width: 100%; }

/*.blog-tag { float:left;width:100%;margin-bottom:20px; }
.blog-design-4 .article img,.middle-top img,.middle-bottom img,.img-hover-effect img {-webkit-transition: all 1.5s ease;-moz-transition: all 1.5s ease;-o-transition: all 1.5s ease;-ms-transition: all 1.5s ease; transition: all 1.5s ease;   }
.blog-design-4 .article:hover img,.middle-top:hover img,.middle-bottom:hover img,.img-hover-effect:hover img { -webkit-transform: scale(1.2); -moz-transform: scale(1.2); -ms-transform: scale(1.2); transform: scale(1.2); }
.blog-design-4 .article,.middle-top,.middle-bottom,.img-hover-effect { -webkit-transition: all .3s ease-out; -moz-transition: all .3s ease-out; -ms-transition: all .3s ease-out; -o-transition: all .3s ease-out; transition: all .3s ease-out; }*/
.featured-content {
  -webkit-transition: all .35s linear;
  transition: all .35s linear;
  /* -webkit-transform-origin: 50% 0; */
  -ms-transform-origin: 50% 0;
  /* transform-origin: 50% 0; */ }

.newsletterwrapper .btn.close:before, .newsletterwrapper .close.btn--secondary:before, .newsletterwrapper .close.btn--tertiary:before, .newsletterwrapper input.close[type="submit"]:before {
  border: none;
  content: none; }

.newsletterwrapper btn.close::after, .newsletterwrapper btn.close::before {
  content: none; }

.blog-design-4 .article .article-img a {
  width: 100%; }

/* No CSS3 support */
.no-opacity .wrapper-dropdown-3 .dropdown, .no-pointerevents .wrapper-dropdown-3 .dropdown {
  display: none;
  opacity: 1;
  /* If opacity support but no pointer-events support */
  pointer-events: auto;
  /* If pointer-events support but no pointer-events support */ }

.no-opacity .wrapper-dropdown-3.active .dropdown, .no-pointerevents .wrapper-dropdown-3.active .dropdown {
  display: block; }

.featured .grid__item.post-large--one-sixth:last-child {
  margin-right: 0; }

.related-products .post-large--one-quarter:last-child {
  margin-right: 0; }

.related-products-container h2, .related-products-container .h2 {
  float: left;
  width: 100%; }

.related-products-container {
  float: left;
  width: 100%;
  position: relative; }

.owl-carousel .owl-item {
  padding: 0 0px; }

.owl-carousel li {
  list-style: none; }

.view_more_btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px 0 0; }

.featured .grid__item.post-large--one-sixth {
  width: 15.6%;
  margin-right: 1%; }

.contact-form input[type="text"], .contact-form input[type="email"], .contact-form input[type="tel"], .contact-form textarea {
  display: inline-block;
  width: 100%;
  padding: 15px;
  font-size: 13px; }

.contact-form {
  float: left;
  padding-right: 30px;
  margin: 0; }

.contact-form-section {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 0px; }

.contact-form-section .errors {
  display: inline-block;
  width: 100%; }

.contact-address .contact-form-section .errors ul li {
  padding: 10px 0;
  border: none; }

.contact-form-section .contact-form {
  padding-right: 0;
  margin: 0;
  float: none;
  margin: auto;
  width: 50%;
  padding: 30px;
  margin-bottom: 0px;
  background: rgba(0, 0, 0, 0); }
  @media screen and (max-width: 767px) {
    .contact-form-section .contact-form {
      width: 70%; } }
  @media screen and (max-width: 567px) {
    .contact-form-section .contact-form {
      width: 95%; } }

.contact-form-section .contact-form .btn, .contact-form-section .contact-form .btn--secondary, .contact-form-section .contact-form .btn--tertiary, .contact-form-section .contact-form input[type="submit"] {
  width: 100%; }

.contact-form .btn span, .contact-form .btn--secondary span, .contact-form .btn--tertiary span, .contact-form input[type="submit"] span {
  float: none;
  display: inline-block;
  width: 100%; }

.contact-form .btn, .contact-form .btn--secondary, .contact-form .btn--tertiary, .contact-form input[type="submit"] {
  width: 100%; }

.contact-address {
  float: left;
  width: 100%;
  background: url(//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/contact_bg_img.png?5955) no-repeat; }

.contact-address h4, .contact-address .h4 {
  text-transform: uppercase;
  letter-spacing: 1px; }

.contact-address ul {
  list-style: none;
  padding: 0; }

.contact-address li p {
  margin: 0; }

.contact-address li .contact_duration li {
  float: left;
  width: 100%; }

@media screen and (max-width: 967px) {
  .contact-address p {
    font-size: 13px; } }

.contact-address .social-icons {
  padding: 0px 0 0px;
  float: left;
  width: 100%; }

.contact-address .social-icons li {
  float: left;
  margin-right: 5px;
  width: auto; }
  @media screen and (max-width: 567px) {
    .contact-address .social-icons li {
      margin-right: 3px; } }

.contact-address li:last-child {
  border-right: none; }

.contact-address .social-icons li a {
  width: 35px;
  height: 35px;
  float: left;
  line-height: 35px;
  text-align: center;
  border: 1px solid #000; }
  @media screen and (max-width: 967px) {
    .contact-address .social-icons li a {
      width: 30px;
      height: 30px;
      line-height: 30px; } }
  @media screen and (max-width: 567px) {
    .contact-address .social-icons li a {
      width: 28px; } }

.contact-address .social-icons li a:hover {
  background: #934d4d;
  border-color: #934d4d; }

@media screen and (max-width: 1199px) {
  .contact-address .icon-wrapper {
    margin-bottom: 25px; } }

.contact-address li {
  padding: 0 10px;
  list-style: none;
  text-align: center; }

/**tabs**/
ul.dt-sc-tabs-frame, ul.dt-sc-tabs {
  margin: 0px;
  padding: 0px; }

ul.dt-sc-tabs-frame li {
  display: inline;
  float: left;
  margin: 0px;
  padding: 0px; }

.dt-sc-tabs-frame-content, .dt-sc-tabs-content {
  display: block;
  padding: 20px 20px 20px 0; }
  @media screen and (max-width: 767px) {
    .dt-sc-tabs-frame-content, .dt-sc-tabs-content {
      padding: 40px 20px 0px; } }

.dt-sc-tabs-content ul {
  margin: 10px 0px 0px; }

.dt-sc-tabs-content h3, .dt-sc-tabs-content .h3 {
  color: gainsboro; }

.dt-sc-tabs-content p {
  font-size: 14px; }

.dt-sc-tabs-content h2, .dt-sc-tabs-content .h2 {
  font-size: 26px;
  margin-bottom: 15px;
  color: gainsboro; }

.dt-sc-tabs-frame-content h5, .dt-sc-tabs-frame-content .h5, .dt-sc-tabs-content h5, .dt-sc-tabs-content .h5 {
  font-size: 18px;
  margin-bottom: 15px;
  color: gainsboro; }

.dt-sc-tabs-frame-content {
  background: #EDE7D6;
  margin-top: -1px;
  border: 1px solid #B6A99E;
  padding: 5px 10px;
  width: 100%;
  border-radius: 0px 5px 5px 5px;
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px; }

ul.dt-sc-tabs li {
  margin-right: 10px;
  float: left;
  display: inline;
  padding-bottom: 0px; }

ul.dt-sc-tabs-frame li {
  margin: 0px 8px 0px 0px; }

ul.dt-sc-tabs li a.current {
  background: #934d4d;
  color: #fff; }

ul.dt-sc-tabs li:first-child a {
  margin-left: 0; }

ul.dt-sc-tabs li a {
  position: relative;
  margin: 0 2px;
  padding: 15px 20px;
  display: inline-block;
  font-size: 14px;
  position: relative;
  z-index: 1;
  text-transform: capitalize; }
  @media screen and (max-width: 1199px) {
    ul.dt-sc-tabs li a {
      padding: 10px 15px;
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    ul.dt-sc-tabs li a {
      padding: 10px 20px; } }
  @media screen and (max-width: 567px) {
    ul.dt-sc-tabs li a {
      margin-bottom: 7px; } }

ul.dt-sc-tabs-frame li a {
  background: #D7CBBB;
  padding: 10px 15px 8px;
  float: left;
  position: relative;
  z-index: 1; }

.dt-sc-tabs-frame-content h5, .dt-sc-tabs-frame-content .h5 {
  margin: 15px 0px 10px; }

.dt-sc-tabs-frame-content p {
  margin: 0px 0px 10px 0px; }

.spr-container {
  border: none !important;
  padding: 0 !important; }

/**toggle-accordion**/
.dt-sc-toggle-frame-set {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px; }

.dt-sc-toggle-frame {
  padding-bottom: 20px;
  display: block; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion, .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5 {
  font-weight: normal;
  position: relative;
  text-transform: uppercase;
  margin: 0px 0px 0px;
  padding: 0px 0px 0px 20px; }

.dt-sc-toggle-frame h5 a, .dt-sc-toggle-frame .h5 a {
  display: block;
  text-transform: uppercase;
  color: #5c5c5c; }

.dt-sc-toggle-frame h5 a:before, .dt-sc-toggle-frame .h5 a:before, .dt-sc-toggle-frame h5 a:after, .dt-sc-toggle-frame .h5 a:after {
  position: absolute;
  text-decoration: inherit; }

.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle-accordion:before, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5:before, .dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle:before, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .dt-sc-toggle.h5:before {
  content: "\f107";
  font-family: Fontawesome;
  top: 0px;
  left: 0px;
  position: absolute;
  font-weight: normal; }

.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle-accordion:after, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5:after, .dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle:after, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .dt-sc-toggle.h5:after {
  content: "\f078";
  font-family: Fontawesome;
  top: 4px;
  left: 0px;
  display: inline-block;
  float: left;
  position: absolute; }

.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.active:before, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .active.h5:before, .dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.active:after, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .active.h5:after, .dt-sc-toggle-frame-set h5.dt-sc-toggle.active:before, .dt-sc-toggle-frame-set .dt-sc-toggle.active.h5:before {
  content: ""; }

.dt-sc-toggle-frame .dt-sc-toggle-content {
  padding: 5px 20px 20px 22px;
  margin: 0px 0px 0px 0px;
  background: #f4f4f4; }

.dt-sc-toggle-frame .dt-sc-toggle-accordion.active, .dt-sc-toggle-frame .dt-sc-toggle.active {
  background: #f4f4f4;
  margin-bottom: 0px;
  padding: 20px 10px 5px 20px; }

.dt-sc-toggle-accordion.active a:before, .dt-sc-toggle-accordion.active a:after, .dt-sc-toggle-frame .dt-sc-toggle.active a:before, .dt-sc-toggle-frame .dt-sc-toggle.active a:after, .dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle.active:before, .dt-sc-toggle-frame-set .dt-sc-toggle-frame .dt-sc-toggle.active.h5:before {
  content: ""; }

.dt-sc-toggle-frame-set h5.dt-sc-toggle-accordion:before, .dt-sc-toggle-frame-set .dt-sc-toggle-accordion.h5:before, h5.dt-sc-toggle:before, .dt-sc-toggle.h5:before {
  content: "\f107";
  font-family: FontAwesome; }

h5.dt-sc-toggle-accordion.active:before, .dt-sc-toggle-accordion.active.h5:before, .dt-sc-toggle-frame-set h5.dt-sc-toggle.active:before, .dt-sc-toggle-frame-set .dt-sc-toggle.active.h5:before {
  content: "\f106";
  font-family: FontAwesome; }

h5.dt-sc-toggle-accordion.active, .dt-sc-toggle-accordion.active.h5 {
  background: #934d4d;
  color: #fff; }

h5.dt-sc-toggle-accordion.active a, .dt-sc-toggle-accordion.active.h5 a {
  color: #fff; }

.course h5.dt-sc-toggle-accordion:before, .course .dt-sc-toggle-accordion.h5:before {
  content: "?"; }

.course h5.dt-sc-toggle-accordion.active:before, .course .dt-sc-toggle-accordion.active.h5:before {
  content: "?"; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion:before, .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5:before {
  content: ''; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion, .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5 {
  line-height: inherit; }

h5.dt-sc-toggle-accordion, .dt-sc-toggle-accordion.h5, .dt-sc-toggle-frame h5.dt-sc-toggle, .dt-sc-toggle-frame .dt-sc-toggle.h5, h5.dt-sc-toggle, .dt-sc-toggle.h5 {
  padding: 15px 0px 15px 42px;
  line-height: 30px;
  position: relative;
  font-size: 16px;
  margin: 0 0 -1px;
  border: 1px solid #36342f; }

.dt-sc-toggle-frame h5.dt-sc-toggle, .dt-sc-toggle-frame .dt-sc-toggle.h5 {
  padding: 0px 0px 0px 20px;
  margin-bottom: 0px; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion a, .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5 a, .dt-sc-toggle-frame h5.dt-sc-toggle a, .dt-sc-toggle-frame .dt-sc-toggle.h5 a {
  font-weight: normal; }

h5.dt-sc-toggle-accordion a, .dt-sc-toggle-accordion.h5 a {
  color: #5c5c5c;
  font-weight: bold;
  display: block; }

h5.dt-sc-toggle a, .dt-sc-toggle.h5 a {
  color: #5c5c5c;
  font-size: 16px;
  font-weight: bold;
  display: block; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion a, .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5 a {
  font-weight: normal;
  /*-webkit-transition:none; -moz-transition:none; -o-transition:none; -ms-transition:none; transition:none;*/ }

h5.dt-sc-toggle-accordion:before, .dt-sc-toggle-accordion.h5:before, h5.dt-sc-toggle:before, .dt-sc-toggle.h5:before {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  position: absolute;
  top: 15px;
  left: 3px;
  z-index: 1; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion:before, .dt-sc-toggle-frame .dt-sc-toggle-accordion.h5:before, .dt-sc-toggle-frame h5.dt-sc-toggle:before, .dt-sc-toggle-frame .dt-sc-toggle.h5:before {
  background: none;
  display: inherit;
  width: auto;
  height: auto;
  text-align: inherit;
  line-height: inherit;
  color: inherit;
  border: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  position: static; }

h5.dt-sc-toggle-accordion.active:before, .dt-sc-toggle-accordion.active.h5:before, h5.dt-sc-toggle.active:before, .dt-sc-toggle.active.h5:before {
  line-height: 25px; }

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active:before, .dt-sc-toggle-frame .dt-sc-toggle-accordion.active.h5:before, .dt-sc-toggle-frame h5.dt-sc-toggle.active:before, .dt-sc-toggle-frame .dt-sc-toggle.active.h5:before {
  border: 0px; }

.dt-sc-toggle-content {
  padding-left: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  clear: both;
  display: none;
  border: 1px solid #36342f;
  border-bottom: none; }

.dt-sc-toggle-content.last {
  border-bottom: 1px solid #36342f; }

/**vertical-tabs**/
.dt-sc-tabs-vertical-container {
  float: left;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
  clear: both; }

.dt-sc-tabs-vertical-container ul {
  float: left;
  width: 20%; }

.dt-sc-tabs-vertical-container .dt-sc-tabs-content {
  width: 80%;
  clear: none;
  float: left;
  padding: 0 0 0 90px;
  border: none;
  border-left: 1px solid #36342f; }

.dt-sc-tabs-vertical-container ul.dt-sc-tabs li {
  display: block;
  float: none; }

.dt-sc-tabs-vertical-container ul.dt-sc-tabs li a:after {
  content: none; }

.dt-sc-tabs-vertical-container ul.dt-sc-tabs li a {
  font-size: 18px;
  margin-bottom: 10px; }

ul.dt-sc-tabs-vertical-frame {
  float: left;
  width: 18%;
  margin: 0px 0px 0px;
  padding: 0px; }
  @media screen and (max-width: 767px) {
    ul.dt-sc-tabs-vertical-frame {
      width: 100%;
      margin-bottom: 20px; } }

ul.dt-sc-tabs-vertical-frame li.current {
  border-right: 2px solid #934d4d; }
  @media screen and (max-width: 767px) {
    ul.dt-sc-tabs-vertical-frame li.current {
      border-right: none; } }

ul.dt-sc-tabs-vertical-frame li {
  float: left;
  display: block;
  margin: 0px 1px 1px;
  padding: 0px;
  width: 100%;
  position: relative; }

ul.dt-sc-tabs-vertical-frame li a {
  display: block;
  border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  -moz-border-radius: 5px 0px 0px 5px;
  margin: 0px;
  padding: 12px;
  font-family: Libre Baskerville; }

ul.dt-sc-tabs-vertical-frame li.current:before {
  background: #934d4d; }

ul.dt-sc-tabs-vertical-frame li:before {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  float: left;
  content: "";
  background: #934d4d;
  margin: 19px 10px 0 0; }

.dt-sc-tabs-vertical-frame-content {
  border-left: 1px solid #36342f;
  border-radius: 0px 5px 5px 5px;
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
  float: left;
  width: 82%;
  margin: 0px;
  padding: 0px 0 0 4%; }
  @media screen and (max-width: 767px) {
    .dt-sc-tabs-vertical-frame-content {
      width: 100%;
      padding: 0;
      border-left: none; } }

.dt-sc-tabs-vertical-frame-content .tab-right {
  padding: 30px;
  background: #f4f4f4;
  margin-right: 0;
  width: 50%;
  min-height: 420px; }
  @media screen and (max-width: 767px) {
    .dt-sc-tabs-vertical-frame-content .tab-right {
      width: 100%; } }

.dt-sc-tabs-vertical-frame-content .tab-left {
  margin-right: 0;
  width: 50%; }
  @media screen and (max-width: 767px) {
    .dt-sc-tabs-vertical-frame-content .tab-left {
      width: 100%; } }

/*blog-section */
.left-sidebar {
  padding-right: 40px; }
  @media screen and (max-width: 1199px) {
    .left-sidebar {
      padding-right: 20px; } }

.right-sidebar {
  padding-left: 40px; }
  @media screen and (max-width: 1199px) {
    .right-sidebar {
      padding-left: 20px; } }

.cart_empty_msg {
  padding: 0 20px;
  text-align: center; }

.blog-section {
  display: inline-block;
  width: 100%; }

.blog-section .post-large--one-half {
  text-align: left;
  padding: 0 0px;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .blog-section .post-large--one-half {
      padding-right: 0; } }

.blog-section .post-large--one-half:nth-child(2n+1) {
  clear: both; }

.blog-section h2, .blog-section .h2 {
  margin: 0; }

.blog-section p.desc {
  margin: 0px 0 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #36342f;
  float: left;
  width: 100%; }

.blog-section.left-sidebar .post-large--four-fifths {
  padding-left: 60px; }

.blog-section #comments {
  margin-top: 30px; }

.blog-section #comments input[type="text"], .blog-section #comments input[type="email"] {
  float: left;
  width: 100%;
  border: 1px solid #36342f; }

.blog-section #comments textarea {
  width: 100%;
  border: 1px solid #36342f; }

.blog-section #comments ul {
  list-style: none;
  padding: 0; }

.blog-section #comments form {
  margin: 0; }

.blog-section #comments form h3, .blog-section #comments form .h3 {
  margin-bottom: 25px;
  font-size: 24px;
  text-transform: uppercase; }

.blog-section #comments h4, .blog-section #comments .h4 {
  text-transform: capitalize;
  margin-bottom: 5px;
  float: left; }

.blog-section #comments p {
  margin-bottom: 10px;
  margin-top: 10px; }

.blog-section #comments ul span {
  float: left;
  margin-right: 15px; }

.blog-section #comments ul span {
  font-weight: normal; }

.blog-section #comments ul li {
  margin: 0 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #36342f;
  float: left;
  width: 100%; }

.share-facebook {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 16px; }

.share-twitter {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 16px; }

.share-pinterest {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 16px; }

.share-google {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 16px; }

.social-sharing a {
  width: 45px;
  height: 50px;
  line-height: 50px;
  padding: 0; }
  @media screen and (max-width: 567px) {
    .social-sharing a {
      width: 25px;
      height: 25px;
      line-height: 25px; } }

.client_logo_section p {
  display: inline-block;
  width: 70%; }
  @media screen and (max-width: 767px) {
    .client_logo_section p {
      width: 100%; } }

#clients-carousel li a {
  position: relative;
  display: block;
  padding: 10px; }
  @media screen and (max-width: 767px) {
    #clients-carousel li a {
      text-align: center; } }

#clients-carousel img {
  text-align: center;
  margin: auto; }

#clients-carousel.owl-theme .owl-controls .owl-page.active span {
  background: #934d4d; }

#clients-carousel.owl-theme .owl-controls {
  margin-top: 40px; }

#clients-carousel.owl-theme .owl-controls .owl-page span {
  opacity: 1;
  width: 8px;
  height: 8px;
  background: #36342f;
  display: inline-block;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0; }

.slick-slider {
  position: relative;
  float: left;
  width: 100%; }

.slick-list {
  float: left;
  width: 100%; }

.instagram .border-title h2, .instagram .border-title .h2 {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 30px; }

.instagram .border-title h2 span, .instagram .border-title .h2 span {
  display: inline-block;
  padding: 15px 30px; }
  @media screen and (max-width: 967px) {
    .instagram .border-title h2 span, .instagram .border-title .h2 span {
      font-size: 20px; } }

.instagram .border-title p {
  margin-bottom: 0; }

.instagram {
  padding: 0px;
  float: left;
  width: 100%;
  position: relative; }

.instagram h3, .instagram .h3 {
  margin-bottom: 40px; }

.instagram .slick-arrow {
  position: absolute;
  margin: 0;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.instagram .slick-arrow.slick-prev {
  left: 0; }

.instagram .slick-arrow.slick-next {
  right: 0; }

#instafeed a img {
  margin-right: 5px;
  margin-bottom: 0px; }

.instagram #instafeed a {
  width: 10%;
  float: left; }

.lush-instagram .item {
  padding: 0;
  float: left;
  width: 25%;
  position: relative;
  border-radius: 0; }
  @media screen and (max-width: 767px) {
    .lush-instagram .item {
      width: 50%; } }
  @media screen and (max-width: 567px) {
    .lush-instagram .item {
      width: 100%; } }

.lush-instagram .info span.likes {
  left: 50%;
  right: auto; }

.lush-instagram .info span {
  right: 50%; }

.lush-instagram .info {
  display: none;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.lush-instagram .info span {
  display: inline-block;
  width: 50px;
  height: 45px;
  line-height: 45px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.lush-instagram .info span i {
  display: inline-block;
  margin-right: 3px; }

.lush-instagram img {
  display: block;
  height: auto; }

.lush-instagram .info {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none; }

.lush-instagram .item:hover .info {
  opacity: 1; }

.lush-instagram .info span {
  margin: 0 5px; }

.is-username {
  background: rgba(246, 246, 248, 0.8);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px 70px; }

/* common to every demo */
#CustomerLoginForm, .register-form, #RecoverPasswordForm, .reset_customer_pwd {
  display: inline-block;
  text-align: center;
  width: 100%; }

.reset_customer_pwd form, .AddAddress-form form, .edit_address_form {
  display: inline-block;
  width: 40%; }
  @media screen and (max-width: 767px) {
    .reset_customer_pwd form, .AddAddress-form form, .edit_address_form {
      width: 100%; } }

.AddAddress-form {
  display: inline-block;
  width: 100%;
  margin: 30px 0 0; }

.AddAddress-form h2, .AddAddress-form .h2 {
  text-align: left; }

.reset_customer_pwd label, .AddAddress-form label, .edit_address_form label {
  float: left;
  width: 100%; }

.reset_customer_pwd input, #AddAddress select, #AddAddress input[type="text"], #AddAddress input[type="tel"] {
  padding: 14px 10px;
  width: 100%; }

#CustomerLoginForm input, .register-form input, #RecoverPasswordForm input, #ResetPassword, #PasswordConfirmation {
  padding: 14px 10px;
  width: 30%; }
  @media screen and (max-width: 1199px) {
    #CustomerLoginForm input, .register-form input, #RecoverPasswordForm input, #ResetPassword, #PasswordConfirmation {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    #CustomerLoginForm input, .register-form input, #RecoverPasswordForm input, #ResetPassword, #PasswordConfirmation {
      width: 60%; } }
  @media screen and (max-width: 567px) {
    #CustomerLoginForm input, .register-form input, #RecoverPasswordForm input, #ResetPassword, #PasswordConfirmation {
      width: 100%; } }

#CustomerLoginForm .errors, .register-form .errors, #RecoverPasswordForm .errors, #ResetSuccess {
  display: inline-block;
  width: 30%;
  text-align: left; }
  @media screen and (max-width: 1199px) {
    #CustomerLoginForm .errors, .register-form .errors, #RecoverPasswordForm .errors, #ResetSuccess {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    #CustomerLoginForm .errors, .register-form .errors, #RecoverPasswordForm .errors, #ResetSuccess {
      width: 60%; } }
  @media screen and (max-width: 567px) {
    #CustomerLoginForm .errors, .register-form .errors, #RecoverPasswordForm .errors, #ResetSuccess {
      width: 100%; } }

#ResetSuccess {
  margin: 0 auto 20px; }

.register-form a {
  text-decoration: underline; }

#AddAddress .address_check {
  margin-bottom: 20px;
  line-height: 15px; }

#AddAddress .address_check input, .edit_address_form input[type="checkbox"] {
  float: left;
  margin-right: 10px; }

#AddAddress .address_check label, .edit_address_form label.inline {
  width: auto;
  font-size: 13px; }

#CustomerLoginForm input[type="submit"] {
  margin: 0; }

#jquery-tab-pager-navi {
  display: none; }

#CustomerLoginForm #customer_register_link {
  text-decoration: underline; }

#AddAddress label, .edit_address_form label, .reset_customer_pwd label {
  display: inline-block;
  text-align: left; }

#AddAddress {
  display: inline-block;
  width: 100%; }

#AddAddress select {
  margin-bottom: 20px; }

.account-success-message {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: auto;
  float: none; }

.order-form {
  display: inline-block;
  width: 100%; }

.order-form h4, .order-form .h4 {
  font-size: 24px; }

.order-form h5, .order-form .h5 {
  font-size: 20px;
  text-transform: capitalize; }

.order-form tr th {
  text-align: center; }

.order-form table {
  margin-bottom: 40px; }

.order-form .table-wrap {
  margin-bottom: 30px; }

.address-section {
  display: inline-block;
  width: 100%; }

.address-section p a {
  padding-right: 5px;
  text-decoration: underline; }

.address-section p a:last-child {
  padding-left: 5px; }

.customer-address-title {
  display: inline-block;
  width: 100%; }

.edit_address_form input[type="checkbox"], .edit_address_form label.inline {
  float: left;
  width: initial;
  line-height: 12px;
  margin-bottom: 15px; }

.edit_address_form select, .edit_address_form input[type="text"], .edit_address_form input[type="tel"] {
  width: 100%;
  margin: 0 0 20px; }

/* Cart */
.cart__header-labels, .cart__header-labels span {
  font-size: 18px;
  text-align: center;
  float: left;
  width: 100%;
  color: #ffffff; }
  @media screen and (max-width: 967px) {
    .cart__header-labels, .cart__header-labels span {
      font-size: 14px; } }
  @media screen and (max-width: 567px) {
    .cart__header-labels, .cart__header-labels span {
      font-size: 13px; } }

.cart__header-labels {
  background: #000;
  padding: 15px 0 0; }

.cart__header-labels .post-large--one-half, .cart__row .post-large--one-half {
  width: 50%;
  margin-right: 0; }
  @media screen and (max-width: 767px) {
    .cart__header-labels .post-large--one-half, .cart__row .post-large--one-half {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .cart__row .shipping-section p {
    float: left;
    width: 100%;
    text-align: left; } }

@media screen and (max-width: 767px) {
  .cart__row .shipping-section .post-large--one-half {
    width: 100%;
    text-align: left !important; } }

.grid.shipping-section, .shipping-section.grid--rev, .shipping-section.grid--full, .order-form {
  padding: 0 20px; }
  @media screen and (max-width: 967px) {
    .grid.shipping-section, .shipping-section.grid--rev, .shipping-section.grid--full, .order-form {
      padding: 0; } }

.cart__row .cart__subtotal-title, .cart__row span.cart__subtotal {
  float: left;
  width: initial; }

.cart__mini-labels {
  display: block;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: em(12px); }
  @media screen and (max-width: 767px) {
    .cart__mini-labels {
      display: none; } }

@media screen and (min-width: 767px) {
  .cart__large-labels {
    display: none; } }

@media screen and (max-width: 767px) {
  .cart_items {
    border-bottom: 1px solid #36342f;
    padding-bottom: 30px; } }

.cart__row .cart_items {
  display: inline-block;
  text-align: center; }

@media screen and (max-width: 567px) {
  .cart__row .four-tenths, .cart__row .six-tenths {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .cart__large-labels {
    float: left;
    width: 100%;
    padding: 20px 0;
    font-size: 12px; } }

.cart__row .qtyminus1, .cart__row .qtyplus1 {
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  float: left;
  border-radius: 0;
  cursor: pointer;
  text-align: center; }
  @media screen and (max-width: 967px) {
    .cart__row .qtyminus1, .cart__row .qtyplus1 {
      width: 35px; } }
  @media screen and (max-width: 767px) {
    .cart__row .qtyminus1, .cart__row .qtyplus1 {
      width: 22px; } }
  @media screen and (max-width: 567px) {
    .cart__row .qtyminus1, .cart__row .qtyplus1 {
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 12px; } }

@media screen and (max-width: 767px) {
  .cart__row .cart-title {
    padding: 10px 0; } }
@media screen and (max-width: 567px) {
  .cart__row .cart-title {
    padding: 10px 0; } }

.wishlist_table th {
  text-align: center; }

.wishlist_page p a {
  text-decoration: underline; }

.wishlist th, td {
  text-align: center; }
  @media screen and (max-width: 767px) {
    .wishlist th, td {
      padding: 10px 5px; } }
  @media screen and (max-width: 567px) {
    .wishlist th, td {
      padding: 10px 3px;
      font-size: 12px; } }

.wishlist tr td {
  font-weight: 400;
  text-transform: capitalize; }

.wishlist tr td p, .wishlist tr td form {
  margin-bottom: 0; }

.wishlist tr {
  border-left: 1px solid #36342f;
  border-right: 1px solid #36342f;
  border-top: 1px solid #36342f; }

.wishlist thead th {
  border-left: 1px solid #36342f;
  border-right: 1px solid #36342f;
  border-top: none;
  border-bottom: none; }

.wishlist tr .product_image {
  display: inline-block; }

.wishlist th {
  font-weight: 400;
  text-transform: capitalize; }

@media screen and (max-width: 567px) {
  .wishlist .image img {
    max-width: 55%; } }

@media screen and (max-width: 567px) {
  .wishlist a {
    font-size: 12px; } }

@media screen and (max-width: 767px) {
  .wishlist .dt-sc-button.small {
    font-size: 12px;
    padding: 10px 8px; } }
@media screen and (max-width: 567px) {
  .wishlist .dt-sc-button.small {
    font-size: 9px;
    background: inherit;
    color: #000;
    padding: 0;
    text-transform: capitalize;
    text-decoration: underline; } }

.wishlist thead th {
  font-size: 18px; }
  @media screen and (max-width: 567px) {
    .wishlist thead th {
      font-size: 12px;
      padding: 15px 5px; } }

@media screen and (max-width: 567px) {
  .wishlist tr {
    border: none; } }

.wishlist thead th.item {
  padding: 15px; }

.wishlist span.money {
  color: #934d4d;
  font-size: 16px; }
  @media screen and (max-width: 567px) {
    .wishlist span.money {
      font-size: 12px; } }

.qty-box-set {
  display: inline-block; }

.cart__row .quantity-selector {
  border-radius: 0;
  border: none;
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #f5f5f5;
  float: left;
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 967px) {
    .cart__row .quantity-selector {
      width: 35px; } }
  @media screen and (max-width: 767px) {
    .cart__row .quantity-selector {
      width: 30px; } }
  @media screen and (max-width: 567px) {
    .cart__row .quantity-selector {
      width: 25px;
      height: 25px;
      line-height: 25px; } }

.cart__row .money, .wishlist-product-price {
  color: #5a574f;
  font-size: 20px; }
  @media screen and (max-width: 767px) {
    .cart__row .money, .wishlist-product-price {
      font-size: 16px; } }
  @media screen and (max-width: 567px) {
    .cart__row .money, .wishlist-product-price {
      font-size: 14px; } }

.wishlist-box .product-thumbnail {
  width: 100px;
  height: 100px;
  float: left; }

.wishlist-box .product-thumbnail img {
  display: block; }

.cart__row .cart__remove {
  display: inline-block;
  font-size: 18px; }
  @media screen and (max-width: 567px) {
    .cart__row .cart__remove {
      font-size: 14px; } }

.cart__row .cart__remove:hover {
  color: #934d4d; }

.cart_btn, .shopping-checkout {
  float: left;
  width: 100%; }
  @media screen and (max-width: 567px) {
    .cart_btn, .shopping-checkout {
      text-align: left; } }

.cart_total_price {
  float: right; }

.mobile-nav-section {
  float: left;
  width: 100%;
  border-left: none;
  border-right: none;
  padding: 10px 10px;
  z-index: 10000;
  background: #b10a0a;
  color: #ffffff; }

.mobile-nav-trigger, .mobile-cart-page-link {
  font-weight: bold;
  font-size: 18px; }
  .mobile-nav-trigger .icon, .mobile-cart-page-link .icon {
    position: relative;
    top: -1px;
    vertical-align: middle;
    padding-right: 4px; }

.mobile-nav-trigger {
  float: left;
  text-align: left;
  display: block;
  text-align: left;
  float: left;
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  .mobile-nav-trigger .icon {
    font-size: 1.4em; }

.mobile-cart-page-link {
  display: block;
  float: right; }
  .mobile-cart-page-link .header-bar__cart-icon {
    font-size: 1.4em; }
  .mobile-cart-page-link .cart-count:before {
    display: inline;
    content: "("; }
  .mobile-cart-page-link .cart-count:after {
    display: inline;
    content: ")"; }

.mobile-nav-trigger span {
  float: left; }

#MobileNav {
  border-bottom: 1px solid #36342f; }

.mobile-nav {
  display: none;
  list-style: none;
  text-align: left;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0; }
  .mobile-nav li {
    margin: 0; }

.mobile-nav__link {
  display: block;
  float: left;
  width: 100%;
  border-top: 1px solid #000;
  border-color: rgba(0, 0, 0, 0.2);
  position: relative;
  /*================ Can't always control anchor markup to add a class ================*/ }
  .mobile-nav__link > a {
    display: block;
    padding: 12px 15px;
    font-size: 14px;
    text-transform: uppercase; }

.mobile-nav__link .header-bar__search {
  display: none; }

.mobile-nav__sublist-expand,
.mobile-nav__sublist-contract {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 12px;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: middle;
  margin: 0px; }

.mobile-nav__sublist-expand:hover {
  color: #934d4d; }

.mobile-nav__sublist-contract {
  display: none; }

.mobile-nav__sublist-trigger {
  position: absolute;
  right: 10px;
  top: 7px; }

.mobile-nav__sublist-trigger.is-active .mobile-nav__sublist-contract {
  display: inline-block; }
.mobile-nav__sublist-trigger.is-active .mobile-nav__sublist-expand {
  display: none; }

.mobile-nav__sublist {
  list-style: none;
  margin: 0;
  display: none;
  background-color: #181616; }
  .mobile-nav__sublist .mobile-nav__sublist {
    margin-left: 15px; }
    .mobile-nav__sublist .mobile-nav__sublist .mobile-nav__sublist-link a {
      border-top: none; }

.mobile-nav__sublist-link a {
  display: block;
  padding: 12px 15px;
  font-size: em(15px);
  border-top: 1px solid #36342f; }
  @media screen and (min-width: 567px) {
    .mobile-nav__sublist-link a {
      padding-left: 30px;
      padding-right: 30px; } }
  .mobile-nav__sublist-link a:hover {
    opacity: 1; }

@media screen and (max-width: 567px) {
  .one-tenth {
    width: 100%; } }

@media screen and (max-width: 567px) {
  .nine-tenths {
    width: 100%; } }

@media screen and (max-width: 567px) {
  .site-footer .grid__item.one-tenth {
    margin: 0; } }

@media screen and (max-width: 567px) {
  .footer-logo img {
    margin: auto;
    padding: 0; } }

/* collection */
.collection-products {
  float: left;
  width: 100%;
  padding: 0; }

.list-collection-products .grid-link__title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 10px; }
  @media screen and (max-width: 967px) {
    .list-collection-products .grid-link__title {
      font-size: 14px; } }

.list-collection-products a.pickgradient.grid-link {
  display: block;
  border: 1px solid #36342f; }

.list-collection-products .brand-icons a.pickgradient.grid-link {
  border: none; }

.list-collection-products .collection-detail {
  display: inline-block;
  width: 100%;
  padding: 30px 20px;
  border: 1px solid #36342f;
  border-top: none; }

.list-collection-products .grid__item {
  padding-right: 20px;
  padding-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .list-collection-products .grid__item {
      padding-right: 7px; } }

.list-collection-products .grid__item img {
  display: block;
  margin: auto; }

.pickgradient {
  float: left;
  width: 100%;
  position: relative; }

.pickgradient .collection-count span {
  font-size: 14px;
  text-transform: uppercase;
  float: left;
  width: 100%;
  letter-spacing: 1px; }

.pickgradient .collection-count {
  font-size: 50px;
  line-height: 30px;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 25px;
  margin: 0; }

.pickgradient:hover .collection-count, .pickgradient:hover .dt-sc-event-overlay .collection-count {
  opacity: 1; }

.dt-sc-event-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.pickgradient:hover .dt-sc-event-overlay, .pickgradient:hover .dt-sc-event-overlay:before {
  opacity: 1; }

.pickgradient-products:hover a.grid-link {
  color: #934d4d; }

.sidebar {
  display: block; }

/*search */
.search-bar {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: auto; }

.search-form {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0; }

.search-form .input-group-field {
  width: 45%;
  display: inline-block;
  min-height: 50px;
  padding: 10px 14px; }
  @media screen and (max-width: 567px) {
    .search-form .input-group-field {
      width: 76%; } }

.search-form .input-group-btn {
  display: inline-block;
  min-height: 50px;
  margin-left: -4px; }

.search-form .input-group-btn .btn, .search-form .input-group-btn .btn--secondary, .search-form .input-group-btn .btn--tertiary, .search-form .input-group-btn input[type="submit"] {
  min-height: 50px;
  padding: 12px 20px;
  border-radius: 0;
  border: none; }

.search-form .input-group-btn .btn:hover, .search-form .input-group-btn .btn--secondary:hover, .search-form .input-group-btn .btn--tertiary:hover, .search-form .input-group-btn input[type="submit"]:hover {
  background: #934d4d; }

.search-products .grid-link__meta {
  padding: 0; }

.search-products ul {
  padding: 0 0 0 0px; }

.search-products > ul {
  float: left;
  width: 100%;
  margin: 30px 0 30px -20px; }
  @media screen and (max-width: 767px) {
    .search-products > ul {
      margin: 30px 0 30px 0px; } }

.search-products ul > li.grid__item {
  padding: 10; }

.search-products ul > li.grid__item:nth-child(4n+1) {
  clear: both; }

.search-products ul > li.grid__item ul {
  margin: 0 0 25px; }

.product-photo-thumbs {
  list-style: none;
  padding: 15px 20px 0;
  float: left;
  text-align: center; }

.quick-view-carousel {
  float: left;
  width: 100%;
  text-align: center;
  margin: auto; }

.quick-view-carousel > div {
  display: inline-block;
  margin: 0 5px; }

.more-view-wrapper-owlslider {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 10px; }

.more-view-wrapper-owlslider .single-page-owl-carousel a.prev {
  left: 0; }

.more-view-wrapper-owlslider .single-page-owl-carousel a.next {
  right: 0; }

.more-view-wrapper-owlslider .single-page-owl-carousel a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  z-index: 1;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute; }
  @media screen and (max-width: 967px) {
    .more-view-wrapper-owlslider .single-page-owl-carousel a {
      width: 25px;
      height: 25px;
      line-height: 25px; } }

.product-photo-thumbs .owl-pagination {
  display: none; }

.product-photo-thumbs .owl-item {
  float: none;
  display: inline-block; }

.product-photo-thumbs.owl-carousel .owl-wrapper-outer {
  display: inline-block;
  text-align: center; }

.product-img-box {
  position: relative;
  padding-right: 30px; }
  @media screen and (max-width: 967px) {
    .product-img-box {
      padding-right: 10px; } }
  @media screen and (max-width: 767px) {
    .product-img-box {
      padding-right: 0px; } }

.product-img-box .product-photo-container {
  position: relative;
  text-align: center; }

.product-img-box .product-photo-thumbs li {
  margin: 0; }

.product-img-box .product-photo-thumbs.owl-theme li {
  padding: 0 10px;
  width: 100%; }

.product-img-box .product-photo-thumbs li a {
  display: block; }

@media screen and (max-width: 767px) {
  .product-single .product-photo-container {
    z-index: -1; } }

.product-photo-container {
  float: left;
  width: 100%;
  position: relative; }
  @media screen and (max-width: 967px) {
    .product-photo-container {
      width: 100%; } }
  @media screen and (max-width: 567px) {
    .product-photo-container {
      width: 100%; } }

.product-photo-container img {
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  max-width: 100%; }

/*.zoomContainer,.zoomLens,.zoomWindowContainer,#fancybox-wrap { @include at-query ($max, $medium) { display:none; } }
.zoomWrapper img {@include at-query ($max, $medium) { z-index: -1;}}
.zoomContainer { z-index: 99; width: auto !important; height: auto !important; }*/
@media screen and (max-width: 767px) {
  .product-single .post-large--one-half {
    width: 100%;
    margin-top: 40px; } }

.product-single__hero .owl-theme .owl-controls .owl-buttons div, .quick-view .owl-theme .owl-controls .owl-buttons div {
  text-indent: -9999px;
  background: url(//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/product-arrow.png?v=1238660925293545047) no-repeat;
  float: left;
  width: 27px;
  height: 26px;
  border-radius: 0;
  -webkit-border-radius: 0;
  margin: 0; }

.product-single__hero .owl-theme .owl-controls .owl-buttons .owl-prev, .quick-view .owl-theme .owl-controls .owl-buttons .owl-prev {
  background-position: left center;
  position: absolute;
  top: 37%;
  left: 0; }

.product-single__hero .owl-theme .owl-controls .owl-buttons .owl-next, .quick-view .owl-theme .owl-controls .owl-buttons .owl-next {
  background-position: right center;
  position: absolute;
  top: 37%;
  right: 0; }

/* ajax */
.ajax-success-modal .content, .ajax-error-modal .modal-inner {
  padding: 40px;
  background-color: #181616;
  bottom: auto;
  left: 0;
  position: fixed;
  right: 0;
  top: 50% !important;
  width: 900px;
  box-shadow: 0 0 0 8px  #36342f;
  float: left;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  z-index: 999; }
  @media screen and (max-width: 967px) {
    .ajax-success-modal .content, .ajax-error-modal .modal-inner {
      padding: 20px; } }
  @media screen and (max-width: 567px) {
    .ajax-success-modal .content, .ajax-error-modal .modal-inner {
      padding: 10px; } }
  @media screen and (max-width: 967px) {
    .ajax-success-modal .content, .ajax-error-modal .modal-inner {
      width: 730px; } }
  @media screen and (max-width: 767px) {
    .ajax-success-modal .content, .ajax-error-modal .modal-inner {
      width: 450px; } }
  @media screen and (max-width: 567px) {
    .ajax-success-modal .content, .ajax-error-modal .modal-inner {
      width: 270px; } }

.ajax-success-modal .ajax-left {
  float: left;
  padding-right: 15px;
  width: 40%; }
  @media screen and (max-width: 767px) {
    .ajax-success-modal .ajax-left {
      width: 100%;
      margin-bottom: 40px; } }

.ajax-product-image {
  width: 25%;
  float: left;
  border: 1px solid #36342f;
  display: block; }

.ajax-success-modal .ajax-left {
  float: left; }

.ajax-product-title {
  margin-bottom: 7px;
  font-size: 22px; }
  @media screen and (max-width: 567px) {
    .ajax-product-title {
      font-size: 18px; } }

.ajax-right .ajax_price {
  font-size: 16px;
  font-weight: 100;
  float: left;
  width: 100%;
  margin-bottom: 7px; }
  @media screen and (max-width: 567px) {
    .ajax-right .ajax_price {
      margin-bottom: 5px;
      font-size: 14px; } }

.ajax-success-modal .success-message a {
  line-height: normal;
  text-transform: uppercase; }
  @media screen and (max-width: 567px) {
    .ajax-success-modal .success-message a {
      font-size: 13px; } }

.ajax-right {
  float: left;
  width: 60%;
  padding: 0px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .ajax-right {
      width: 100%; } }

.ajax-success-modal .added-to-cart.info, .ajax-success-modal .added-to-wishlist.info {
  font-size: 16px;
  text-align: left;
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: underline; }

.ajax_cartTotal {
  float: left;
  width: 100%;
  font-size: 30px;
  margin-bottom: 70px; }
  @media screen and (max-width: 767px) {
    .ajax_cartTotal {
      margin-bottom: 20px; } }

.ajax-success-modal .success-message {
  display: inline-block; }

.ajax-cart-desc {
  float: left;
  width: 65%;
  margin-left: 30px; }
  @media screen and (max-width: 1599px) {
    .ajax-cart-desc {
      margin-left: 20px; } }
  @media screen and (max-width: 1199px) {
    .ajax-cart-desc {
      margin-left: 15px; } }
  @media screen and (max-width: 967px) {
    .ajax-cart-desc {
      margin-left: 10px; } }

.ajax-right p {
  text-transform: uppercase;
  font-size: 16px; }

.ajax-success-modal .ajax_price {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  font-size: 18px; }

.ajax-success-modal .continue-shopping {
  line-height: normal;
  margin-right: 12px; }
  @media screen and (max-width: 967px) {
    .ajax-success-modal .continue-shopping {
      margin-right: 5px; } }
  @media screen and (max-width: 567px) {
    .ajax-success-modal .continue-shopping {
      margin-right: 10px;
      margin-bottom: 15px; } }

.ajax-right .btn, .ajax-right .btn--secondary, .ajax-right .btn--tertiary, .ajax-right input[type="submit"] {
  padding: 12px 40px; }

/* Quick view */
/* pop up newsletter */
#email-modal .modal-window .window-window {
  padding: 80px;
  float: left;
  width: 35%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: inline-block;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  margin: auto; }
  @media screen and (max-width: 1599px) {
    #email-modal .modal-window .window-window {
      width: 50%; } }
  @media screen and (max-width: 1199px) {
    #email-modal .modal-window .window-window {
      width: 64%; } }
  @media screen and (max-width: 967px) {
    #email-modal .modal-window .window-window {
      width: 80%; } }

#email-modal .modal-window {
  display: none;
  margin: 0 auto;
  padding: 10px; }

#email-modal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }
  @media screen and (max-width: 767px) {
    #email-modal {
      display: none !important; } }

#mc_embed_signup #mc-embedded-subscribe-form #mail {
  border: none;
  float: left;
  width: 100%;
  height: 50px;
  background: #fff;
  margin: 0;
  padding: 0 85px 0 15px;
  font-size: 16px; }

#mc_embed_signup #mc-embedded-subscribe-form input::-webkit-input-placeholder {
  color: #000; }

#mc_embed_signup #mc-embedded-subscribe-form input:-moz-placeholder {
  color: #000; }

#mc_embed_signup #mc-embedded-subscribe-form input::-moz-placeholder {
  color: #000; }

#mc_embed_signup #mc-embedded-subscribe-form input:-ms-input-placeholder {
  color: #000; }

#mc_embed_signup #mc-embedded-subscribe-form input {
  color: #000; }

#mc-embedded-subscribe-form input[type='text'] {
  border: none; }

#mc_embed_signup form {
  display: inline-block;
  text-align: center;
  width: 100%; }

#mc-embedded-subscribe-form input[type="email"]:focus {
  border: none; }

#mc_embed_signup form {
  text-align: center !important;
  margin: 0px 0 10px;
  padding: 0px;
  position: relative; }

#email-modal .window-window .window-content {
  width: 100%;
  float: left;
  display: inline-block;
  text-align: center;
  margin: auto;
  z-index: 1; }

#email-modal .modal-overlay {
  background: #ffffff;
  height: 100%;
  position: absolute;
  width: 100%; }

#email-modal .modal-overlay {
  opacity: 0.8;
  filter: alpha(opacity=50);
  zoom: 1; }

#email-modal .window-border {
  background: #111;
  left: 0;
  position: absolute;
  top: 0; }

#email-modal .window-window {
  position: relative;
  min-height: 280px; }

#email-modal .window-window .window-background {
  opacity: 0.9;
  filter: alpha(opacity=90); }

#email-modal .window-window .window-content {
  padding: 30px;
  margin: 0px; }

#mc_embed_signup button {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 0;
  top: 0;
  width: auto; }

#email-modal .window-window .window-content .title,
#email-modal .window-window .window-content .message {
  text-align: center;
  float: left;
  border-top: none;
  color: #b10a0a;
  font-size: 16px; }

#email-modal .window-window .window-content .message {
  width: 100%;
  margin: auto; }

#email-modal .window-window .window-content .title {
  display: inline-block;
  font-size: 30px;
  left: 0;
  letter-spacing: 0.05em;
  line-height: normal;
  margin: 0 auto;
  padding: 0 0px 20px;
  text-align: center;
  width: 100%; }

#email-modal .window-window .window-content .title span:before {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -10px;
  height: 2px;
  content: "";
  width: 20px;
  background: #404e53; }

#email-modal .window-window .window-content .title span {
  font-size: 14px;
  text-transform: none;
  display: block;
  font-weight: normal;
  padding-top: 18px;
  line-height: 14px;
  color: #404e53;
  position: relative;
  margin-top: 15px;
  letter-spacing: 0; }

#email-modal .window-window .window-content .message {
  clear: both;
  padding: 0px 25px 15px;
  margin-bottom: 0px;
  font-weight: normal; }

#email-modal .modal-window .window-window::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(38, 34, 34, 0.7);
  z-index: -1; }

#mailchimp-email-subscibe {
  padding: 30px 20px 0;
  float: left;
  width: 100%; }

#mce-EMAIL {
  border: 1px solid #000;
  color: #333;
  font: 11px arial, sans-serif;
  height: 11px;
  margin: 0 !important;
  padding: 8px !important;
  width: 217px; }

#mc-embedded-subscribe,
#mc_embed_signup button span,
#mc_embed_signup .input-group-btn .btn,
#mc_embed_signup .input-group-btn .btn--secondary,
#mc_embed_signup .input-group-btn .btn--tertiary,
#mc_embed_signup .input-group-btn input[type="submit"] {
  color: #fff;
  height: auto !important;
  width: auto;
  line-height: 36px !important;
  padding: 0 !important;
  text-transform: uppercase;
  background: #272e31;
  display: block; }

#mc_embed_signup .input-group-btn {
  float: right; }

#mc_embed_signup button span span {
  border: none;
  padding: 0 20px !important;
  background: none; }

#mc_embed_signup button:hover span,
#mc_embed_signup .input-group-btn .btn:hover,
#mc_embed_signup .input-group-btn .btn--secondary:hover,
#mc_embed_signup .input-group-btn .btn--tertiary:hover,
#mc_embed_signup .input-group-btn input[type="submit"]:hover {
  background: #cbeded; }

#mc_embed_signup .input-group-btn .btn, #mc_embed_signup .input-group-btn .btn--secondary, #mc_embed_signup .input-group-btn .btn--tertiary, #mc_embed_signup .input-group-btn input[type="submit"] {
  padding: 0 15px !important; }

#mc_embed_signup button:hover span span {
  background: none; }

#mc_embed_signup button:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

#mc_embed_signup button {
  float: right;
  border: none;
  height: 50px; }

#mc_embed_signup input[type="text"]:focus {
  border: none; }

.newsletterwrapper .btn.close, .newsletterwrapper .close.btn--secondary, .newsletterwrapper .close.btn--tertiary, .newsletterwrapper input.close[type="submit"] {
  background: url(//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/hover-Close.png?v=14390700632560925198) no-repeat;
  background-position: right;
  border: none;
  cursor: pointer;
  width: 58px;
  height: 58px;
  text-indent: -9999px;
  position: absolute;
  padding: 0; }

.newsletterwrapper .btn.close, .newsletterwrapper .close.btn--secondary, .newsletterwrapper .close.btn--tertiary, .newsletterwrapper input.close[type="submit"] {
  top: -6px;
  right: 1%;
  z-index: 9; }

#email-modal .window-window .window-content input[type="email"] {
  border-radius: 30px;
  border: none; }

.tbl-list > li.currency > .dropdown-toggle i {
  margin-top: 6px;
  display: none; }
  @media screen and (max-width: 767px) {
    .tbl-list > li.currency > .dropdown-toggle i {
      margin-top: 0; } }

@media screen and (max-width: 767px) {
  .tbl-list > li.currency .flag-dropdown-menu li {
    padding: 0; } }

@media screen and (max-width: 767px) {
  .tbl-list > li.currency {
    padding: 0; } }

.tbl-list > li.currency > .dropdown-toggle {
  cursor: pointer;
  position: relative;
  font-weight: bold;
  line-height: normal;
  font-size: 18px; }
  @media screen and (max-width: 967px) {
    .tbl-list > li.currency > .dropdown-toggle {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .tbl-list > li.currency > .dropdown-toggle {
      padding: 0;
      font-size: 12px; } }

/* Swatches Styles */
.swatch {
  margin: 0 0 10px; }

.swatch {
  float: left;
  width: 100%; }

.swatch .header {
  margin: 0px 0 10px; }

.swatch input {
  display: none; }

.swatch .swatch-element.color.available {
  box-shadow: inherit; }

.swatch .swatch-element.soldout {
  margin-right: 10px; }

.swatch .swatch-element.color label {
  width: 80px;
  height: 80px; }

.swatch .swatch-element.color {
  background: none !important;
  border: none; }

.swatch-element.color label {
  padding: 0; }

.summary .product-size .swatch-element.color label {
  width: 30px;
  height: 30px; }

.color.swatch-element label {
  padding: 0; }

.swatch .swatch-element {
  -webkit-transform: translateZ();
  -webkit-font-smoothing: antialiased;
  position: relative; }

.crossed-out {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.swatch .swatch-element .crossed-out {
  display: none; }

.swatch .swatch-element.soldout .crossed-out {
  display: block; }

.swatch .swatch-element.color.soldout input:checked + label.white-tick:before {
  content: none; }

.swatch .swatch-element.soldout label {
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6; }

.swatch .tooltip {
  text-align: center;
  bottom: 100%;
  padding: 10px;
  position: absolute;
  width: 100px;
  margin-bottom: 15px;
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  z-index: 10000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: -36px; }

.swatch .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%; }

.swatch .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 0%;
  right: 0;
  margin: auto;
  position: absolute;
  width: 0; }

.swatch .swatch-element:hover .tooltip {
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px); }

.swatch.error {
  background-color: #00305f !important;
  color: #333333 !important;
  padding: 1em;
  border-radius: 5px; }

.swatch.error p {
  margin: 0.7em 0; }

.swatch.error p:first-child {
  margin-top: 0; }

.swatch.error p:last-child {
  margin-bottom: 0; }

.swatch.error code {
  font-family: monospace; }

.swatch .selector-wrapper:after {
  background: none; }

.swatch .tooltip {
  background: #b10a0a;
  color: #ffffff;
  display: none; }

.swatch .tooltip:after {
  border-top: solid #b10a0a 10px; }

.swatch label {
  float: left;
  text-align: center; }

.swatch .swatch-element.color.white input:checked + label:before {
  color: #000; }

.swatch .swatch-element {
  margin-bottom: 10px; }

.swatch input:checked + label, .swatch label:hover {
  border: 2px solid #934d4d; }

.swatch .header {
  float: left;
  width: 100%;
  color: #b10a0a; }

.swatch-section {
  float: left; }
  @media screen and (max-width: 967px) {
    .swatch-section {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .swatch-section {
      width: 60%; } }

.swatch .swatch-element {
  display: inline-table;
  width: min-content;
  vertical-align: top; }

.swatch .swatch-element.available {
  margin-right: 10px; }
  @media screen and (max-width: 967px) {
    .swatch .swatch-element.available {
      margin-right: 5px; } }

.swatch-element label {
  padding: 0px 18px;
  line-height: 35px;
  cursor: pointer;
  font-weight: bold !important;
  color: #b10a0a; }
  @media screen and (max-width: 967px) {
    .swatch-element label {
      padding: 0 15px; } }

.swatch label {
  margin: 0 0px 3px 0;
  border: 2px solid #36342f; }
  @media screen and (max-width: 967px) {
    .swatch label {
      margin: 0 0px 3px 0; } }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.site-header__logo img.retina-logo, .is-sticky .sticky-logo img.retina-footer-logo, .footer-logo img.retina-footer-logo {
  display: none; }

.site-header__logo img {
  max-height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%); }

.site-header__logo a {
  padding: 0px;
  display: inline-block; }
  @media screen and (max-width: 967px) {
    .site-header__logo a {
      padding: 0px; } }

.site-header__logo {
  position: relative;
  height: 150px; }
  @media screen and (max-width: 767px) {
    .site-header__logo {
      text-align: left;
      width: 50%;
      height: auto; } }

.copyright {
  float: left; }
  @media screen and (max-width: 767px) {
    .copyright {
      font-size: 13px;
      float: none;
      padding: 7px 0 15px 0; } }

footer .input-group .btn, footer .input-group .btn--secondary, footer .input-group .btn--tertiary, footer .input-group input[type="submit"] {
  height: auto;
  border-radius: 0;
  padding: 15px 14px;
  float: right; }

.tbl-list > li.currency .flag-dropdown-menu {
  right: 0;
  right: 20px;
  top: 100%;
  width: 90px;
  margin-top: 12px;
  padding: 10px 0 !important;
  z-index: 9999; }

.products .hover {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%; }

.Collection-content {
  display: inline-block;
  width: 100%;
  margin-top: 20px; }

.Collection-content h3, .Collection-content .h3 {
  font-size: 16px;
  text-transform: inherit; }

.Collection-content h2, .Collection-content .h2 {
  margin-bottom: 5px; }

.home_product_detail_button {
  display: inline-block;
  width: 100%;
  text-align: center; }

.product-single__quantity .quantity-box {
  margin: 0; }

.product-single__quantity .quantity-box select {
  background: none;
  padding: 5px 15px;
  text-align: left;
  float: left;
  width: 70px;
  border-bottom: 1px solid #36342f;
  border-top: none;
  border-left: none;
  border-right: none; }

.qty-section.quantity-box {
  float: left;
  width: 100%; }

.collection_sidebar .tags .widget h4, .collection_sidebar .tags .widget .h4 {
  padding: 12px 15px 12px 30px; }

.collection_sidebar .widget h4, .collection_sidebar .widget .h4 {
  float: left;
  width: 100%;
  position: relative;
  margin-left: 0;
  text-transform: none; }

.collection_sidebar .widget h4:after, .collection_sidebar .widget .h4:after {
  position: absolute;
  left: 0;
  bottom: -10px;
  margin: 5px 0 0; }

.nav_featured_best-selling, .nav_home-collection-2 {
  position: absolute;
  top: 0;
  right: 0; }

@font-face {
  font-family: 'montserratlight';
  src: url("montserrat-light-webfont.woff2") format("woff2"), url("montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.featuredCollections a:hover img {
  -webkit-transform: scale(1.2);
  -khtml-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2); }

.featuredCollections a {
  overflow: hidden;
  display: block; }

.featuredCollections a img {
  -webkit-transition: all 1s ease-in-out 0s;
  -khtml-transition: all 1s ease-in-out 0s;
  -moz-transition: all 1s ease-in-out 0s;
  -ms-transition: all 1s ease-in-out 0s;
  -o-transition: all 1s ease-in-out 0s;
  transition: all 1s ease-in-out 0s;
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  max-width: 100%; }

.search-bar input[type="search"] {
  font-size: 18px;
  letter-spacing: 1px;
  margin: 0;
  border: none;
  float: left;
  width: 90%; }
  @media screen and (max-width: 767px) {
    .search-bar input[type="search"] {
      font-size: 12px; } }
  @media screen and (max-width: 1199px) {
    .search-bar input[type="search"] {
      width: 90%; } }
  @media screen and (max-width: 767px) {
    .search-bar input[type="search"] {
      width: 80%; } }

.drawer--top {
  width: 50%;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  @media screen and (max-width: 1199px) {
    .drawer--top {
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .drawer--top {
      width: 98%; } }
  .js-drawer-open-top .drawer--top {
    display: block; }

.search-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 55px; }

.search-bar__table {
  float: left;
  width: 100%; }

.supports-fontface .search-bar__icon-cell {
  width: 40px;
  float: left; }

#SearchDrawer span.notify {
  float: right;
  margin-top: 10px;
  display: none; }

.drawer--top .search-bar__table .search-bar__form {
  float: left;
  width: 100%;
  margin: 0;
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  left: 50%; }

.search-bar__form button {
  font-size: 18px;
  padding: 0 20px;
  border: none;
  line-height: 55px;
  height: 55px;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0; }
  @media screen and (max-width: 967px) {
    .search-bar__form button {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .search-bar__form button {
      position: static;
      margin: 0;
      line-height: normal;
      font-size: 18px; } }

.search-bar__form {
  float: left;
  width: 100%;
  border: 1px solid #36342f;
  margin: 0;
  position: relative; }

.search-bar__input {
  background: none;
  border: none;
  width: 100%;
  padding: 0 15px;
  margin: 0 !important;
  height: 55px; }

#SearchDrawer .remove-button button {
  display: none;
  background: #934d4d;
  padding: 0px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  font-size: 14px; }

.drawer--top .search-bar__icon-button {
  z-index: 99999;
  position: relative;
  cursor: pointer;
  font-size: 40px;
  display: inline-block;
  position: fixed;
  top: 10px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  right: 20px; }

.search-bar__icon-button {
  background: none;
  border: none;
  font-size: 18px;
  padding: 0 20px;
  margin: 0;
  height: 55px; }

.js-drawer-open-top .drawer--top .search-bar__icon-button {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1); }

.search__results-count {
  padding: 0 10px 10px 20px;
  line-height: 50px; }

#SearchDrawer {
  outline: none;
  border: none; }

.drawer {
  z-index: 99991;
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  right: -100%;
  top: 0;
  bottom: 0;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.drawer--left, .drawer--right {
  top: 0;
  max-width: 95%; }

.drawer--left {
  width: 300px;
  left: -300px; }

.js-drawer-open-left .drawer--left {
  display: block;
  -webkit-transform: translateX(300px);
  -moz-transform: translateX(300px);
  -ms-transform: translateX(300px);
  -o-transform: translateX(300px);
  transform: translateX(300px); }

.drawer--right {
  width: 300px;
  right: -300px; }

.js-drawer-open-right .drawer--right {
  display: block;
  -ms-transform: translateX(-300px);
  -webkit-transform: translateX(-300px);
  transform: translateX(-300px); }

.drawer--right .drawer__close {
  float: right;
  height: 65px; }

.js-drawer-open-top .drawer--top {
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible; }

#PageContainer {
  -webkit-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: 60px;
  height: 60px;
  position: fixed;
  top: -60px;
  right: -60px;
  z-index: 9998; }

.drawer-page-content:before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
  zoom: 1;
  background-color: transparent\9;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 9999; }

.js-drawer-open .drawer-page-content:before {
  -webkit-transform: scale(75);
  -moz-transform: scale(75);
  -ms-transform: scale(75);
  -o-transform: scale(75); }

.drawer__title, .drawer__close {
  display: table-cell;
  vertical-align: middle; }

.drawer__title {
  font-weight: 700;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%; }

.drawer__close-button {
  background: none;
  border: 0 none;
  position: relative;
  right: -15px;
  height: 100%;
  width: 60px;
  padding: 0 20px;
  color: inherit; }

.drawer--left .drawer__close-button {
  right: auto;
  left: -15px; }

/* Blog Page */
.blog-design-4 .blogs-sub-title {
  float: left;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px; }

.blog-design-4 .blog-date, .blog-design-4 .comments-count, .blog-design-4 .author {
  margin-bottom: 10px; }

.blog-design-4 .blog-date, .blog-design-4 .comments-count {
  margin-top: 0; }
  @media screen and (max-width: 967px) {
    .blog-design-4 .blog-date, .blog-design-4 .comments-count {
      padding-right: 0px;
      margin-right: 0px; } }

.blog-design-4 .comments-count {
  color: #5a574f; }

.blog-design-4 .blog-tag {
  margin-bottom: 0px;
  color: #5a574f; }

.blog-design-4 .blog-tag:hover {
  color: #b10a0a; }

.blog-design-4 .article {
  margin-bottom: 20px;
  float: left;
  width: 100%;
  padding: 0 15px; }

.blog-design-4 .article-detail p {
  margin: 0; }

.blog-design-4 .article-detail {
  float: left;
  width: 65%;
  margin-left: 5%; }

.blog-design-4 .article-detail h5, .blog-design-4 .article-detail .h5 {
  letter-spacing: 0;
  margin-bottom: 7px;
  text-transform: inherit; }
  @media screen and (max-width: 967px) {
    .blog-design-4 .article-detail h5, .blog-design-4 .article-detail .h5 {
      font-size: 14px; } }

.blog-design-4 .artical-image {
  float: left;
  width: 30%; }

.blog-design-4 .blog-detail p {
  margin-bottom: 15px; }

.blog-design-4 .blog-btn a i {
  margin-left: 5px; }

.blog-design-4 .recent_article li {
  float: left;
  width: 100%;
  margin-bottom: 7px; }

.blog-design-4 .home-blog-content.blog-detail h4, .blog-design-4 .home-blog-content.blog-detail .h4 {
  text-transform: inherit;
  letter-spacing: 0;
  margin-bottom: 8px; }

.blog-design-4 .home-blog-content.blog-detail h4 a, .blog-design-4 .home-blog-content.blog-detail .h4 a, .blog-design-4 .article-detail h5 a, .blog-design-4 .article-detail .h5 a {
  color: #b10a0a; }

.blog-design-4 .home-blog-content.blog-detail h4 a:hover, .blog-design-4 .home-blog-content.blog-detail .h4 a:hover, .blog-design-4 .article-detail h5 a:hover, .blog-design-4 .article-detail .h5 a:hover {
  color: #544633; }

.blog-design-4 .home-blog-content.blog-detail .blog-date, .blog-design-4 .blog-date {
  color: #5a574f; }

.blog-design-4 .home-blog-content.blog-detail .author, .blog-design-4 .author {
  color: #5a574f; }

.blog-design-4 .home-blog-content.blog-detail .author {
  color: #5a574f; }

.blog-design-4 .widget.recent_article, .blog-design-4 .widget.widget_categories, .widget.widget-blog.tweetbox {
  float: left;
  width: 100%; }

.blog-design-4 .widget.widget_categories ul li {
  float: left;
  width: 100%; }

.blog-design-4 .blog-detail {
  padding: 40px 40px 25px 40px;
  float: left;
  width: 100%;
  text-align: center; }
  @media screen and (max-width: 1199px) {
    .blog-design-4 .blog-detail {
      padding: 20px 20px 15px 20px; } }

.blog-design-4 .blog-description {
  float: left;
  width: 100%;
  border-top: none;
  border-radius: px; }

.blog-design-4 .blog-btn .btn, .blog-design-4 .blog-btn .btn--secondary, .blog-design-4 .blog-btn .btn--tertiary, .blog-design-4 .blog-btn input[type="submit"] {
  padding: 10px 20px; }

.blog-design-4 .article .blog-design-4 .home-blog-content.blog-detail i {
  font-style: normal;
  margin-right: 5px;
  color: #544633; }

.blog-design-4 .home-blog-content.blog-detail .blog-date, .blog-design-4 .blog-date i {
  font-style: normal; }

.blog-design-4 .recent_article li {
  float: left;
  padding: 12px 0 12px 0px;
  border-bottom: 1px dashed #36342f;
  float: left;
  width: 100%; }

.blog-design-4 p.blog-date {
  width: 100%;
  margin-bottom: 10px; }

.blog-design-4 .blog-section {
  display: inline-block;
  width: auto;
  margin: 0;
  float: left;
  width: 100%; }

.blog-design-4 .home-blog-content.blog-detail .author i {
  padding-right: 5px;
  color: #544633; }

.blog-design-4.blog-detail-section .blog-description {
  padding: 40px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .blog-design-4.blog-detail-section .blog-description {
      padding: 20px; } }

.blog-design-4 .article-img a {
  position: relative;
  display: inline-block; }

.blog-design-4 .article-img a::before, .blog-design-4 .article-img a::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #000;
  opacity: 0.25;
  top: 0; }

.blog-design-4 .article-img a::before {
  left: 0;
  top: 0; }

.blog-design-4 .article-img a::after {
  right: 0;
  bottom: 0;
  top: auto; }

.blog-design-4 .article:hover .article-img a::before, .blog-design-4 .article:hover .article-img a::after {
  height: 0;
  width: 0; }

.blog-design-4 .widget.widget_categories ul li {
  float: left;
  width: 100%;
  margin-bottom: 15px; }

.blog-design-4 .widget.widget_categories ul li {
  margin-top: 0px;
  position: relative;
  border-bottom: 1px dashed #36342f;
  float: left;
  width: 100%;
  margin-bottom: 0px;
  padding: 10px 0 10px 20px; }

.blog-design-4 .widget.widget_categories ul li a {
  text-transform: capitalize; }

.blog-design-4 .widget.widget_categories ul li::before {
  position: absolute;
  font-family: fontawesome;
  left: 0;
  top: 20px;
  color: #934d4d; }

.blog-design-4 .widget.widget_categories ul li:last-child {
  border-bottom: none; }

.blog-design-4 .home-blog-image a {
  display: block; }

/* Blog Detail Page */
.blog_section_detail #comments form .grid p.grid__item, .blog_section_detail #comments form .grid--rev p.grid__item, .blog_section_detail #comments form .grid--full p.grid__item {
  width: 49%;
  margin-right: 2%;
  padding: 0; }
  @media screen and (max-width: 567px) {
    .blog_section_detail #comments form .grid p.grid__item, .blog_section_detail #comments form .grid--rev p.grid__item, .blog_section_detail #comments form .grid--full p.grid__item {
      width: 100%;
      margin: 0; } }

.blog_section_detail #comments form .grid p.grid__item:nth-child(2n), .blog_section_detail #comments form .grid--rev p.grid__item:nth-child(2n), .blog_section_detail #comments form .grid--full p.grid__item:nth-child(2n) {
  margin-right: 0; }

.blog_section_detail {
  float: left;
  width: 100%;
  padding: 0; }

.blog_section_detail .social-sharing {
  float: none; }

.blog_section_detail .social-sharing label {
  float: none; }

.blog_section_detail .social-sharing a {
  line-height: normal;
  height: auto;
  float: none; }

.blog_section_detail .blog-tag {
  float: left;
  width: 100%;
  margin-bottom: 30px; }

.product-container:hover .product-additional img {
  visibility: hidden; }

.product-container .product-additional img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.product-container .reveal .hidden-feature_img {
  position: absolute;
  top: 50%;
  visibility: hidden;
  left: 0px;
  right: 0;
  margin: auto;
  opacity: 0;
  -webkit-transform: scale(0, 1);
  -moz-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.product-container:hover .reveal .hidden-feature_img {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.toolbar {
  float: left;
  width: 100%;
  padding: 0px;
  margin-bottom: 30px; }

@media screen and (max-width: 967px) {
  .toolbar .view-mode {
    width: 20%; } }

.sorting-section {
  width: 35%; }

.pages_list {
  width: 40%; }

.filter-show .btn, .filter-show .btn--secondary, .filter-show .btn--tertiary, .filter-show input[type="submit"] {
  position: relative; }

.filter-sortby input {
  display: none; }

.filter-sortby {
  text-align: right; }
  @media screen and (max-width: 767px) {
    .filter-sortby {
      text-align: left; } }

.filter-sortby label, .filter-show label {
  display: inline-block;
  margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .filter-sortby label, .filter-show label {
      display: none; } }

.toolbar .view-mode {
  float: left; }

.toolbar .view-mode a {
  float: left;
  display: inline-block;
  margin-right: 7px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background: #dcdcdc;
  color: #fff; }

.toolbar .view-mode a span {
  display: inline-block;
  padding: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  float: left;
  border-radius: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 967px) {
    .toolbar .view-mode a span {
      width: 42px;
      height: 42px;
      line-height: 42px; } }

.toolbar .view-mode a:hover span, .toolbar .view-mode a.active span {
  background: #934d4d;
  color: #fff; }

.filter_right {
  float: right; }
  @media screen and (max-width: 767px) {
    .filter_right {
      float: left; } }

@media screen and (max-width: 767px) {
  .filter-sortby {
    float: left;
    width: 50%; } }
@media screen and (max-width: 567px) {
  .filter-sortby {
    float: left;
    width: 100%; } }

.filter-sortby {
  position: relative; }

.filter-show, .view-mode {
  position: relative;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .filter-show, .view-mode {
      display: none; } }

.filter-sortby, .sorting-section, .pages_list {
  display: inline-block; }

.filter-show {
  text-align: right; }

.sorting-section ul, .sorting-section {
  width: 35%; }
  @media screen and (max-width: 1599px) {
    .sorting-section ul, .sorting-section {
      width: 65%; } }
  @media screen and (max-width: 767px) {
    .sorting-section ul, .sorting-section {
      width: 100%; } }

.sorting-section .btn:hover:after, .sorting-section .btn--secondary:hover:after, .sorting-section .btn--tertiary:hover:after, .sorting-section input[type="submit"]:hover:after, .filter-show .pages_list .btn:hover:after, .filter-show .pages_list .btn--secondary:hover:after, .filter-show .pages_list .btn--tertiary:hover:after, .filter-show .pages_list input[type="submit"]:hover:after, .sorting-section .btn:active:after, .sorting-section .btn--secondary:active:after, .sorting-section .btn--tertiary:active:after, .sorting-section input[type="submit"]:active:after, .filter-show .pages_list .btn:active:after, .filter-show .pages_list .btn--secondary:active:after, .filter-show .pages_list .btn--tertiary:active:after, .filter-show .pages_list input[type="submit"]:active:after, .sorting-section .btn:focus:after, .sorting-section .btn--secondary:focus:after, .sorting-section .btn--tertiary:focus:after, .sorting-section input[type="submit"]:focus:after, .filter-show .pages_list .btn:focus:after, .filter-show .pages_list .btn--secondary:focus:after, .filter-show .pages_list .btn--tertiary:focus:after, .filter-show .pages_list input[type="submit"]:focus:after {
  border-bottom: 7px solid #fff;
  border-top: none; }

.sorting-section.open .btn:after, .sorting-section.open .btn--secondary:after, .sorting-section.open .btn--tertiary:after, .sorting-section.open input[type="submit"]:after, .filter-show .pages_list.open .btn:after, .filter-show .pages_list.open .btn--secondary:after, .filter-show .pages_list.open .btn--tertiary:after, .filter-show .pages_list.open input[type="submit"]:after {
  border-bottom: 7px solid #fff;
  border-top: none; }

.filter-show .btn:after, .filter-show .btn--secondary:after, .filter-show .btn--tertiary:after, .filter-show input[type="submit"]:after, .sorting-section .btn:after, .sorting-section .btn--secondary:after, .sorting-section .btn--tertiary:after, .sorting-section input[type="submit"]:after {
  border-bottom: none;
  transform: translate(0);
  left: auto;
  content: "";
  width: 0;
  height: 0;
  float: left;
  border-top: 7px solid #ffffff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  position: absolute;
  right: 17px;
  top: 17px; }
  @media screen and (max-width: 967px) {
    .filter-show .btn:after, .filter-show .btn--secondary:after, .filter-show .btn--tertiary:after, .filter-show input[type="submit"]:after, .sorting-section .btn:after, .sorting-section .btn--secondary:after, .sorting-section .btn--tertiary:after, .sorting-section input[type="submit"]:after {
      right: 7px; } }

.filter-show .btn::before, .filter-show .btn--secondary::before, .filter-show .btn--tertiary::before, .filter-show input[type="submit"]::before, .filter-show .btn::after, .filter-show .btn--secondary::after, .filter-show .btn--tertiary::after, .filter-show input[type="submit"]::after {
  background: transparent; }

.sorting-section .btn, .sorting-section .btn--secondary, .sorting-section .btn--tertiary, .sorting-section input[type="submit"], .filter-show .btn, .filter-show .btn--secondary, .filter-show .btn--tertiary, .filter-show input[type="submit"] {
  width: 100%;
  text-align: left;
  padding: 13px 17px;
  border-radius: 30px;
  background: #dcdcdc;
  color: #fff; }

.sorting-section .btn, .sorting-section .btn--secondary, .sorting-section .btn--tertiary, .sorting-section input[type="submit"], .filter-show .btn, .filter-show .btn--secondary, .filter-show .btn--tertiary, .filter-show input[type="submit"] {
  font-size: 14px;
  z-index: 1; }

.toolbar .dropdown-menu {
  border-radius: 0 0 0 0; }

.featured-tag {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99; }

.product_right_tag {
  z-index: 2; }

.product_right_tag .badge--new {
  background: #11b00d;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2; }

.product_right_tag .offer-price {
  float: right;
  padding: 10px;
  background: #11b00d;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 2;
  text-align: center;
  line-height: 1.1; }

.product_right_tag .badge--new {
  top: 0; }

.product_right_tag.offer_exist .badge--new {
  top: 60px; }

.product_right_tag .offer-price {
  top: 0px; }

.products:hover .badge.badge--sale {
  width: 100%; }

.badge.badge--sale {
  float: left;
  margin: 3px 0 0 3px;
  webkit-transition: width 0.1s cubic-bezier(0.86, 0, 0.07, 1) 0.05s;
  -moz-transition: width 0.1s cubic-bezier(0.86, 0, 0.07, 1) 0.05s;
  -ms-transition: width 0.1s cubic-bezier(0.86, 0, 0.07, 1) 0.05s;
  -o-transition: width 0.1s cubic-bezier(0.86, 0, 0.07, 1) 0.05s;
  transition: width 0.1s cubic-bezier(0.86, 0, 0.07, 1) 0.05s; }

.social-sharing {
  float: right;
  text-align: center;
  padding: 0px 0;
  margin: 0px 0 0; }
  @media screen and (max-width: 1199px) {
    .social-sharing {
      float: left;
      width: 100%; } }

.social-sharing a {
  padding: 0 0px;
  display: inline-block;
  text-align: center; }

#promo-carousel li {
  position: relative;
  transition: all 900ms ease 0s;
  -webkit-transition: all 900ms ease 0s; }

#promo-carousel li:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

#promo-carousel li img {
  transition: all 900ms ease 0s;
  -webkit-transition: all 900ms ease 0s;
  margin-bottom: 10px; }

#promo-carousel li h5, #promo-carousel li .h5 {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0; }

/* Currency */
.tbl-list > li.currency {
  position: relative;
  float: left; }

.tbl-list > li.currency > .dropdown-toggle .fa {
  margin: 0 0 0 3px; }

.tbl-list > li.currency .flag-dropdown-menu {
  left: 0;
  right: 20px;
  top: 100%;
  width: 90px;
  margin-top: 12px;
  padding: 10px 0 !important; }

.menu-middle-section ul li.currency a {
  padding-right: 0; }

.menu-middle-section ul li.currency .flag-dropdown-menu li a {
  padding: 7px 15px; }

.menu-middle-section ul li.currency:hover .flag-dropdown-menu {
  opacity: 1;
  visibility: visible;
  display: block;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1); }

.flag-dropdown-menu li a i {
  margin-top: 2px;
  margin-right: 7px;
  float: left; }
  @media screen and (max-width: 567px) {
    .flag-dropdown-menu li a i {
      margin-right: 5px; } }

.flag-dropdown-menu li a span {
  float: right;
  color: #b10a0a;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.flag-dropdown-menu li a:hover span {
  color: #934d4d; }

.flag-dropdown-menu {
  text-align: left;
  width: 90px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  -khtml-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0;
  -khtml-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  padding: 0;
  margin-bottom: 0;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.15);
  -ms-transform: 2px 3px 5px 2px rgba(0, 0, 0, 0.15);
  -o-transform: 2px 3px 5px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  @media screen and (max-width: 767px) {
    .flag-dropdown-menu {
      right: auto; } }

.tbl-list > li.currency .flag-dropdown-menu li a {
  line-height: 1.2;
  padding: 6px 17px !important;
  border: none;
  font-size: 14px !important;
  float: left; }
  @media screen and (max-width: 767px) {
    .tbl-list > li.currency .flag-dropdown-menu li a {
      font-size: 13px !important;
      padding: 10px 10px !important; } }
  @media screen and (max-width: 567px) {
    .tbl-list > li.currency .flag-dropdown-menu li a {
      font-size: 13px !important;
      padding: 10px 10px 10px 10px !important; } }

@media screen and (max-width: 767px) {
  .tbl-list > li.currency .flag-dropdown-menu li:last-child a {
    margin-right: 0; } }

.tbl-list > li.currency:hover .flag-dropdown-menu, .tbl-list > li.currency.open .flag-dropdown-menu {
  opacity: 1;
  visibility: visible;
  display: block;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1); }

.tbl-list > li.currency:hover .flag-dropdown-menu::before, .tbl-list > li.currency.open .flag-dropdown-menu::before {
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  right: 0;
  top: -15px;
  width: 100%; }

.header-bar .wrapper, .site-header .wrapper {
  max-width: 100%; }

.site-footer .social-icons {
  padding: 0;
  display: inline-block; }
  @media screen and (max-width: 967px) {
    .site-footer .social-icons {
      margin-left: 5px; } }
  @media screen and (max-width: 767px) {
    .site-footer .social-icons {
      margin-left: 0; } }

.site-footer .social-icons li span {
  margin-right: 0;
  margin-left: 0; }

.site-footer .social-icons li a:hover {
  background: #934d4d;
  color: #fff; }

.site-footer .social-icons li a span {
  margin-bottom: 4px;
  float: left;
  border: 1px solid #36342f;
  font-size: 16px;
  padding: 0; }

.menu-middle-section ul {
  list-style: none;
  padding: 0;
  float: right; }
  @media screen and (max-width: 767px) {
    .menu-middle-section ul {
      text-align: center;
      float: none; } }

.menu-middle-section ul li {
  float: left;
  list-style: none; }
  @media screen and (max-width: 767px) {
    .menu-middle-section ul li {
      float: none;
      display: inline-block;
      text-align: center; } }

.menu-middle-section li a {
  float: left;
  padding: 7px 0 7px 15px;
  font-size: 13px;
  text-transform: uppercase; }
  @media screen and (max-width: 967px) {
    .menu-middle-section li a {
      padding-right: 5px; } }

.menu-middle-section li a i {
  margin-right: 5px; }

.menu-middle-section li.logout-link i {
  float: left;
  padding: 7px 0 7px 15px; }

.menu-middle-section li.logout-link a {
  float: left;
  padding: 7px 0px 7px 5px; }

.menu-middle-section li span.currency_code i {
  margin-right: 5px; }

#currencies-picker {
  margin: 0; }

.header-bar__module.cart .wrapper-top-cart, .header-bar__module.cart .mini-cart-icon, .header-bar__search form .btn, .header-bar__search form .btn--secondary, .header-bar__search form .btn--tertiary, .header-bar__search form input[type="submit"], .header-bar__search {
  float: none; }

.header-bar__module.cart .mini-cart-icon i {
  width: auto;
  background: none; }

.site-header__logo {
  margin: 0; }
  @media screen and (max-width: 767px) {
    .site-header__logo {
      margin: 0 auto; } }

.site-header__logo a.logo_name {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media screen and (max-width: 767px) {
    .site-header__logo a.logo_name {
      right: 0; } }

.header-bar__module.cart .mini-cart-icon, .header-bar__module.cart .mini-cart-icon i {
  margin-right: 0; }

.header-bar__search form .btn, .header-bar__search form .btn--secondary, .header-bar__search form .btn--tertiary, .header-bar__search form input[type="submit"] {
  padding: 28px 17px !important; }

.header-bar__search form {
  margin: 0; }

.header-bar .social-icons li, .header-bar .social-icons li a {
  float: left;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .header-bar .social-icons li, .header-bar .social-icons li a {
      float: none; } }

.header-bar .social-icons li {
  display: inline-block; }

.header-bar__search form .header-bar__search-input {
  display: none; }

.header-bar__search form .btn, .header-bar__search form .btn--secondary, .header-bar__search form .btn--tertiary, .header-bar__search form input[type="submit"] {
  border: none; }

.header-bar__module.cart .baskettop {
  float: none; }

.header-bar__module.cart .baskettop a.icon-cart-arrow {
  position: relative; }

.header-bar__module.cart .baskettop a.icon-cart-arrow + #slidedown-cart {
  width: 350px;
  position: absolute;
  right: 0;
  top: 100%;
  -webkit-box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.15);
  z-index: 9999999;
  padding: 0px;
  text-align: left;
  line-height: 1.7; }
  @media screen and (max-width: 1199px) {
    .header-bar__module.cart .baskettop a.icon-cart-arrow + #slidedown-cart {
      right: 0; } }
  @media screen and (max-width: 767px) {
    .header-bar__module.cart .baskettop a.icon-cart-arrow + #slidedown-cart {
      left: auto;
      right: 0;
      margin: auto;
      padding: 10px 0; } }
  @media screen and (max-width: 567px) {
    .header-bar__module.cart .baskettop a.icon-cart-arrow + #slidedown-cart {
      width: 280px; } }

.header-bar__module.cart .mini-cart-icon i {
  height: auto;
  line-height: normal;
  float: none;
  display: inline-block;
  padding: 28px 17px; }

.is-sticky .header-bar__search .btn, .is-sticky .header-bar__search .btn--secondary, .is-sticky .header-bar__search .btn--tertiary, .is-sticky .header-bar__search input[type="submit"] {
  position: inherit; }

.is-sticky .grid__item:nth-child(2n) {
  float: right; }

.is-sticky .site-header__logo {
  display: none;
  border: none; }

.is-sticky .menu-right-section {
  position: absolute;
  right: 15%;
  bottom: 0;
  z-index: 99; }

.is-sticky .menu-right li a {
  padding: 16px 15px;
  font-size: 16px; }

.is-sticky .header_top {
  display: none; }

.site-nav {
  list-style: none;
  margin-left: auto;
  margin-right: auto; }

.site-nav li:first-child a:before {
  content: none;
  width: 0; }

.site-nav li a.new:after, .site-nav li a.sale:after, .site-nav li a.offer:after {
  font-size: 9px;
  padding: 1px 7px 0;
  border-radius: 50px;
  line-height: 16px; }

.site-nav li a.new:after {
  content: 'New';
  background: #11b00d;
  color: #ffffff;
  padding: 3px 5px; }

.site-nav li a.sale:after {
  content: 'Sale';
  background: #11b00d;
  color: #ffffff;
  padding: 3px 5px; }

.site-nav li a.offer:after {
  content: 'Offer';
  background: #11b00d;
  color: #ffffff;
  padding: 3px 5px; }

/* Mega Menu */
.header-mobile {
  margin-top: 0px;
  display: none; }
  @media screen and (max-width: 967px) {
    .header-mobile {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle; } }
  @media screen and (max-width: 767px) {
    .header-mobile {
      vertical-align: middle; } }

.desktop-megamenu .site-nav-dropdown {
  list-style: none;
  text-align: left;
  position: absolute;
  top: auto;
  width: 215px;
  z-index: 999;
  padding: 0;
  margin-bottom: 0;
  border: 1px solid transparent; }
  @media screen and (max-width: 1199px) {
    .desktop-megamenu .site-nav-dropdown {
      width: 182px; } }

.desktop-megamenu .site-nav li.mega-menu:hover .site-nav-dropdown, .site-nav li.mega-menu:hover .site-nav-dropdown, .site-nav li.dropdown:hover .site-nav-dropdown {
  overflow: visible;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.desktop-megamenu .site-nav li.mega-menu .site-nav-dropdown {
  width: 100%;
  left: 0;
  right: 0;
  border: 0;
  margin: auto; }
  @media screen and (max-width: 1599px) {
    .desktop-megamenu .site-nav li.mega-menu .site-nav-dropdown {
      width: 98%; } }

.desktop-megamenu .site-nav-dropdown {
  text-align: left;
  visibility: hidden;
  overflow: hidden;
  -webkit-transform: scaleY(0);
  -khtml-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0;
  -khtml-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
  width: 215px;
  z-index: 999;
  padding: 0;
  margin-bottom: 0;
  border: 1px solid transparent; }

.nav-bar-mobile .site-nav-dropdown .dropdown {
  display: none; }

.cbp-spmenu {
  background: #181616; }

.site-nav > li.dropdown > p.toogleClick:before {
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #dcdcdc;
  display: block;
  margin-left: 7px;
  text-indent: -999em;
  cursor: pointer;
  position: absolute;
  top: 19px;
  right: 15px;
  margin: 0;
  padding: 0;
  background: none;
  z-index: 2; }

.site-nav > li.dropdown > p.toogleClick.mobile-toggle-open:before {
  border-bottom-color: #dcdcdc; }

.cbp-spmenu .site-nav > li > a {
  border-bottom: 1px solid #36342f; }

.site-nav-dropdown {
  float: left;
  width: 100%; }
  @media screen and (max-width: 967px) {
    .site-nav-dropdown {
      display: none; } }

.site-nav-dropdown li {
  float: left;
  width: 100%; }

.menu-tool {
  width: 95%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 1199px) {
    .menu-tool {
      width: 100%; } }

.site-nav {
  padding: 0;
  list-style: none;
  text-align: center; }

.site-nav li {
  margin: 0; }

.site-nav ul {
  list-style: none;
  padding: 0; }

.site-nav a {
  display: block;
  padding: 0 12px;
  text-decoration: none;
  /* white-space: nowrap; */ }
  @media screen and (max-width: 1599px) {
    .site-nav a {
      font-size: 14px; } }

.site-nav > li {
  display: inline-block;
  float: none;
  text-align: center;
  list-style: none; }

.site-nav li > a i {
  display: inline-block;
  margin-left: 7px; }

.site-nav > li > a {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  padding: 24px 20px; }
  @media screen and (max-width: 1599px) {
    .site-nav > li > a {
      font-size: 14px;
      padding: 24px 10px; } }
  @media screen and (max-width: 1199px) {
    .site-nav > li > a {
      font-size: 14px; } }
  @media screen and (max-width: 967px) {
    .site-nav > li > a {
      padding: 24px 3px;
      font-size: 14px;
      letter-spacing: 0px; } }

.site-nav > li:first-child > a {
  margin-left: 0; }

.site-nav-dropdown li a {
  padding: 8px 30px 8px 20px;
  position: relative; }

.site-nav-dropdown ul {
  left: 100%;
  top: -1px;
  display: none;
  padding-left: 0;
  list-style: none; }

li.dropdown:last-child .site-nav-dropdown ul {
  left: auto;
  right: 100%; }

.site-nav > .dropdown:hover > ul, .site-nav > .dropdown.open > ul {
  display: block; }

.site-nav > li > ul > .dropdown:hover > ul, .site-nav > li > ul > .dropdown.open > ul {
  display: block; }

.site-nav > li > ul > li > ul > .dropdown:hover > ul, .site-nav > li > ul > li > ul > .dropdown.open > ul {
  display: block; }

.site-nav > li > ul > li > ul > li > ul > .dropdown:hover > ul,
.site-nav > li > ul > li > ul > ul > li > .dropdown.open > ul {
  display: block; }

.site-nav li.dropdown .icon-dropdown {
  height: 0;
  width: 0;
  border: 4px solid transparent;
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  cursor: pointer; }

.site-nav-dropdown li.dropdown .icon-dropdown {
  position: absolute;
  top: 16px;
  right: 20px; }

.site-nav-dropdown li:hover > a {
  padding-left: 40px; }

.site-nav-dropdown li:hover > a:before {
  content: "";
  width: 15px;
  height: 1px;
  position: absolute;
  top: 18px;
  left: 20px; }

.site-nav > li.mega-menu {
  position: static; }

.site-nav li.dropdown:hover.mega-menu > a > .icon-dropdown {
  border-top-color: #fff;
  opacity: 1; }

.sub-mega-menu .parent-link {
  border-bottom: 1px solid #3d4549;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 15px; }

.sub-mega-menu .parent-link a {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  display: inline-block;
  padding: 0;
  margin: 0; }

.sub-mega-menu .parent-link > span.up {
  text-indent: -999em;
  display: block;
  background: url(icon-menu-up.png) no-repeat;
  opacity: 0.5;
  width: 9px;
  height: 6px;
  position: absolute;
  left: 50%;
  top: 6px;
  cursor: pointer; }

.sub-mega-menu .parent-link > span.up:hover {
  opacity: 1; }

.sub-mega-menu .parent-link > span {
  padding: 0 10px; }

.site-nav-dropdown .col-1 {
  float: left;
  width: 53%; }

.site-nav-dropdown .col-2 {
  float: left;
  width: 27%; }

.site-nav-dropdown .col-3 {
  float: left;
  width: 20%; }

.site-nav-dropdown .col-1 .inner {
  float: left;
  width: 33.3%;
  padding-right: 3%; }

.site-nav-dropdown .col-1 .inner > a {
  padding: 0;
  margin-bottom: 15px; }

.site-nav-dropdown .col-1 .inner .dropdown {
  margin-bottom: 30px;
  float: left;
  width: 100%; }

.site-nav-dropdown .col-2 {
  padding: 0 0% 0 0; }

.site-nav-dropdown .col-3 {
  padding: 5px 0; }

.site-nav-dropdown .col-3 a {
  padding: 0; }

.site-nav-dropdown .col-wide img, .site-nav-dropdown img {
  border-radius: 0px; }

.site-nav-dropdown .col-wide {
  width: 100%;
  float: left;
  padding: 0 0px; }

.site-nav-dropdown .col-wide .bottom_left a, .site-nav-dropdown .col-wide .bottom_right a {
  padding: 0; }

.site-nav-dropdown .col-wide .bottom_left, .site-nav-dropdown .col-wide .bottom_right {
  width: 49%;
  float: left; }
  @media screen and (max-width: 767px) {
    .site-nav-dropdown .col-wide .bottom_left, .site-nav-dropdown .col-wide .bottom_right {
      margin-bottom: 10px;
      width: 100%;
      margin-right: 0; } }

.site-nav-dropdown .col-wide .bottom_right {
  margin-right: 0; }

.site-nav-dropdown .col-wide .bottom_left {
  width: 49%;
  margin-right: 2%; }
  @media screen and (max-width: 767px) {
    .site-nav-dropdown .col-wide .bottom_left {
      width: 100%;
      margin-right: 0; } }

.site-nav-dropdown .col-1 .inner > a {
  font-weight: bold;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 2px; }
  @media screen and (max-width: 1599px) {
    .site-nav-dropdown .col-1 .inner > a {
      letter-spacing: 0px; } }
  @media screen and (max-width: 1199px) {
    .site-nav-dropdown .col-1 .inner > a {
      text-transform: capitalize; } }

.site-nav-dropdown .col-1 .inner p.toogleClick {
  display: none !important; }

.site-nav-dropdown .col-1 ul.dropdown {
  display: block;
  position: static;
  padding: 0; }

.site-nav-dropdown .col-1 ul.dropdown li:hover > a:before {
  left: 0;
  top: 15px; }

.site-nav-dropdown .col-1 ul.dropdown li a {
  display: inline-block;
  padding: 10px 0;
  font-size: 13px;
  width: 100%; }

.col-1 ul.dropdown li .icon-dropdown {
  margin-left: 20px; }

.site-nav-dropdown .col-1 ul.dropdown li a:hover span {
  border-bottom: 1px solid #fff; }

.site-nav-dropdown .col-1 ul.dropdown li a:hover span.icon-dropdown {
  border-bottom: 0;
  margin-top: -3px; }

.site-nav li.mega-menu > .dropdown:hover > .site-nav-dropdown,
.site-nav li.mega-menu > .dropdown.open > .site-nav-dropdown {
  display: block; }

.site-nav li.dropdown ul.site-nav-dropdown {
  padding: 20px 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px; }

.site-nav li.dropdown ul.site-nav-dropdown li a {
  padding: 5px 20px; }

.site-nav .widget-featured-product {
  float: left;
  width: 100%;
  text-align: center; }

.site-nav .widget-featured-product .widget-product .products-grid {
  margin: 0; }

.site-nav .widget-featured-product .widget-product {
  float: left;
  width: 100%;
  position: relative; }

.site-nav .widget-featured-nav .owl-prev, .site-nav .widget-featured-nav .owl-next {
  z-index: 1;
  position: absolute;
  top: 34%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%); }
  @media screen and (max-width: 967px) {
    .site-nav .widget-featured-nav .owl-prev, .site-nav .widget-featured-nav .owl-next {
      top: 24%; } }

.site-nav .widget-featured-nav .owl-next {
  right: 0; }

.site-nav .widget-featured-nav .owl-prev {
  left: 0; }

.site-nav .widget-featured-product .widget-title {
  margin-bottom: 15px; }

.site-nav .widget-featured-product .widget-title h3, .site-nav .widget-featured-product .widget-title .h3 {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 20px; }

.site-nav .widget-featured-product .products-grid .grid-item {
  width: 100%;
  padding: 0;
  margin: 0; }

.site-nav .products-grid .grid-item .product-grid-image {
  display: inline-block;
  padding: 0;
  margin-bottom: 7px; }

.site-nav .widget-featured-product .product-title {
  padding: 0; }

.site-nav .widget-featured-product .spr-badge {
  display: none; }

.site-nav .widget-featured-product .price-box {
  margin: 0; }

.site-nav .widget-featured-product .price-box .regular-product {
  margin: 0; }

.site-nav .widget-featured-product .btn i, .site-nav .widget-featured-product .btn--secondary i, .site-nav .widget-featured-product .btn--tertiary i, .site-nav .widget-featured-product input[type="submit"] i {
  margin: 0;
  font-size: 20px; }

.site-nav .widget-featured-product .btn, .site-nav .widget-featured-product .btn--secondary, .site-nav .widget-featured-product .btn--tertiary, .site-nav .widget-featured-product input[type="submit"] {
  padding: 0;
  letter-spacing: 0.02em;
  border-bottom: 1px solid transparent;
  width: 35px;
  height: 35px;
  line-height: 35px; }

.sub-mega-menu {
  display: none; }

.menu_bar_right {
  list-style: none;
  padding: 0; }

.price-box .old-price, .price-box .special-price {
  display: inline-block;
  float: none;
  width: auto; }

.price-box .old-price {
  color: #bcbcbc; }

.price-box .special-price, .site-nav .widget-featured-product .price-box .regular-product {
  color: #5a574f;
  font-size: 16px;
  margin-right: 3px; }

.menu-tool ul li.dropdown > a {
  position: relative; }

.menu-tool ul li.dropdown > a::before {
  position: absolute;
  content: "\f0d7";
  right: 0px;
  font-family: fontawesome;
  font-size: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media screen and (max-width: 967px) {
    .menu-tool ul li.dropdown > a::before {
      right: 15px; } }

/* Update 2.0.1 */
.site-nav-dropdown img {
  display: block; }

.site-nav-dropdown .col-1 {
  width: 60%; }

.site-nav-dropdown .col-1 .inner {
  float: left;
  display: inline-block;
  vertical-align: top; }

.site-nav-dropdown .container {
  float: left;
  max-width: 100%;
  width: 100%;
  padding: 40px 40px 40px 40px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px; }
  @media screen and (max-width: 1199px) {
    .site-nav-dropdown .container {
      padding: 30px 30px 30px 30px; } }

.site-nav-dropdown .container.style_3 .col-1 .inner:nth-child(3n+1), .site-nav-dropdown .container.style_2 .col-1 .inner:nth-child(3n+1), .site-nav-dropdown .container.style_1 .col-1 .inner:nth-child(3n+1) {
  clear: both; }

.site-nav-dropdown .col-1 .inner {
  padding-right: 20px; }

.site-nav-dropdown .col-2 {
  width: 40%; }

.site-nav-dropdown .style_3 .col-2, .site-nav-dropdown .style_2 .col-2 {
  padding: 0; }

.site-nav-dropdown .col-2 a {
  padding: 0;
  display: inline-block;
  max-width: 100%; }

.site-nav-dropdown .col-2 .col-left {
  width: 50%;
  padding-right: 20px;
  float: left; }

.site-nav-dropdown .col-2 .col-right {
  width: 50%;
  float: left; }

.site-nav-dropdown .style_2 .col-2 .col-left {
  padding-right: 0;
  text-align: right; }

.site-nav-dropdown .style_2 .col-2 .col-left a {
  padding: 0 6px 0px 0; }

.site-nav-dropdown .style_4 .col-1 {
  width: 100%;
  float: none; }

.site-nav-dropdown .style_4 .inner {
  width: 25%;
  padding: 0 10px; }

.site-nav-dropdown .style_4 .inner > a > img {
  padding: 15px 0 0;
  display: block; }

.site-nav-dropdown .style_1 .col-1 {
  float: left; }

.site-nav-dropdown .style_1 .col-2 {
  padding-left: 0px; }

.site-nav-dropdown .style_1 .col-2 .col-right {
  width: 100%; }

.site-nav-dropdown .style_1 .col-2 .col-right a {
  margin: 0; }

.site-nav-dropdown .style_5 .col-1 {
  width: 100%;
  float: none; }

.site-nav-dropdown .style_5 .inner {
  width: 25%;
  padding: 0 10px; }

.site-nav-dropdown .style_5 .inner > a > img {
  padding: 8px 0; }

.desktop-megamenu .site-nav-dropdown {
  text-align: left;
  visibility: hidden;
  overflow: hidden;
  -webkit-transform: scaleY(0);
  -khtml-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0;
  -khtml-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
  width: 215px;
  z-index: 999;
  padding: 0;
  margin-bottom: 0;
  border: 1px solid transparent; }

.tbl-list > li.currency .flag-dropdown-menu li {
  width: 100%;
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .tbl-list > li.currency .flag-dropdown-menu li {
      width: auto;
      float: left; } }

.tbl-list > li.currency:hover .flag-dropdown-menu, .tbl-list > li.currency.open .flag-dropdown-menu {
  display: block; }

.tbl-list > li.currency:hover .flag-dropdown-menu::before, .tbl-list > li.currency.open .flag-dropdown-menu::before {
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  right: 0;
  top: -15px;
  width: 100%; }

.tbl-list > li.currency {
  position: relative; }

.tbl-list > li.currency > .dropdown-toggle .fa {
  margin: 0 0 0 5px; }

.tbl-list > li.currency .flag-dropdown-menu {
  right: 0;
  right: 20px;
  top: 100%;
  width: 100px;
  margin-top: 0px;
  padding: 10px 0 !important; }
  @media screen and (max-width: 767px) {
    .tbl-list > li.currency .flag-dropdown-menu {
      margin-top: 0px;
      width: 75px;
      text-align: center;
      padding: 0 !important;
      right: 0;
      left: auto; } }

.shopify-section {
  padding: 0; }
  @media screen and (max-width: 767px) {
    .shopify-section {
      padding: 0; } }

.products:hover .deal-clock {
  opacity: 0; }

.products .deal-clock {
  display: inline-block;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1; }

.product_single_detail_section form {
  margin: 0;
  padding: 0; }

.product_single_detail_section .deal-clock {
  float: left;
  width: 100%;
  z-index: 1;
  position: static;
  text-align: left;
  margin-bottom: 20px; }

.product_single_detail_section .deal-clock ul {
  margin: 10px 0 0; }

.products .deal-clock ul, .product_single_detail_section .deal-clock ul {
  padding: 0;
  list-style: none;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: 0; }

.products .deal-clock ul li:first-child, .product_single_detail_section .deal-clock ul li:first-child {
  margin-left: 0; }

.products .deal-clock ul li {
  display: inline-block;
  text-align: center;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #36342f;
  background: #000;
  color: #fff;
  width: 25%;
  line-height: normal;
  padding: 10px 10px; }
  @media screen and (max-width: 1599px) {
    .products .deal-clock ul li {
      width: 25%;
      font-size: 10px;
      padding: 7px 0; } }
  @media screen and (max-width: 1199px) {
    .products .deal-clock ul li {
      width: 25%;
      font-size: 10px;
      padding: 7px 0; } }
  @media screen and (max-width: 967px) {
    .products .deal-clock ul li {
      width: 25%;
      padding: 7px 0; } }
  @media screen and (max-width: 767px) {
    .products .deal-clock ul li {
      font-size: 14px; } }

.product_single_detail_section .deal-clock ul li {
  float: left;
  text-align: center;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #36342f;
  background: #000;
  color: #fff;
  width: 10%;
  line-height: normal;
  padding: 10px 0px; }
  @media screen and (max-width: 1599px) {
    .product_single_detail_section .deal-clock ul li {
      width: 25%;
      font-size: 10px;
      padding: 7px 0; } }
  @media screen and (max-width: 1199px) {
    .product_single_detail_section .deal-clock ul li {
      width: 25%;
      font-size: 10px;
      padding: 7px 0; } }
  @media screen and (max-width: 967px) {
    .product_single_detail_section .deal-clock ul li {
      width: 25%;
      padding: 7px 0; } }
  @media screen and (max-width: 767px) {
    .product_single_detail_section .deal-clock ul li {
      font-size: 14px; } }

.products .deal-clock ul li:last-child, .product_single_detail_section .deal-clock ul li:last-child {
  margin-right: 0;
  border-right: none; }

.products .deal-clock ul li span, .product_single_detail_section .deal-clock ul li span {
  text-transform: uppercase;
  font-size: 10px;
  display: block;
  letter-spacing: 1px; }
  @media screen and (max-width: 1599px) {
    .products .deal-clock ul li span, .product_single_detail_section .deal-clock ul li span {
      letter-spacing: 0;
      font-size: 8px; } }
  @media screen and (max-width: 767px) {
    .products .deal-clock ul li span, .product_single_detail_section .deal-clock ul li span {
      font-size: 10px; } }

.products .deal-clock ul li i {
  display: block; }

.product_single_detail_section .deal-clock ul li:last-child {
  border-right: none; }

.support-section-content {
  display: inline-block;
  width: 100%;
  text-align: center; }
  @media screen and (max-width: 567px) {
    .support-section-content {
      text-align: left; } }

.support-section i {
  display: inline-block;
  margin-right: 15px;
  margin-top: 0px;
  font-size: 50px; }
  @media screen and (max-width: 967px) {
    .support-section i {
      font-size: 32px;
      margin-right: 7px; } }
  @media screen and (max-width: 567px) {
    .support-section i {
      float: left;
      margin-right: 15px; } }

.support-section {
  float: left;
  text-align: center;
  width: 100%;
  padding: 80px 0px 0; }

.footer-support p {
  margin-left: auto;
  margin-right: auto;
  width: 45%;
  text-align: center; }

.deal-items {
  padding: 80px 0;
  float: left;
  width: 100%; }

/*.zoomWrapper > div {  width: 64px !important; height: 64px !important; background-color: #fff!important; left: 50% !important; margin: -32px 0 0 -32px !important; top: 50% !important; -webkit-border-radius: 4px;-moz-border-radius: 4px; border-radius: 4px;z-index: 99!important;}*/
#email-modal .window-window .window-content .title b {
  font-family: Libre Baskerville; }

.list-collection-products .grid__item:hover a.btn, .list-collection-products .grid__item:hover a.btn--secondary, .list-collection-products .grid__item:hover a.btn--tertiary {
  background-color: #934d4d;
  color: #fff;
  border-color: #934d4d; }

.list-collection-products .grid__item:hover a.pickgradient:after {
  opacity: 0.4; }

.list-collection-products .grid__item a.pickgradient {
  position: relative; }

.list-collection-products .grid__item:hover a.pickgradient:before {
  opacity: 1;
  filter: alpha(opacity=100);
  margin-top: 0; }

.placeholder-svg {
  border: 1px solid #36342f; }

.header_custom p {
  margin: 0; }

.header_custom {
  padding: 12px 20px 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.8px; }
  @media screen and (max-width: 767px) {
    .header_custom {
      display: none; } }

.header_currency ul {
  list-style: none;
  padding: 0; }

.header_currency ul select {
  padding: 10px 15px;
  background: none;
  border: none; }

.header_currency ul li.currency {
  position: relative; }

.header_currency ul li.currency .selector-arrow:after {
  content: "\f107";
  font-family: 'FontAwesome';
  float: left;
  position: absolute;
  right: 5px;
  top: 0px;
  pointer-events: none; }

.header_currency ul li.currency .selector-arrow {
  position: relative;
  float: left;
  width: 100%; }

.header_currency {
  float: left;
  position: relative;
  display: block;
  padding: 32px 10px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .header_currency {
      text-align: center;
      width: 100%; } }

.header_user {
  position: relative;
  min-width: 100px;
  padding: 0 0px;
  text-align: center;
  line-height: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 967px) {
    .header_user {
      min-width: 50px; } }
  @media screen and (max-width: 767px) {
    .header_user {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 30%;
      -moz-box-flex: 1;
      -moz-flex: 1 1 30%;
      -ms-flex: 1 1 30%;
      flex: 1 1 30%;
      line-height: 60px;
      border-right: 1px solid #36342f; } }
  @media screen and (max-width: 567px) {
    .header_user {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 100%;
      -moz-box-flex: 1;
      -moz-flex: 1 1 100%;
      -ms-flex: 1 1 30%;
      flex: 1 1 100%; } }

.customer_account_links ul {
  position: absolute;
  background: red;
  top: 100%;
  line-height: 2em; }

.customer_account_links ul {
  list-style: none; }

.header-search span {
  cursor: pointer;
  font-size: 30px; }
  @media screen and (max-width: 967px) {
    .header-search span {
      font-size: 22px; } }

.header_cart {
  position: relative;
  padding: 26px 0px 26px 10px;
  float: left; }
  @media screen and (max-width: 767px) {
    .header_cart {
      position: static; } }

.header-search {
  float: left;
  padding: 31px 10px;
  position: relative; }

.header-search .header_toggle {
  position: relative;
  display: block;
  padding: 0px 0px 0;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .header-search .header_toggle {
      display: none; } }

.header_slide_element ul {
  list-style: none;
  padding: 0;
  text-align: left; }

.header_slide_element ul li {
  line-height: normal;
  padding: 7px;
  float: left;
  width: 100%; }

.header_slide_element ul li form button {
  background: none;
  border: none;
  padding: 0;
  margin: 0; }

.header_slide_element ul li a i, .header_slide_element ul li form button i {
  margin-right: 7px; }

.header_slide_element ul li.logout-link i {
  margin-right: 7px; }

.slidersearch.header_slide_element {
  padding: 20px;
  right: 0;
  z-index: 100;
  position: absolute;
  top: 0;
  width: 300px;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate(0%, -100%);
  transform: translate(0%, -100%); }
  @media screen and (max-width: 767px) {
    .slidersearch.header_slide_element {
      display: inline-block;
      position: static;
      left: 0;
      opacity: 1;
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
      width: auto;
      background: none;
      line-height: normal;
      padding: 0px; } }
  @media screen and (max-width: 567px) {
    .slidersearch.header_slide_element {
      padding: 0px;
      width: auto; } }

.header_slide_element {
  padding: 20px;
  z-index: 100;
  position: absolute;
  top: 0;
  width: 300px;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate(0%, -100%);
  transform: translate(0%, -100%); }
  @media screen and (max-width: 967px) {
    .header_slide_element {
      width: 200px; } }
  @media screen and (max-width: 767px) {
    .header_slide_element {
      padding: 7px;
      width: 100%;
      left: 0;
      -webkit-transform: translate(0%, 100%);
      transform: translate(0%, 100%); } }

.header_toggle.active + .header_slide_element {
  top: 100%;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  opacity: 1; }
  @media screen and (max-width: 767px) {
    .header_toggle.active + .header_slide_element {
      z-index: 1000; } }

.products .item-swatch {
  margin: 5px 0 0; }

.full_width_tab ul.item-swatch {
  margin: 7px 0 0; }

.option-loader .product-img, .option-loader .product-title, .option-loader .product-title, .option-loader .product-description {
  display: none; }

.quick-view.option-loader .product-detail-section {
  width: 100%;
  padding: 0;
  float: left; }

.quick-view.option-loader .product-shop.summary {
  width: 100%;
  padding: 0;
  float: left; }

.quick-view .actions {
  float: left;
  width: 100%;
  margin-top: 20px; }

.for-home {
  border: none;
  border-bottom: 1px solid; }

.quick-view .content {
  width: 60%;
  padding: 50px; }
  @media screen and (max-width: 1599px) {
    .quick-view .content {
      width: 95%; } }
  @media screen and (max-width: 967px) {
    .quick-view .content {
      width: 98%;
      padding: 20px; } }
  @media screen and (max-width: 767px) {
    .quick-view .content {
      padding: 15px; } }

.quick-view .content {
  position: relative;
  margin: 30px auto;
  background: #fff; }

.quick-view.option-loader .content {
  width: 34%; }
  @media screen and (max-width: 1199px) {
    .quick-view.option-loader .content {
      width: 60%; } }
  @media screen and (max-width: 967px) {
    .quick-view.option-loader .content {
      width: 75%; } }
  @media screen and (max-width: 767px) {
    .quick-view.option-loader .content {
      width: 90%; } }

.quick-view .product-inventory {
  border-bottom: none;
  margin-bottom: 15px; }

.quick-view {
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: -webkit-transform .2s,opacity .2s,visibility .2s;
  -webkit-transition: opacity .2s,visibility .2s,-webkit-transform .2s;
  transition: opacity .2s,visibility .2s,-webkit-transform .2s;
  transition: transform .2s,opacity .2s,visibility .2s;
  transition: transform .2s,opacity .2s,visibility .2s,-webkit-transform .2s;
  display: block !important; }

.quick-view .product-shop.summary span.price {
  font-size: 18px;
  line-height: 1;
  font-weight: 600; }
  @media screen and (max-width: 1199px) {
    .quick-view .product-shop.summary span.price {
      font-size: 14px; } }

.quickview-featured-image {
  text-align: center; }

#toTop i {
  font-size: 18px; }

.infinite-scrolling {
  text-align: center;
  clear: both; }

.infinite-scrolling a, .infinite-scrolling-homepage a {
  display: inline-block;
  width: 345px; }
  @media screen and (max-width: 767px) {
    .infinite-scrolling a, .infinite-scrolling-homepage a {
      width: 100%; } }

@media screen and (max-width: 567px) {
  #slide-236-layer-1, #slide-236-layer-2, #slide-236-layer-3, .tp-caption.rev-btn, #slide-237-layer-1, #slide-237-layer-2, #slide-237-layer-3, #slide-238-layer-1, #slide-238-layer-2 {
    display: none !important; } }

.rev-btn {
  -webkit-transition: all 0.4s ease-in-out !important;
  -moz-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  padding: 14px 28px !important; }

@media screen and (max-width: 767px) {
  ul li:first-child .tp-parallax-wrap {
    padding-left: 60px; } }

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  #email-modal .modal-window .window-window {
    width: 50%; } }
@media screen and (min-width: 1200px) and (max-width: 1727px) {
  .site-footer .grid-uniform .grid__item form {
    width: 90%; }

  .site-footer input {
    width: 80%; }

  .products .spr-badge {
    font-size: 11px !important; }

  .single-product-layout-type-3 .product_single_detail_section .add-to-wishlist {
    width: 100%; }

  .single-product-layout-type-3 .add-to-wishlist .btn, .single-product-layout-type-3 .add-to-wishlist .btn--secondary, .single-product-layout-type-3 .add-to-wishlist .btn--tertiary, .single-product-layout-type-3 .add-to-wishlist input[type="submit"] {
    margin-left: 0;
    margin-top: 10px; }

  .collection-products {
    padding: 0 10px; } }
.owl-prev a, .owl-next a {
  cursor: pointer;
  display: inline-block; }

.owl-prev.disabled .btn, .owl-prev.disabled .btn--secondary, .owl-prev.disabled .btn--tertiary, .owl-prev.disabled input[type="submit"], .owl-next.disabled .btn, .owl-next.disabled .btn--secondary, .owl-next.disabled .btn--tertiary, .owl-next.disabled input[type="submit"] {
  opacity: 0.7 !important; }

.single-product-layout-type-1 .owl-prev.disabled, .single-product-layout-type-1 .owl-next.disabled {
  opacity: 0;
  filter: alpha(opacity=0);
  zoom: 1; }

.single-product-layout-type-1 .owl-carousel .owl-item {
  display: inline-block; }

.single-product-layout-type-1 .product_single_detail_section {
  border-right: none; }

.single-product-layout-type-1 #ProductThumbs .owl-carousel .owl-item {
  margin: 0 5px !important; }

single-product-layout-type-7 .swatch-element label {
  border-radius: 30px; }

.single-product-layout-type-7 .add-to-wishlist .btn, .single-product-layout-type-7 .add-to-wishlist .btn--secondary, .single-product-layout-type-7 .add-to-wishlist .btn--tertiary, .single-product-layout-type-7 .add-to-wishlist input[type="submit"] {
  display: inline-block;
  float: none; }

@media screen and (max-width: 1599px) {
  .single-product-layout-type-7 .add-to-wishlist .btn, .single-product-layout-type-7 .add-to-wishlist .btn--secondary, .single-product-layout-type-7 .add-to-wishlist .btn--tertiary, .single-product-layout-type-7 .add-to-wishlist input[type="submit"] {
    margin-top: 0; } }

.single-product-layout-type-7 .single-7-btn .btn, .single-product-layout-type-7 .single-7-btn .btn--secondary, .single-product-layout-type-7 .single-7-btn .btn--tertiary, .single-product-layout-type-7 .single-7-btn input[type="submit"] {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  line-height: 60px;
  padding: 0;
  margin: 0;
  font-size: 20px; }

.single-product-layout-type-7 #AddToCart i {
  margin-right: 0; }

.single-product-layout-type-7 .add-to-wishlist .btn i, .single-product-layout-type-7 .add-to-wishlist .btn--secondary i, .single-product-layout-type-7 .add-to-wishlist .btn--tertiary i, .single-product-layout-type-7 .add-to-wishlist input[type="submit"] i {
  margin-right: 0; }

.single-product-layout-type-7 .product_single_detail_section .add-to-wishlist {
  float: none;
  display: inline-block; }
  @media screen and (max-width: 1599px) {
    .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist {
      width: auto; } }
  @media screen and (max-width: 1199px) {
    .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist {
      width: 100%;
      margin-top: 10px; } }

.single-product-layout-type-7 .product_single_detail_section .selector-wrapper-secton {
  border: none;
  margin: 0;
  padding: 0; }

.single-product-layout-type-7 .product_single_detail_section .selector-wrapper-secton .selector-wrapper {
  display: none;
  width: 100%;
  margin-bottom: 20px; }

.single-product-layout-type-7 .selector-wrapper .selector-arrow, .product_single_detail_section .selector-wrapper-secton .selector-wrapper label, .product-single .price_range {
  width: 100%;
  text-align: left; }

.single-product-layout-type-7 .product_single_detail_section #AddToCart {
  margin-bottom: 0px;
  float: none;
  display: inline-block; }

.single-product-layout-type-7 .product_single_detail_section .add-to-wishlist .btn, .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist .btn--secondary, .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist .btn--tertiary, .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist input[type="submit"] {
  margin-left: 0; }

@media screen and (max-width: 967px) {
  .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist .btn, .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist .btn--secondary, .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist .btn--tertiary, .single-product-layout-type-7 .product_single_detail_section .add-to-wishlist input[type="submit"], .single-product-layout-type-7 .product_single_detail_section #AddToCart {
    font-size: 13px; } }

.single-product-layout-type-7 .product_single_detail_section .product-single__quantity {
  margin-bottom: 30px;
  margin-top: 0; }

.single-product-layout-type-7 .product_single_price {
  margin-bottom: 15px; }

.single-product-layout-type-7 .product-infor {
  float: left;
  width: 100%;
  margin-top: 10px; }

.single-product-layout-type-7 .product-single {
  position: relative;
  float: left;
  width: 100%; }
  @media screen and (max-width: 1599px) {
    .single-product-layout-type-7 .product-single {
      padding: 0 20px; } }

@media screen and (max-width: 1199px) {
  .single-product-layout-type-7 .product-single__quantity label, .single-product-layout-type-7 .product-infor label {
    width: 100%;
    margin-bottom: 10px; } }
@media screen and (max-width: 767px) {
  .single-product-layout-type-7 .product-single__quantity label, .single-product-layout-type-7 .product-infor label {
    width: 40%; } }

@media screen and (max-width: 1199px) {
  .single-product-layout-type-7 .quantity_width {
    width: 100%; } }
@media screen and (max-width: 767px) {
  .single-product-layout-type-7 .quantity_width {
    width: 60%; } }

.single-product-layout-type-7 .shopify-payment-button .shopify-payment-button__button--unbranded {
  margin-bottom: 20px;
  line-height: normal;
  border-radius: 30px; }

.product-single .price_range {
  float: left; }

.single-product-layout-type-7 .swatch-section {
  float: none;
  width: 100%;
  display: inline-block;
  text-align: center; }

.single-product-layout-type-7 .swatch .swatch-element {
  float: none;
  display: inline-block;
  margin: auto; }

.single-product-layout-type-7 .swatch .header {
  width: 100%;
  text-align: center; }

.single-product-layout-type-7 .single-7-btn {
  margin-bottom: 25px;
  float: left;
  width: 100%;
  text-align: center; }

.single-product-layout-type-7 .shopify-payment-button {
  float: none;
  margin-left: 0;
  display: inline-block;
  width: 100%; }

.single-product-layout-type-7 .single-7-paybtn {
  width: 100%;
  float: left;
  text-align: center; }

.product-single .price_varies {
  width: auto;
  color: #dcdcdc;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0; }

@media screen and (max-width: 967px) {
  .single-product-layout-type-7 .product_single_detail_section .product-single__quantity input#quantity {
    width: 45px; } }

@media screen and (max-width: 967px) {
  .single-product-layout-type-7 .product_single_detail_section .product-single__quantity .dec, .single-product-layout-type-7 .product_single_detail_section .product-single__quantity input, .product_single_detail_section .product-single__quantity .inc {
    width: 35px; } }

.single-product-layout-type-7 .product-img-box {
  padding-left: 30px; }
  @media screen and (max-width: 967px) {
    .single-product-layout-type-7 .product-img-box {
      padding-left: 10px; } }
  @media screen and (max-width: 767px) {
    .single-product-layout-type-7 .product-img-box {
      padding-left: 0px; } }

.single-product-layout-type-7 .quantity_width .min-qty-alert {
  width: 100%;
  margin-left: 0;
  margin-top: 5px;
  line-height: 1.4; }

@media screen and (max-width: 1199px) {
  .single-product-layout-type-7 .product_single_detail_section .swatch .header {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px; } }
@media screen and (max-width: 767px) {
  .single-product-layout-type-7 .product_single_detail_section .swatch .header {
    width: 40%; } }

@media screen and (max-width: 1199px) {
  .single-product-layout-type-7 .swatch-section {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px; } }
@media screen and (max-width: 767px) {
  .single-product-layout-type-7 .swatch-section {
    width: 40%; } }

@media screen and (max-width: 1599px) {
  .single-product-layout-type-7 .product-infor label {
    width: 50%; } }
@media screen and (max-width: 1199px) {
  .single-product-layout-type-7 .product-infor label {
    width: 100%; } }

.template-product .dt-sc-tabs-container .dt-sc-tabs li {
  float: left; }
  @media screen and (max-width: 567px) {
    .template-product .dt-sc-tabs-container .dt-sc-tabs li {
      width: 100%;
      margin-bottom: 7px; } }

.template-product .dt-sc-tabs-container {
  float: left;
  width: 100%; }

.template-product .dt-sc-tabs-container ul, .template-product .dt-sc-tabs-container ol, .template-collection .product-detail ul, .template-collection .product-detail ol {
  margin-bottom: 0; }

blockquote {
  position: relative; }

.dt-sc-tabs-content blockquote::before blockquote:before {
  content: "\"";
  font-size: 70px;
  left: -28px;
  position: absolute;
  top: -28px;
  font-family: Maven Pro;
  width: 70px;
  text-align: center; }

/* Sidebar Instagram */
.instagram-images {
  float: left;
  width: 100%;
  margin: 0 0 30px;
  padding: 0 0 30px 0px;
  border-bottom: 1px solid #36342f; }

#instafeed {
  float: left;
  width: 100%; }

.instagram-images #instafeed a {
  float: left;
  width: 25%; }

.instagram-images #instafeed a img {
  display: block; }

.instagram-images a.btn, .instagram-images a.btn--secondary, .instagram-images a.btn--tertiary {
  margin-top: 10px; }

.instagram.widget {
  margin-bottom: 60px; }

.homepage_sidebar .lush-instagram .info {
  display: none; }

/* Sidebar Newsletter */
.home-sidebar-newsletter .newsletter-section form {
  width: 100%;
  margin-top: 20px; }

/* Slider */
.slide-des {
  position: absolute;
  bottom: 40%;
  left: 20px;
  right: 20px;
  padding: 0 20px;
  text-align: center;
  max-width: 600px; }
  @media screen and (max-width: 967px) {
    .slide-des {
      bottom: 25%; } }
  @media screen and (max-width: 767px) {
    .slide-des {
      display: none; } }

.sidebar-hidden #shopify-section-sidebar-colors {
  width: 25%;
  float: left; }

.sidebar-hidden #shopify-section-sidebar-colors .sidebar-tag {
  float: left;
  width: 100%; }

.sidebar-hidden #shopify-section-sidebar-tag-filters {
  width: 75%;
  float: left; }

.sidebar-hidden .sidebar-tag {
  border-right: 1px solid #36342f;
  min-height: 160px;
  float: left;
  width: 33.33%;
  padding: 15px;
  border-bottom: none;
  margin: 30px 0; }
  @media screen and (max-width: 967px) {
    .sidebar-hidden .sidebar-tag {
      padding: 10px; } }

.sidebar-hidden .collection_sidebar .widget .product-categories li, .sidebar-hidden .sidebar-tag.brand li, .sidebar-hidden .widget_categories li, .sidebar-hidden .sidebar-tag.price li, .sidebar-hidden .sidebar-tag.material li, .sidebar-hidden .sidebar-tag.color ul li {
  float: left;
  width: 45%; }

.sidebar-hidden .sidebar-tag.color ul li {
  margin: 0; }

.sidebar-hidden .sidebar-tag ul {
  margin: 0; }

#shopify-section-sidebar-tag-filters aside:last-child {
  border-right: none; }

.sidebar-hidden .btn.tag-fillter, .sidebar-hidden .tag-fillter.btn--secondary, .sidebar-hidden .tag-fillter.btn--tertiary, .sidebar-hidden input.tag-fillter[type="submit"], .sidebar-hidden .tags-filter .btn, .sidebar-hidden .tags-filter .btn--secondary, .sidebar-hidden .tags-filter .btn--tertiary, .sidebar-hidden .tags-filter input[type="submit"] {
  float: left;
  padding: 10px 30px;
  margin-right: 15px; }

.sidebar-hidden .btn.tag-fillter i, .sidebar-hidden .tag-fillter.btn--secondary i, .sidebar-hidden .tag-fillter.btn--tertiary i, .sidebar-hidden input.tag-fillter[type="submit"] i {
  margin-left: 10px; }

.sidebar-hidden .section-header {
  margin-bottom: 0px; }

.sidebar-tag.color ul li::before {
  content: none; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #b10a0a;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #934d4d; }

@media screen and (max-width: 767px) {
  .sticky-mass {
    position: static !important; } }

.sticky-wrapper {
  height: auto !important; }

.owl-prev a, .owl-next a {
  z-index: 1;
  cursor: pointer;
  padding: 0; }

.owl-prev, .owl-next {
  float: left;
  z-index: 1;
  padding: 0; }

@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-devicepixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  background-attachment: initial; }
.tab-icon-wrapper {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 50px;
  padding-right: 10px; }

.tab-icon-wrapper .tab-icon {
  position: absolute;
  left: 0;
  width: 40px;
  font-size: 30px;
  line-height: normal; }

.vertical-left-tab h6, .vertical-left-tab .h6 {
  font-size: 12px;
  margin-bottom: 5px; }

.tab-img {
  padding-left: 20px; }

.vertical-tab {
  position: relative;
  float: left;
  width: 100%; }

.vertical-left-tab .dt-sc-tabs-vertical-container ul.dt-sc-tabs-vertical-frame::before {
  position: absolute;
  height: 100%;
  width: 33%;
  content: "";
  background: pink;
  left: 0;
  bottom: 0; }

.owl-carousel.owl-theme {
  overflow: hidden; }

.owl-carousel {
  padding: 0; }

.flexslider a.btn:before, .flexslider a.btn--secondary:before, .flexslider a.btn--tertiary:before {
  background: none; }

.filter-show .btn::before, .filter-show .btn--secondary::before, .filter-show .btn--tertiary::before, .filter-show input[type="submit"]::before, .filter-show .btn::after, .filter-show .btn--secondary::after, .filter-show .btn--tertiary::after, .filter-show input[type="submit"]::after {
  background: transparent; }

.newsletterwrapper .btn.close::after, .newsletterwrapper .close.btn--secondary::after, .newsletterwrapper .close.btn--tertiary::after, .newsletterwrapper input.close[type="submit"]::after, .newsletterwrapper .btn.close::before, .newsletterwrapper .close.btn--secondary::before, .newsletterwrapper .close.btn--tertiary::before, .newsletterwrapper input.close[type="submit"]::before {
  content: none; }

.newsletterwrapper .btn.close, .newsletterwrapper .close.btn--secondary, .newsletterwrapper .close.btn--tertiary, .newsletterwrapper input.close[type="submit"] {
  box-shadow: 0 0 0 0 transparent; }

#fancybox-inner {
  top: 0 !important; }

.products .lSSlideOuter.vertical {
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out; }

.products:hover .lSSlideOuter.vertical {
  right: 0px; }

.products .lslide {
  box-shadow: 0 0 5px rgba #36342f, 0.7; }

.lSSlideWrapper .lSSlide {
  padding: 10px; }

#tags-filter-content.tags-closed {
  border: none; }

#tags-filter-content.tags-closed .sidebar-tag {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  min-height: 0;
  overflow: hidden; }

#tags-filter-content .sidebar-tag {
  display: block;
  margin: 0 0 40px 0;
  float: left;
  border-right: 1px solid #36342f;
  height: 200px;
  min-height: 200px;
  -webkit-transition: ease all 0.5s;
  -moz-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  -o-transition: ease all 0.5s;
  transition: ease all 0.5s; }

.filter.tags {
  -webkit-transition: ease all 0.5s;
  -moz-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  -o-transition: ease all 0.5s;
  transition: ease all 0.5s; }

.collection_sidebar {
  border-top: 1px solid #36342f;
  border-bottom: 1px solid #36342f;
  margin-bottom: 30px;
  padding-top: 30px; }

#tags-filter-content.tags-closed .collection_sidebar {
  border-top: none;
  border-bottom: none;
  margin-bottom: 0px;
  padding-top: 0px; }

.sidebar-tag .widget-content {
  float: left;
  width: 100%; }

.collection_sidebar .widget h4 span, .collection_sidebar .widget .h4 span {
  float: left;
  width: 100%;
  cursor: pointer; }

.collection_sidebar .widget.widget_product_categories h4 span::before, .collection_sidebar .widget.widget_product_categories .h4 span::before {
  content: none; }

.collection_sidebar .widget .product-categories li a, .homepage_sidebar .widget .product-categories li a {
  float: left;
  width: 80%; }

.collection_sidebar .widget .product-categories li span.dt-sc-toggle:before, .homepage_sidebar .widget .product-categories li span.dt-sc-toggle:before {
  cursor: pointer;
  font-size: 14px;
  content: '\f278';
  font-family: Material-Design-Iconic-Font; }

.collection_sidebar .widget .product-categories li span.dt-sc-toggle.active:before, .homepage_sidebar .widget .product-categories li span.dt-sc-toggle.active:before {
  cursor: pointer;
  font-size: 14px;
  content: '\f273';
  font-family: Material-Design-Iconic-Font; }

.collection_sidebar .dt-sc-toggle, .homepage_sidebar .dt-sc-toggle {
  float: right;
  width: 10%;
  text-align: center;
  cursor: pointer; }

.homepage_sidebar .widget .product-categories li a.active, .collection_sidebar .widget .product-categories li a.active, .blog-design-4 .widget.widget_categories ul li.active {
  color: #934d4d; }

.collection_sidebar .widget h4 span::before, .collection_sidebar .widget .h4 span::before, .collection_sidebar .widget .h4 span::before {
  font-size: 14px;
  position: absolute;
  content: "\f0d7";
  left: 15px;
  top: 12px;
  font-family: FontAwesome; }

.collection_sidebar .widget.click h4 span::before, .collection_sidebar .widget.click .h4 span::before, .collection_sidebar .widget.click .h4 span::before {
  font-size: 14px;
  position: absolute;
  content: "\f0d8";
  right: 12px;
  top: 12px;
  font-family: FontAwesome; }

@media screen and (max-width: 967px) {
  .homepage_sidebar .shopify-section {
    float: left;
    width: 50%;
    padding-left: 10px; } }
@media screen and (max-width: 567px) {
  .homepage_sidebar .shopify-section {
    float: left;
    width: 100%;
    padding-left: 0px; } }

@media screen and (max-width: 567px) {
  .collection_sidebar .shopify-section {
    float: left;
    width: 100%; } }

@media screen and (max-width: 967px) {
  .homepage_sidebar .shopify-section:nth-child(2n+1) {
    clear: both; } }

.homepage_sidebar .shopify-section:nth-child(odd) {
  padding-left: 0; }

.bottom-products-suggest .suggest-close {
  display: none; }

.bottom-products-suggest .product-short-suggest .price del {
  margin-right: 10px; }

.toolbar .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  display: none;
  float: left;
  padding: 15px 20px;
  margin: 0px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: #181616;
  background-clip: padding-box;
  border-top: none;
  border-bottom: 1px solid #36342f;
  border-left: 1px solid #36342f;
  border-right: 1px solid #36342f;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.wishlist-box .product-remove a {
  font-size: 20px; }

.order-section-form {
  padding-left: 30px; }
  @media screen and (max-width: 767px) {
    .order-section-form {
      padding-left: 0px;
      margin-top: 30px; } }

.spr-badge-caption {
  margin-left: 5px;
  display: none; }

.template-customers-order th {
  text-align: center; }

@media screen and (max-width: 967px) {
  .slick-dots {
    bottom: 10px !important; } }

.collection-list .product-list .reveal .swipe {
  width: initial;
  left: 0;
  right: 0; }

.product-container .reveal .swipe {
  display: block !important;
  visibility: visible !important; }

.hidden, .swipe {
  display: none; }

.product-container .reveal {
  display: table;
  position: relative; }

.products .reveal .hidden {
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out; }

.product-container:hover .reveal .swipe {
  z-index: 9;
  opacity: 1; }

.hidden {
  visibility: hidden; }

.swatch .swatch-element.color input:checked + label.white-tick:before {
  color: #000; }

.filter-show ul {
  width: 40%; }

.toolbar ul li {
  list-style: none;
  border: none; }

.toolbar ul li a {
  padding-bottom: 10px;
  float: left;
  width: 100%; }

.toolbar ul li:last-child a {
  padding-bottom: 0; }

.toolbar .open .dropdown-menu {
  border-radius: 10px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  left: auto;
  text-align: left; }

.sidebar-tag .widget-title h4 span, .sidebar-tag .widget-title .h4 span {
  cursor: pointer;
  font-size: 12px; }

.sidebar-tag .widget-title h4 span:before, .sidebar-tag .widget-title .h4 span:before {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  display: inline-block;
  margin: 0 10px -2px 0; }

.sidebar-tag .widget-title h4 a, .sidebar-tag .widget-title .h4 a {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  float: right;
  letter-spacing: 0; }

.sidebar-tag .widget-title.click h3 span:before, .sidebar-tag .widget-title.click .h3 span:before {
  border-top-color: transparent;
  margin-bottom: 2px; }

.sidebar-tag .widget-title {
  margin-bottom: 20px;
  padding: 21px 0 0;
  border-bottom: 0;
  float: left;
  width: 100%; }

.sidebar-tag .widget-content {
  margin-bottom: 0; }

.sidebar-tag label {
  font-weight: 400;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.sidebar-tag ul {
  overflow: hidden;
  padding: 0;
  float: left;
  width: 100%;
  list-style: none; }

.sidebar-tag ul li a {
  text-decoration: none; }

.sidebar-tag input[type=checkbox] {
  display: none; }

.sidebar-tag li:hover input[type="checkbox"] + label {
  color: #934d4d; }

.sidebar-tag input[type=checkbox] + label:hover, .sidebar-tag input[type=checkbox]:checked + label {
  cursor: pointer; }

.sidebar-tag.size ul li {
  float: left;
  margin-top: 12px;
  margin-right: 10px;
  border: 1px solid #36342f; }

.sidebar-tag.size ul li:hover {
  border-color: #934d4d; }

.sidebar-tag.color ul {
  margin: 0;
  list-style: none;
  float: left;
  width: 100%;
  padding: 0; }

.sidebar-tag.color ul li {
  width: 100%;
  padding: 0;
  float: left;
  margin: 0; }

.sidebar-tag.color ul li a {
  display: block;
  float: left;
  width: 100%; }

.sidebar-tag.color ul li a img {
  float: left;
  margin-top: 5px;
  width: 15px;
  height: 15px;
  border-radius: 15px; }

.sidebar-tag.color ul li a span {
  float: left;
  margin-left: 15px; }

.sidebar-tag.color ul li a i {
  float: right;
  text-align: right;
  font-style: inherit; }

.sidebar-tag.color ul li.white a img {
  border: 1px solid #e4e4e4; }

.refined-widgets > span {
  font-size: 14px; }

.refined-widgets > a {
  float: right;
  margin-bottom: 15px;
  text-decoration: underline; }

.item-swatch.color_swatch_Value label {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  float: left;
  cursor: pointer; }

.item-swatch {
  list-style: none;
  padding: 0;
  float: left;
  width: 100%; }

.item-swatch li {
  float: left;
  margin: 0 3px 0 0; }

.item-swatch.swatch_count li label {
  cursor: pointer;
  margin: 0;
  line-height: 40px;
  width: 60px;
  height: 40px;
  background: #f2f2f2; }

ul.item-swatch.swatch_count {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0; }

.sidebar-tag.size input[type="checkbox"] + label {
  padding: 5px 12px;
  border-bottom: none; }

.sidebar-tag input:checked + label {
  color: #934d4d; }

.ajax-error-modal, .loading-modal {
  bottom: auto;
  overflow: hidden;
  right: auto;
  position: fixed;
  text-align: center; }

.loading-modal {
  background: url(loading.gif) center 14px no-repeat white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  top: 50% !important;
  left: 50% !important;
  width: 56px;
  height: 56px;
  margin-left: -28px;
  margin-top: -28px;
  padding: 0;
  text-indent: -999em;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: block; }

.ajax-right .success-message p {
  font-size: 14px; }

.ajax-right .btn, .ajax-right .btn--secondary, .ajax-right .btn--tertiary, .ajax-right input[type="submit"] {
  text-transform: uppercase; }

.ajax-right .btn i, .ajax-right .btn--secondary i, .ajax-right .btn--tertiary i, .ajax-right input[type="submit"] i {
  margin-right: 12px; }

.ajax-error-modal {
  left: 0;
  padding: 10px 20px;
  top: 50%;
  width: 100%; }

.ajax-error-modal .modal-inner {
  color: #fff;
  background: #191919;
  display: inline-block;
  padding: 10px 20px; }

.ajax-success-modal .btn-secondary {
  padding: 0 20px; }

.success-message {
  line-height: 40px;
  color: #191919;
  padding: 0 0 0 40px;
  margin-bottom: 20px;
  font-size: 12px; }

.ajax-success-modal .success-message p {
  display: inline-block;
  width: 100%;
  margin: 0; }

.ajax-success-modal .success-message {
  padding: 0; }

.ajax-success-modal .close-modal .fa {
  font-size: 20px;
  color: #fff; }

.ajax-success-modal .close-modal {
  top: 8px;
  right: 8px; }

.compare_modal, .modal {
  top: 0;
  bottom: 0;
  z-index: 10000; }

.ajax-content-section {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: auto; }

.compare_modal, .modal {
  display: none;
  left: 0;
  outline: 0; }

.modal {
  position: fixed;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.close-modal {
  float: right;
  bottom: 0;
  position: absolute;
  right: 0; }

.compare_modal {
  position: fixed;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#compare-modal #table-compare th {
  font-weight: 400; }

#compare-modal .close-modal {
  bottom: auto;
  top: -15px;
  right: -7px;
  font-size: 30px; }

#compare-modal .product_price {
  margin-bottom: 10px; }

#compare-modal .product_price strong {
  margin-right: 7px; }

#compare-modal table .btn, #compare-modal table .btn--secondary, #compare-modal table .btn--tertiary, #compare-modal table input[type="submit"] {
  padding: 10px 24px;
  font-size: 13px; }

#compare-modal table button, #compare-modal table p {
  margin: 0; }

#table-compare td, #table-compare th, .compare_modal .th-compare th {
  text-align: center; }

#table-compare td img {
  margin-bottom: 5px; }

.compare_modal h4, .compare_modal .h4 {
  text-align: center;
  margin: 0;
  padding: 20px; }

.modal.fade .modal-dialog {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%); }

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }

.quick-view, .quickview-template {
  bottom: 0;
  left: 0;
  outline: 0;
  overflow-x: auto;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10050;
  display: none; }

.ajax-success-modal .overlay, .quick-view .overlay, .quickview-template .content {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  position: fixed; }

.quick-view .product-shop {
  float: right; }

.quick-view .product-img-box {
  margin: 0;
  float: left; }

.quick-view .product-img-box .image, .quick-view .product-img-box .image-wrapper {
  width: 311px;
  height: 311px;
  overflow: hidden;
  position: relative; }

.quick-view .product-img-box .image img {
  display: block;
  position: relative;
  max-width: 110%; }

.quick-view .product-img-box .thumbnails {
  padding: 20px 0 0 40px;
  position: relative; }

.quick-view .product-img-box .thumbnails .jcarousel-next-horizontal, .quick-view .product-img-box .thumbnails .jcarousel-prev-horizontal {
  width: 70px;
  height: 70px;
  position: absolute;
  display: block;
  -moz-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
  top: 0;
  float: left; }

.quick-view .product-img-box .thumbnails .jcarousel-next-horizontal {
  right: 0;
  opacity: .2; }

.quick-view .product-img-box .thumbnails .jcarousel-next-horizontal:hover {
  opacity: 1; }

.quick-view .product-img-box .thumbnails .jcarousel-prev-horizontal {
  left: 0;
  opacity: .2; }

.quick-view .product-img-box .thumbnails .jcarousel-prev-horizontal:hover, .quick-view .product-img-box .thumbnails ul li .active, .quick-view .product-img-box .thumbnails ul li a:hover {
  opacity: 1; }

.quick-view .product-img-box .thumbnails ul {
  float: left;
  text-align: center;
  list-style: none;
  padding-left: 0;
  width: 266px; }

.quick-view .product-img-box .thumbnails ul li a img {
  max-width: 110%; }

.quick-view .product-shop p.custom {
  margin: 0; }

.quick-view .product-shop .prices {
  margin-top: 25px; }

.quick-view .product-shop .prices:first-child {
  margin-top: 0; }

.quick-view .product-shop .addthis {
  margin-bottom: 0; }

.quickview-template {
  bottom: 0;
  left: 0;
  outline: 0;
  overflow-x: auto;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
  display: none; }

.quick-view {
  bottom: 0;
  left: 0;
  outline: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10050;
  display: none; }

.quick-view .product-detail-section p.dt-sc-button {
  margin: 20px 0 0;
  float: left; }

.quick-view form .actions button {
  font-weight: 500;
  text-transform: uppercase;
  float: left;
  padding: 10px 20px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.quick-view .product-shop.summary .details {
  float: left;
  width: 100%; }

.quick-view .product-shop.summary .details label {
  float: left;
  margin-right: 5px;
  min-height: 35px;
  min-width: 35px; }

.quick-view .product-shop.summary .details label {
  color: #b10a0a;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  float: left; }

.quick-view .product-shop.summary .details .quantity-box label {
  margin-top: 10px; }

.quick-view .product-shop.summary h2, .quick-view .product-shop.summary .h2 {
  margin-bottom: 15px; }

.quick-view .product-detail-section p.product-description {
  margin-bottom: 20px;
  float: left;
  width: 100%; }

.product-infor .out-of-stock {
  color: red; }

.product-infor .many-in-stock {
  color: #fa9324; }

.product-infor .unavailable {
  color: orchid; }

.product-infor .items-count {
  color: green; }

.quick-view .product-shop.summary select {
  width: 100%;
  padding: 12px 10px; }

.quick-view.open-in {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .4s,opacity .4s;
  -webkit-transition: opacity .4s,-webkit-transform .4s;
  transition: opacity .4s,-webkit-transform .4s;
  transition: transform .4s,opacity .4s;
  transition: transform .4s,opacity .4s,-webkit-transform .4s; }

.ajax-success-modal .overlay {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99; }

.quick-view .content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: 99;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  -ms-perspective: 1200px;
  perspective: 1200px; }

._quick-view-content {
  display: none; }

.quick-view .close-window, #fancybox-close, .search-close, #popupBoxClose {
  background: url(//cdn.shopify.com/s/files/1/0020/6048/4665/t/8/assets/hover.png?v=14866871867660656306) no-repeat;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  position: absolute;
  top: 0px;
  right: 0px; }

.dialog, .dialog__overlay {
  display: block;
  position: fixed; }

#popupBoxClose {
  right: -10px;
  top: -10px; }

.search-close {
  right: 15px;
  top: -120px; }

.product-shop.summary {
  float: right;
  width: 50%; }

.product-img.images {
  margin: 0 !important;
  float: left;
  width: 47%; }

.product-shop.summary .prices {
  float: left;
  width: 100%;
  margin: 0; }

.product-shop.summary span.price {
  margin-bottom: 10px;
  float: left; }

.dialog, .dialog__overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  float: left; }

.dialog {
  top: 350px;
  padding: 100px 0 0;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  pointer-events: none;
  z-index: 900; }

.dialog__overlay {
  background: rgba(249, 249, 249, 0.95);
  bottom: 0;
  height: auto;
  left: 0;
  opacity: 0;
  overflow: auto;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: auto;
  z-index: 8010; }

.dialog.dialog--open .dialog__content {
  opacity: 1; }

.dialog--open .dialog__overlay {
  opacity: 1;
  pointer-events: auto; }

.dialog__content {
  display: inline-block;
  opacity: 0;
  position: relative;
  text-align: center;
  width: 65%;
  z-index: 8030;
  padding: 0 0 0 260px; }

.dialog--open .dialog__content {
  pointer-events: auto; }

.dialog h2, .dialog .h2 {
  font-weight: 400;
  font-size: 2em;
  padding: 0 0 2em;
  margin: 0; }

.dialog input[type=text] {
  width: 80%;
  float: left;
  padding: 16px 15px;
  min-height: 48px;
  border: none; }

.dialog input[type=submit] {
  float: left;
  border: none;
  padding: 15px 18px 16px;
  min-height: 48px; }

.simple-menu .coll-img {
  display: none !important; }

.clear-filter {
  text-transform: capitalize;
  float: right;
  font-size: 12px;
  padding: 2px 0; }

.widget a.hidden, .widget_text a.hidden {
  display: none; }

#viewcontrols .gridview, #viewcontrols .listview {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  font-size: 24px; }

#viewcontrols .gridview {
  padding-right: 10px; }

#viewcontrols {
  float: left;
  display: inline-block; }

/* Testimonial-type-11 */
.testimonial-type-11 .invisible-very-small {
  float: left;
  width: 100%;
  margin: 10px 0; }

.testimonial-type-11 blockquote {
  margin: 0 0 40px;
  padding: 0;
  float: left;
  width: 100%; }

.testimonial-type-11 blockquote q {
  position: relative; }

.testimonial-type-11 .authorimg img {
  display: block;
  border-radius: 50%;
  margin: 0; }

.testimonial-type-11 h4, .testimonial-type-11 .h4 {
  text-transform: capitalize; }

.testimonial-type-11 .testimonial-block {
  margin-bottom: 40px;
  transition: 0.3s linear; }

.testimonial-type-11 .testimonial-block blockquote:before {
  content: none; }

.testimonial-type-11 q {
  line-height: 32px;
  float: left;
  text-align: center; }

.testimonial-type-11 .test-detail {
  text-align: center;
  float: none;
  width: 100%;
  display: inline-block;
  margin: 0 auto; }

.testimonial-type-11 .owl-theme .owl-controls .owl-page span {
  height: 15px;
  width: 15px;
  margin: 0px 5px;
  border-radius: 50%; }

.testimonial-type-11 .main-heading {
  text-align: center; }

.testimonial-type-11 .authorimg {
  z-index: 1;
  display: inline-block;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  position: relative; }

.testimonial-type-11 .authorimg::before {
  content: "";
  position: absolute;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  top: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  z-index: -1; }

.testimonial-type-11 .test-detail h4, .testimonial-type-11 .test-detail .h4 {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0; }

.testimonial-type-11 .test-detail span {
  font-size: 14px;
  text-transform: uppercase; }

.testimonial-type-11 h3, .testimonial-type-11 .h3 {
  text-transform: uppercase; }

.testimonial-type-11 .owl-prev, .testimonial-type-11 .owl-next {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transform: translateY(-50%);
  position: absolute;
  top: 50%; }

.testimonial-type-11 .owl-prev {
  left: 0; }

.testimonial-type-11 .owl-next {
  right: 0; }

.testimonial-type-11 {
  float: left;
  width: 100%;
  padding: 5%; }

.testimonial-type-11 .slick-arrow::before, .testimonial-type-11 .slick-arrow::before {
  opacity: 1; }

.testimonial-type-11 .text-img {
  vertical-align: middle;
  margin-bottom: 30px; }

.testimonial-type-11 .test-inner-detail {
  display: inline-block;
  text-align: center; }

.testimonial-type-11 .slick-next::before {
  content: "\f2ee";
  font-family: "Material-Design-Iconic-Font"; }

.testimonial-type-11 .slick-prev::before {
  content: "\f2ea";
  font-family: "Material-Design-Iconic-Font"; }

.testimonial-type-11 .slick-prev, .testimonial-type-11 .slick-next {
  font-size: 50px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.testimonial-type-11 .slick-prev:hover, .testimonial-type-11 .slick-next:hover {
  color: #934d4d; }

.testimonial-type-11 .slick-prev {
  left: 25%; }

.testimonial-type-11 .slick-next {
  right: 25%; }

.testimonial-type-11 .text-img img {
  border-radius: 50%; }

.testimonial-type-11 .slick-dots {
  position: static; }

.testimonial-type-11 .slick-dots li button::before {
  font-size: 10px;
  line-height: normal;
  color: #934d4d; }

.instagram-block .instagram-detail {
  float: left;
  width: 100%;
  text-align: center;
  padding: 27% 0; }
  @media screen and (max-width: 1599px) {
    .instagram-block .instagram-detail {
      padding: 20% 0; } }
  @media screen and (max-width: 1199px) {
    .instagram-block .instagram-detail {
      padding: 15% 0; } }

.instagram-block .instagram-detail .border-title {
  float: none;
  width: 60%;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .instagram-block .instagram-detail .border-title {
      width: 80%; } }

.small-desc {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 0px; }

q::before {
  content: none; }

.owl-prev, .owl-next {
  margin: 0;
  padding: 0; }

#bg-group {
  position: relative;
  text-align: left; }

.wrapper-container {
  overflow: hidden; }

.home-sidebar-deals .widget.widget_top_rated_products {
  float: left;
  width: 100%;
  position: relative; }

.home-sidebar-deals .home_sidebar--deal-nav {
  z-index: 1;
  text-align: center;
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  -o-transform: translateY(-40%);
  transform: translateY(-40%);
  left: 0;
  margin: 0 auto;
  right: 0px; }

.home-sidebar-deals .home_sidebar--deal-nav a {
  font-size: 10px;
  padding: 0;
  margin: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: 1px solid #b10a0a; }

.home-sidebar-deals .home_sidebar--deal-nav a.prev {
  left: 0;
  position: absolute;
  float: left;
  padding: 0px; }

.home-sidebar-deals .home_sidebar--deal-nav a.next {
  position: absolute;
  right: 0;
  padding: 0px;
  float: right; }

.about-us .icon-section {
  float: left;
  padding-left: 70px;
  width: 100%;
  position: relative; }

.about-us .icon-heading {
  float: left;
  width: 100%; }

.about-us .icon-section .icon {
  border-radius: 50%;
  height: 60px;
  left: 0;
  font-size: 40px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: top 0.2s linear 0s;
  width: 60px; }

.about-us .icon-heading h3, .about-us .icon-heading .h3 {
  margin-bottom: 0;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 18px; }
  @media screen and (max-width: 1199px) {
    .about-us .icon-heading h3, .about-us .icon-heading .h3 {
      font-size: 12px; } }

.about-us .icon-desc {
  float: left;
  margin: 30px 0; }

.about-us .icon-desc p {
  margin-bottom: 0; }

.about-us h3, .about-us .h3 {
  font-size: 22px; }

.about-us .icon-section:hover .icon {
  top: -4px; }

.about-us ul {
  padding: 0;
  list-style: none; }

.about-us .section-one .border-title {
  text-align: left; }

.about-us .section-one .small-desc {
  float: left; }

.about-us .right-section {
  padding: 0 30px; }

.about-us .section-one .left-section a {
  text-align: center;
  display: block; }

.about-us .section-one .left-section a img {
  display: inline-block; }
  @media screen and (max-width: 967px) {
    .about-us .section-one .left-section a img {
      margin-bottom: 20px; } }

.about-us .abt-tab-section {
  float: left;
  text-align: center;
  width: 100%;
  padding: 5%; }

.about-us .team-whole-wrapper {
  padding: 0 10px; }

.about-us .team-wrapper {
  transition: all 0.3s linear 0s;
  float: left;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .about-us .team-wrapper {
      margin-bottom: 20px; } }

.about-us .team-detail {
  padding: 30px 10px;
  text-align: center; }

.about-us .team-detail h5, .about-us .team-detail .h5 {
  transition: all 0.3s linear 0s;
  font-size: 18px; }
  @media screen and (max-width: 967px) {
    .about-us .team-detail h5, .about-us .team-detail .h5 {
      font-size: 13px; } }

.about-us .team-wrapper h6, .about-us .team-wrapper .h6 {
  position: relative;
  margin-bottom: 30px;
  font-size: 16px; }

.about-us .social-icon li {
  display: inline-block;
  margin: 0 5px; }

.about-us .social-icon a {
  font-size: 14px;
  padding: 8px; }
  @media screen and (max-width: 967px) {
    .about-us .social-icon a {
      padding: 3px; } }

.about-us .team-wrapper h6::after, .about-us .team-wrapper .h6::after {
  bottom: -8px;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  transition: all 0.3s linear 0s;
  width: 30%; }

.about-us .team-wrapper:hover h6::after, .about-us .team-wrapper:hover .h6::after {
  width: 50%; }

.about-us .grid-uniform.section-five {
  float: left;
  width: 100%; }

.about-us .dt-sc-tabs-container .dt-sc-tabs {
  margin-bottom: 20px; }

/* Slider */
.home-slideshow .btn, .home-slideshow .btn--secondary, .home-slideshow .btn--tertiary, .home-slideshow input[type="submit"] {
  padding: 10px 35px; }

.home-slideshow .slide-text {
  font-family: Maven Pro; }

.home-slideshow .slide-offer-text {
  font-family: Maven Pro;
  margin-bottom: 25px; }
  @media screen and (max-width: 1199px) {
    .home-slideshow .slide-offer-text {
      font-size: 20px !important;
      margin-bottom: 15px; } }

@media screen and (max-width: 1199px) {
  .home-slideshow .slide-sub-heading-2 {
    font-size: 30px !important; } }
@media screen and (max-width: 967px) {
  .home-slideshow .slide-sub-heading-2 {
    font-size: 27px !important; } }
@media screen and (max-width: 567px) {
  .home-slideshow .slide-sub-heading-2 {
    font-size: 14px !important; } }

@media screen and (max-width: 1199px) {
  .home-slideshow .slide-heading {
    font-size: 27px !important; } }
@media screen and (max-width: 967px) {
  .home-slideshow .slide-heading {
    font-size: 20px !important; } }
@media screen and (max-width: 567px) {
  .home-slideshow .slide-heading {
    font-size: 14px !important; } }

.home-slideshow .slider_style_1 .slide-sub-heading-2 {
  margin-bottom: 30px; }
  @media screen and (max-width: 1199px) {
    .home-slideshow .slider_style_1 .slide-sub-heading-2 {
      margin-bottom: 15px; } }

.home-slideshow .slider-content {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media screen and (max-width: 1599px) {
    .home-slideshow .slider-content {
      top: 65%;
      -webkit-transform: translateY(-65%);
      -moz-transform: translateY(-65%);
      -ms-transform: translateY(-65%);
      -o-transform: translateY(-65%);
      transform: translateY(-65%); } }
  @media screen and (max-width: 767px) {
    .home-slideshow .slider-content {
      padding: 40px !important;
      text-align: center !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important; } }
  @media screen and (max-width: 567px) {
    .home-slideshow .slider-content {
      padding: 20px !important;
      width: 75% !important; } }

.home-slideshow .slick-active .slide-heading {
  animation: 1200ms ease-in-out 0s normal none 1 running slideInUp; }

.home-slideshow .slick-active .btn, .home-slideshow .slick-active .btn--secondary, .home-slideshow .slick-active .btn--tertiary, .home-slideshow .slick-active input[type="submit"] {
  animation: 1800ms ease-in-out 0s normal none 1 running slideInUp; }

.home-slideshow .slick-active .slide-text {
  animation: 1000ms ease-in-out 0s normal none 1 running slideInUp; }

.home-slideshow .slick-active .slide-offer-text {
  animation: 1600ms ease-in-out 0s normal none 1 running slideInUp; }

.home-slideshow .slick-active .slide-sub-heading-2 {
  animation: 1400ms ease-in-out 0s normal none 1 running slideInUp; }

.home-slideshow .slick-active .slider-content {
  animation: 1000ms ease-in-out 0s normal none 1 running fadeIn; }

.home-slideshow .slider_style_1 .slide-text {
  padding: 8px 15px;
  margin: 0 0 25px; }
  @media screen and (max-width: 1199px) {
    .home-slideshow .slider_style_1 .slide-text {
      margin: 0 0 15px; } }

.home-slideshow .slider_style_1 .slide-heading {
  margin-bottom: 13px; }

.home-slideshow .slider_style_1 .slider-content {
  left: 15%;
  right: auto; }
  @media screen and (max-width: 1599px) {
    .home-slideshow .slider_style_1 .slider-content {
      left: 13%; } }

.home-slideshow .slider_style_2 .slide-text {
  padding: 8px 15px;
  margin: 0 0 25px; }
  @media screen and (max-width: 1199px) {
    .home-slideshow .slider_style_2 .slide-text {
      margin: 0 0 15px; } }

.home-slideshow .slider_style_2 .slide-heading {
  margin-bottom: 13px; }

.home-slideshow .slider_style_2 .slider-content {
  right: 12%;
  left: auto; }
  @media screen and (max-width: 1599px) {
    .home-slideshow .slider_style_2 .slider-content {
      right: 8%; } }

.home-slideshow .slider_style_2 .slide_left {
  float: right; }
  @media screen and (max-width: 767px) {
    .home-slideshow .slider_style_2 .slide_left {
      float: none; } }

.home-slideshow .slider_style_3 .slider-content {
  padding: 40px;
  width: 35%; }
  @media screen and (max-width: 1599px) {
    .home-slideshow .slider_style_3 .slider-content {
      width: 50%; } }

.home-slideshow .slider_style_3 .slide-heading {
  margin-bottom: 20px; }

.home-slideshow .slider_style_3 .slider-content {
  right: 0%;
  left: auto;
  left: 0;
  margin: auto;
  text-align: center; }

.home-slideshow .slider_style_3 .slide-text {
  margin: 0 0 20px; }
  @media screen and (max-width: 1199px) {
    .home-slideshow .slider_style_3 .slide-text {
      margin: 0 0 15px; } }

.slide-button i {
  margin-left: 5px; }

.slide-offer.animated {
  margin: 15px 0 30px;
  font-weight: 500; }
  @media screen and (max-width: 1199px) {
    .slide-offer.animated {
      margin: 0px 0 15px; } }

.slide-sub-heading-2 {
  font-weight: 500; }
  @media screen and (max-width: 967px) {
    .slide-sub-heading-2 {
      font-size: 16px !important; } }

.slider-content.slider-content-2-bg.right-content {
  width: 40%; }

@media screen and (max-width: 1199px) {
  .slide-heading {
    font-size: 30px !important; } }
@media screen and (max-width: 967px) {
  .slide-heading {
    font-size: 20px !important; } }

@media screen and (max-width: 1199px) {
  .home-slideshow .slider-content {
    width: 60% !important; } }

@media screen and (max-width: 767px) {
  .slide-text {
    font-size: 16px !important; } }
@media screen and (max-width: 567px) {
  .slide-text {
    font-size: 12px !important; } }

.border-title .tilte_img {
  display: inline-block;
  margin-bottom: 10px; }

.product_single_detail_section .margin-left-right {
  margin-top: 10px !important;
  padding: 0 7px !important; }

.product-options {
  position: relative;
  margin-top: 5px; }

.product_single_detail_section .product-options p.h3 {
  font-size: 20px;
  margin: 0 0 10px; }

.product_single_detail_section .clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

.product_single_detail_section .clear {
  clear: both; }

.product_single_detail_section .product-options li.open {
  position: relative; }

.product_single_detail_section .product-options li.hover, .product-options li.open {
  background: url("https://kust-fr.com/skin/frontend/smartwave/porto/images/option-item.png") no-repeat bottom center; }

.product_single_detail_section .product-options li {
  float: left;
  display: block;
  width: 20%; }

.product_single_detail_section .product-options li .option-item {
  padding: 0 8px; }

.product_single_detail_section .product-options li .option-item-img {
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
  text-align: center; }

.product_single_detail_section .product-options li .option-qty {
  border-radius: 15px;
  text-align: center;
  min-width: 20px;
  height: 30px;
  line-height: 32px;
  color: #fff;
  font-family: 'proxima_nova_rgbold', Arial;
  font-size: 16px;
  padding: 0 10px;
  background: #df3a01;
  z-index: 4;
  position: absolute;
  top: 0;
  left: 50%; }

.product_single_detail_section .product-options li .option-item-img img {
  max-width: 100%;
  height: auto; }

.product_single_detail_section .product-options li .option-item-img .product-name {
  margin: 5px -8px 2px -8px;
  font-size: 13px;
  line-height: 12px; }
